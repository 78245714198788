import React, {useMemo} from 'react'
import styled from 'styled-components'
import {getFontFamily, getFontSize} from 'utils'
import {Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {useTranslation} from 'i18n'

const StyledList = styled.ol`
  padding-left: ${convertUnit(25)};
`
const StyledListItem = styled.li`
  font-family: ${getFontFamily('regular')};
  font-size: ${getFontSize('l')};
  line-height: ${convertUnit(31)};
  padding-left: ${convertUnit(25)};
`
const StyledTextContainer = styled.div`
  text-align: justify;
`
const StyledSubTitle = styled(Paragraph)`
  ${({fontSize = 'xl', fontWeight = 'bold'}) => ({
    fontSize: typeof fontSize === 'number' ? fontSize : getFontSize(fontSize),
    fontFamily: getFontFamily(fontWeight),
  })}
  margin-top: ${convertUnit(20)};
  text-align: justify;
`
const StyledDescription = styled(Paragraph)`
  ${({fontSize = 'l'}) => ({
    fontSize: typeof fontSize === 'number' ? fontSize : getFontSize(fontSize),
  })}
  margin-top: ${convertUnit(10)};
  line-height: ${convertUnit(31)};
  white-space: pre-wrap;
  text-align: justify;
`

export default function LegalFototreeContent() {
  const {translate} = useTranslation()

  const handleRenderFotoTree = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>
          {translate('policy:howItWorksFototree')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:howItWorksFototreeDesc')}
        </StyledDescription>
        <StyledSubTitle fontSize="l">
          {translate('policy:howItWorksFototreePlant')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:howItWorksFototreePlantDesc')}
        </StyledDescription>
        <StyledDescription>
          {translate('policy:howItWorksFototreePlantStep')}
        </StyledDescription>
        <StyledList>
          {Array.from({length: 9}).map((_, index) => (
            <StyledListItem key={index.toString()}>
              {translate('policy:howItWorksFototreePlantStep', {
                context: `${index + 1}`,
              })}
            </StyledListItem>
          ))}
        </StyledList>
      </StyledTextContainer>
    ),
    [translate],
  )

  const handleRenderLeafMemory = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle fontSize="l">
          {translate('policy:howItWorksFototreeLeaf')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:howItWorksFototreeLeafDesc')}
        </StyledDescription>
        <StyledDescription>
          {translate('policy:howItWorksFototreeLeafStep')}
        </StyledDescription>
        <StyledList>
          {Array.from({length: 4}).map((_, index) => (
            <StyledListItem key={index.toString()}>
              {translate('policy:howItWorksFototreeLeafStep', {
                context: `${index + 1}`,
              })}
            </StyledListItem>
          ))}
        </StyledList>
        <StyledDescription>
          {translate('policy:howItWorksFototreeLeafDescBottom')}
        </StyledDescription>
      </StyledTextContainer>
    ),
    [translate],
  )

  return (
    <>
      {handleRenderFotoTree}
      {handleRenderLeafMemory}
    </>
  )
}
