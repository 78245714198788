import React, {useMemo} from 'react'
import styled from 'styled-components'
import {
  getAuthPrivacy,
  getCreatorHostPolicy,
  getFontFamily,
  getFontSize,
  getHostBiometric,
  getHowItWorksWebUrl,
  getUnderagePolicy,
} from 'utils'
import {Paragraph, ParsedText} from 'common/components'
import convertUnit from 'lib/unit'
import {useTranslation} from 'i18n'
import {parseShape} from 'types'
import {AUTH_USER_MIN_AGE} from 'consts'
import {LegalDosAndDontsContent} from '../DosAndDontsContent'

const StyledTextContainer = styled.div`
  text-align: justify;
`
const StyledTitle = styled(Paragraph)`
  ${({theme}) => ({
    color: theme.primary_5,
  })}
  text-align: justify;
`
const StyledSubTitle = styled(Paragraph)`
  ${({fontSize = 'xl', fontWeight = 'bold'}) => ({
    fontSize: typeof fontSize === 'number' ? fontSize : getFontSize(fontSize),
    fontFamily: getFontFamily(fontWeight),
  })}
  margin-top: ${convertUnit(20)};
  text-align: justify;
`
const StyledDescription = styled(Paragraph)`
  ${({fontSize = 'l'}) => ({
    fontSize: typeof fontSize === 'number' ? fontSize : getFontSize(fontSize),
  })}
  white-space: pre-wrap;
  margin-top: ${convertUnit(10)};
  line-height: ${convertUnit(31)};
  text-align: justify;
`
const StyledBold = styled.span`
  font-family: ${getFontFamily('bold')};
`
const StyledList = styled.ol`
  padding-left: ${convertUnit(25)};
`
const StyledUlList = styled.ul`
  padding-left: ${convertUnit(25)};
`
const StyledListItem = styled.li`
  font-family: ${getFontFamily('regular')};
  font-size: ${getFontSize('l')};
  line-height: ${convertUnit(31)};
  padding-left: ${convertUnit(25)};
`
const StyledLink = styled.a`
  color: ${({theme}) => theme.primary_5};
  text-decoration: underline;
  cursor: pointer;
`

export default function LegalTermsOfUseContent({
  scrollTo,
}: {
  scrollTo: (top: number | undefined) => void
}) {
  const {translate} = useTranslation()
  const isSection = useMemo(
    () => document.location.href.includes('section'),
    [],
  )

  const patterns: parseShape[] = useMemo(() => {
    const regexPatterns = [
      {
        regex: /\*\*\*(.+?)\*\*\*/,
        style: {fontFamily: getFontFamily('boldItalic')},
      },
      {regex: /\*\*(.+?)\*\*/, style: {fontFamily: getFontFamily('bold')}},
      {regex: /\*(.+?)\*/, style: {fontFamily: getFontFamily('italic')}},
    ]

    return regexPatterns.map(({regex, style}) => ({
      pattern: regex,
      style,
      renderText: (text) => text.replace(regex, '$1'),
    }))
  }, [])

  const handleTermsOfUse = useMemo(
    () => (
      <StyledTextContainer>
        <StyledTitle fontWeight="bold" fontSize="xxl">
          {translate('policy:termsOfUse')}
        </StyledTitle>
        <StyledDescription>
          <ParsedText parse={patterns}>
            {translate('policy:termsOfUseDesc')}
          </ParsedText>
        </StyledDescription>
      </StyledTextContainer>
    ),
    [patterns, translate],
  )

  const handleGeneralUser = useMemo(
    () => (
      <>
        <StyledSubTitle fontSize="l">
          {translate('policy:termsOfUseRegisterAsGeneralYuser')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:termsOfUseRegisterAsGeneralYuserList')}
        </StyledDescription>
        <StyledList>
          {Array.from({length: 5}).map((_, index) => (
            <StyledListItem key={index.toString()}>
              {translate('policy:termsOfUseRegisterAsGeneralYuserList', {
                context: `${index + 1}`,
                age: AUTH_USER_MIN_AGE,
              })}
              {index === 1 && (
                <StyledLink
                  href={isSection ? getUnderagePolicy() : '#'}
                  target={isSection ? '_blank' : undefined}
                  onClick={() => scrollTo(13)}>
                  {translate('policy:privacyPolicyChild')}.
                </StyledLink>
              )}
              {index === 2 && (
                <StyledLink
                  href={isSection ? getHostBiometric() : '#'}
                  target={isSection ? '_blank' : undefined}
                  onClick={() => scrollTo(7)}>
                  {translate('global:here')}.
                </StyledLink>
              )}
            </StyledListItem>
          ))}
        </StyledList>
      </>
    ),
    [isSection, scrollTo, translate],
  )

  const handleChildUser = useMemo(
    () => (
      <>
        <StyledSubTitle fontSize="l">
          {translate('policy:termsOfUseRegisterAsChildYuser', {
            age: AUTH_USER_MIN_AGE,
          })}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:termsOfUseRegisterAsChildYuserDesc', {
            age: AUTH_USER_MIN_AGE,
          })}
          <StyledLink
            href={isSection ? getUnderagePolicy() : '#'}
            target={isSection ? '_blank' : undefined}
            onClick={() => scrollTo(13)}>
            {translate('policy:privacyPolicyChild')}.
          </StyledLink>
        </StyledDescription>
        <StyledDescription>
          {translate('policy:termsOfUseRegisterAsChildYuserList', {
            age: AUTH_USER_MIN_AGE,
          })}
        </StyledDescription>
        <StyledList>
          {Array.from({length: 5}).map((_, index) => (
            <StyledListItem key={index.toString()}>
              {translate('policy:termsOfUseRegisterAsChildYuserList', {
                context: `${index + 1}`,
                age: AUTH_USER_MIN_AGE,
              })}
            </StyledListItem>
          ))}
        </StyledList>
      </>
    ),
    [isSection, scrollTo, translate],
  )

  const handleParentUser = useMemo(
    () => (
      <>
        <StyledSubTitle fontSize="l">
          {translate('policy:termsOfUseRegisterAsParentYuser')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:termsOfUseRegisterAsParentYuserList')}
        </StyledDescription>
        <StyledList>
          {Array.from({length: 8}).map((_, index) => (
            <StyledListItem key={index.toString()}>
              {translate('policy:termsOfUseRegisterAsParentYuserList', {
                context: `${index + 1}`,
              })}
            </StyledListItem>
          ))}
        </StyledList>
      </>
    ),
    [translate],
  )

  const handleCreatorUser = useMemo(
    () => (
      <>
        <StyledSubTitle fontSize="l">
          {translate('policy:termsOfUseAsCreator')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:termsOfUseAsCreatorDesc')}
        </StyledDescription>
        <StyledUlList>
          <StyledListItem>
            <StyledSubTitle fontSize="l">
              {translate('policy:termsOfUseAsCreatorBasic')}
            </StyledSubTitle>
            <StyledDescription>
              {translate('policy:termsOfUseAsCreatorBasicDesc')}
            </StyledDescription>
            <StyledDescription>
              {translate('policy:termsOfUseAsCreatorBasicList')}
            </StyledDescription>
            <StyledList>
              {Array.from({length: 3}).map((_, index) => (
                <StyledListItem key={index.toString()}>
                  {translate('policy:termsOfUseAsCreatorBasicList', {
                    context: `${index + 1}`,
                  })}
                </StyledListItem>
              ))}
            </StyledList>
          </StyledListItem>
          <StyledListItem>
            <StyledSubTitle fontSize="l">
              {translate('policy:termsOfUseAsCreatorVerified')}
            </StyledSubTitle>
            <StyledDescription>
              {translate('policy:termsOfUseAsCreatorVerifiedList')}
            </StyledDescription>
            <StyledList>
              {Array.from({length: 4}).map((_, index) => (
                <StyledListItem key={index.toString()}>
                  {translate('policy:termsOfUseAsCreatorVerifiedList', {
                    context: `${index + 1}`,
                    age: AUTH_USER_MIN_AGE,
                  })}
                  {index === 1 && (
                    <StyledLink
                      href={isSection ? getHostBiometric() : '#'}
                      target={isSection ? '_blank' : undefined}
                      onClick={() => scrollTo(7)}>
                      {translate('global:here')}.
                    </StyledLink>
                  )}
                </StyledListItem>
              ))}
            </StyledList>
          </StyledListItem>
          <StyledListItem>
            <StyledSubTitle fontSize="l">
              {translate('policy:termsOfUseAsCreatorHost')}
            </StyledSubTitle>
            <StyledDescription>
              {translate('policy:termsOfUseAsCreatorHostList')}
            </StyledDescription>
            <StyledList>
              {Array.from({length: 5}).map((_, index) => (
                <StyledListItem key={index.toString()}>
                  {translate('policy:termsOfUseAsCreatorHostList', {
                    context: `${index + 1}`,
                  })}
                </StyledListItem>
              ))}
            </StyledList>
            <StyledDescription>
              {translate('policy:termsOfUseAsCreatorHostDesc')}
              <StyledLink
                href={isSection ? getCreatorHostPolicy() : '#'}
                target={isSection ? '_blank' : undefined}
                onClick={() => scrollTo(3)}>
                {translate('global:here')}.
              </StyledLink>
            </StyledDescription>
          </StyledListItem>
        </StyledUlList>
      </>
    ),
    [isSection, scrollTo, translate],
  )

  const handleYourChoice = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>{translate('policy:yuserChoice')}</StyledSubTitle>
        <StyledDescription>
          <ParsedText parse={patterns}>
            {translate('policy:yuserChoiceDesc')}
          </ParsedText>
          <StyledLink
            href={isSection ? getHowItWorksWebUrl() : '#'}
            target={isSection ? '_blank' : undefined}
            onClick={() => scrollTo(0)}>
            {translate('global:here')}.
          </StyledLink>
        </StyledDescription>
      </StyledTextContainer>
    ),
    [isSection, patterns, scrollTo, translate],
  )

  const handleRegisterAccount = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle fontWeight="bold" fontSize="xl">
          {translate('policy:termsOfUseRegister')}
        </StyledSubTitle>
        <StyledDescription fontSize="l">
          {translate('policy:termsOfUseRegisterDesc', {
            age: AUTH_USER_MIN_AGE,
          })}
        </StyledDescription>
        {handleGeneralUser}
        {handleChildUser}
        {handleParentUser}
        {handleCreatorUser}
      </StyledTextContainer>
    ),
    [
      handleCreatorUser,
      handleGeneralUser,
      handleParentUser,
      handleChildUser,
      translate,
    ],
  )

  const handlePayment = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle fontWeight="bold">
          {translate('policy:termsOfUsePayment')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:termsOfUsePaymentDesc')}
          <StyledLink
            href={isSection ? `${getHowItWorksWebUrl()}#pembayaran` : '#'}
            target={isSection ? '_blank' : undefined}
            onClick={() =>
              document
                .getElementById('pembayaran')
                ?.scrollIntoView({behavior: 'smooth'})
            }>
            {translate('global:here')}.
          </StyledLink>
        </StyledDescription>
      </StyledTextContainer>
    ),
    [isSection, translate],
  )

  const handleFotoyuContent = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle fontSize="l">
          {translate('policy:termsOfUseContentFotoyu')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:termsOfUseContentFotoyuDesc')}
        </StyledDescription>
        <StyledSubTitle fontSize="l">
          {translate('policy:termsOfUseContentCreator')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:termsOfUseContentCreatorDesc')}
        </StyledDescription>
      </StyledTextContainer>
    ),
    [translate],
  )

  const handleStopContent = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle fontWeight="bold">
          {translate(
            'policy:termsOfUseContentRemovalAndAccountTermination',
            {},
          )}
        </StyledSubTitle>
        <StyledUlList>
          {Array.from({length: 3}).map((_, index) => (
            <StyledListItem key={index.toString()}>
              {translate(
                'policy:termsOfUseContentRemovalAndAccountTermination',
                {
                  context: `${index + 1}`,
                },
              )}
            </StyledListItem>
          ))}
        </StyledUlList>
      </StyledTextContainer>
    ),
    [translate],
  )

  const handleDisclaimer = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle fontWeight="bold" fontSize="xl">
          {translate('policy:termsOfUseDisclaimerOfWarranty')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:termsOfUseDisclaimerOfWarrantyList')}
        </StyledDescription>
        <StyledList>
          {Array.from({length: 5}).map((_, index) => (
            <StyledListItem key={index.toString()}>
              {translate('policy:termsOfUseDisclaimerOfWarrantyList', {
                context: `${index + 1}`,
              })}
            </StyledListItem>
          ))}
        </StyledList>
        <StyledDescription>
          {translate('policy:termsOfUseDisclaimerOfWarrantyDesc')}
        </StyledDescription>
        <StyledSubTitle fontWeight="bold" fontSize="xl">
          {translate('policy:termsOfUseLimitationOfLiability')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:termsOfUseLimitationOfLiabilityListOne')}
        </StyledDescription>
        <StyledList>
          {Array.from({length: 4}).map((_, index) => (
            <StyledListItem key={index.toString()}>
              {translate('policy:termsOfUseLimitationOfLiabilityListOne', {
                context: `${index + 1}`,
              })}
            </StyledListItem>
          ))}
        </StyledList>
        <StyledDescription>
          {translate('policy:termsOfUseLimitationOfLiabilityListTwo')}
        </StyledDescription>
        <StyledList>
          {Array.from({length: 5}).map((_, index) => (
            <StyledListItem key={index.toString()}>
              {translate('policy:termsOfUseLimitationOfLiabilityListTwo', {
                context: `${index + 1}`,
              })}
            </StyledListItem>
          ))}
        </StyledList>
        <StyledDescription>
          {translate('policy:termsOfUseLimitationOfLiabilityDesc')}
        </StyledDescription>
      </StyledTextContainer>
    ),
    [translate],
  )

  const handleOtherTerms = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle fontWeight="bold" fontSize="xl">
          {translate('policy:termsOfUseOtherTerms')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:termsOfUseOtherTermsDesc')}
        </StyledDescription>
        <StyledSubTitle fontSize="l">
          {translate('policy:termsOfUseSupplementalTerms')}
        </StyledSubTitle>
        <StyledUlList>
          <StyledListItem>
            <StyledSubTitle fontSize="l">
              {translate('policy:termsOfUseSupplementalTermsDownload')}
            </StyledSubTitle>
            <StyledDescription>
              {translate('policy:termsOfUseSupplementalTermsDownloadDesc', {})}
            </StyledDescription>
            <StyledDescription fontWeight="bold">
              {translate('policy:termsOfUseSupplementalTermsAppleDevice', {})}
            </StyledDescription>
            <StyledList>
              {Array.from({length: 3}).map((_, index) => (
                <StyledListItem key={index.toString()}>
                  {translate('policy:termsOfUseSupplementalTermsAppleDevice', {
                    context: `${index + 1}`,
                  })}
                </StyledListItem>
              ))}
            </StyledList>
            <StyledDescription fontWeight="bold">
              {translate('policy:termsOfUseSupplementalTermsAndroidDevice', {})}
            </StyledDescription>
            <StyledList>
              {Array.from({length: 3}).map((_, index) => (
                <StyledListItem key={index.toString()}>
                  {translate(
                    'policy:termsOfUseSupplementalTermsAndroidDevice',
                    {
                      context: `${index + 1}`,
                    },
                  )}
                </StyledListItem>
              ))}
            </StyledList>
          </StyledListItem>
          <StyledListItem>
            <StyledSubTitle fontSize="l">
              {translate('policy:termsOfUseYourLocation')}
            </StyledSubTitle>
            <StyledDescription>
              {translate('policy:termsOfUseYourLocationDesc')}
            </StyledDescription>
            <StyledSubTitle fontSize="l">Indonesia</StyledSubTitle>
            <StyledDescription fontWeight="bold">
              {translate('policy:termsOfUseAgreeToTerms')}
              <br />
              {translate('policy:termsOfUseAgreeToTermsList')}
            </StyledDescription>
            <StyledList>
              {Array.from({length: 3}).map((_, index) => (
                <StyledListItem key={index.toString()}>
                  {translate('policy:termsOfUseAgreeToTermsList', {
                    context: `${index + 1}`,
                  })}
                </StyledListItem>
              ))}
            </StyledList>
            <StyledDescription>
              <StyledBold>
                {translate('policy:termsOfUseLimitationOfLiabilities')}
              </StyledBold>
              <br />
              {translate('policy:termsOfUseLimitationOfLiabilitiesDesc')}
              <br />
              <StyledBold>
                {translate('policy:termsOfUseLimitationOfAge')}
              </StyledBold>
              <br />
              {translate('policy:termsOfUseLimitationOfAgeDesc', {
                age: AUTH_USER_MIN_AGE,
              })}
              <StyledLink
                href={isSection ? getUnderagePolicy() : '#'}
                target={isSection ? '_blank' : undefined}
                onClick={() => scrollTo(13)}>
                {translate('policy:privacyPolicyChild')}.
              </StyledLink>
              <br />
              <StyledBold>{translate('policy:termsOfUseLanguage')}</StyledBold>
              <br />
              {translate('policy:termsOfUseLanguageDesc')}
            </StyledDescription>
          </StyledListItem>
          <StyledListItem>
            <StyledSubTitle fontSize="l">
              {translate('policy:termsOfUseUpdate')}
            </StyledSubTitle>
            <StyledDescription>
              {translate('policy:termsOfUseUpdateDesc')}
              <StyledLink
                href={isSection ? getAuthPrivacy() : '#'}
                target={isSection ? '_blank' : undefined}
                onClick={() => scrollTo(4)}>
                {translate('global:here')}.
              </StyledLink>
            </StyledDescription>
          </StyledListItem>
        </StyledUlList>
      </StyledTextContainer>
    ),
    [isSection, scrollTo, translate],
  )

  return (
    <div>
      {handleTermsOfUse}
      {handleRegisterAccount}
      {handleYourChoice}
      {handlePayment}
      {handleFotoyuContent}
      <LegalDosAndDontsContent scrollTo={scrollTo} />
      {handleStopContent}
      {handleDisclaimer}
      {handleOtherTerms}
    </div>
  )
}
