import React, {useEffect, useMemo} from 'react'
import styled from 'styled-components'
import {AUTH_USER_MIN_AGE, COMPANY_NAME, DOMAIN_NAME} from 'consts'
import {useTranslation} from 'i18n'
import {
  getAuthCookie,
  getCommunityGuideline,
  getFontFamily,
  getFontSize,
  getHostBiometric,
  getHowItWorksWebUrl,
  getUnderagePolicy,
} from 'utils'
import {parseShape} from 'types'
import {Paragraph, ParsedText} from 'common/components'
import convertUnit from 'lib/unit'

const StyledList = styled.ol`
  padding-left: ${convertUnit(25)};
`

const StyledListUl = styled.ul`
  padding-left: ${convertUnit(25)};
`

const StyledListItem = styled.li`
  font-family: ${getFontFamily('regular')};
  font-size: ${getFontSize('l')};
  line-height: ${convertUnit(31)};
  padding-left: ${convertUnit(25)};
`

const StyledTextContainer = styled.div`
  text-align: justify;
`

const StyledTitle = styled(Paragraph)`
  ${({theme}) => ({
    color: theme.primary_5,
  })}
  text-align: justify;
`

const StyledSubTitle = styled(Paragraph)`
  ${({fontSize = 'xl', fontWeight = 'bold'}) => ({
    fontSize: typeof fontSize === 'number' ? fontSize : getFontSize(fontSize),
    fontFamily: getFontFamily(fontWeight),
  })}
  margin-top: ${convertUnit(20)};
  text-align: justify;
`

const StyledParagraph = styled(Paragraph)`
  ${({fontSize = 'l'}) => ({
    fontSize: typeof fontSize === 'number' ? fontSize : getFontSize(fontSize),
  })}
  line-height: ${convertUnit(31)};
  white-space: pre-wrap;
`

const StyledDescription = styled(Paragraph)`
  ${({fontSize = 'l'}) => ({
    fontSize: typeof fontSize === 'number' ? fontSize : getFontSize(fontSize),
  })}
  white-space: pre-wrap;
  margin-top: ${convertUnit(10)};
  line-height: ${convertUnit(31)};
  text-align: justify;
`

const StyledLink = styled.a`
  color: ${({theme}) => theme.primary_5};
  text-decoration: underline;
  cursor: pointer;
`

export default function PrivacyPolicyContent({
  scrollTo,
}: {
  scrollTo: (childRef: number | undefined) => void
}) {
  const {translate} = useTranslation()
  const isSection = useMemo(
    () => document.location.href.includes('section'),
    [],
  )

  const patterns: parseShape[] = useMemo(() => {
    const regexs = [
      {
        regex: /\*\*\*(.+?)\*\*\*/,
        style: {fontFamily: getFontFamily('boldItalic')},
      },
      {regex: /\*\*(.+?)\*\*/, style: {fontFamily: getFontFamily('bold')}},
      {regex: /\*(.+?)\*/, style: {fontFamily: getFontFamily('italic')}},
    ]

    return regexs.map(({regex, style}) => ({
      pattern: regex,
      style,
      renderText: (text) => text.replace(regex, '$1'),
    }))
  }, [])

  const handlePrivacyPolicy = useMemo(
    () => (
      <StyledTextContainer>
        <StyledTitle fontWeight="bold" fontSize="xxl">
          {translate('policy:privacyPolicy')}
        </StyledTitle>
        <StyledDescription>
          <ParsedText parse={patterns}>
            {translate('policy:privacyPolicyDesc', {name: COMPANY_NAME})}
          </ParsedText>
          <StyledLink
            href={`mailto:${translate('policy:emailSupport', {
              Domain: DOMAIN_NAME,
            })}`}>
            {translate('policy:emailSupport', {Domain: DOMAIN_NAME})}.
          </StyledLink>
        </StyledDescription>
      </StyledTextContainer>
    ),
    [patterns, translate],
  )

  const handleCollectInfo = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>{translate('policy:collectInfo')}</StyledSubTitle>
        <StyledDescription>
          {translate('policy:collectInfoDesc', {name: COMPANY_NAME})}
        </StyledDescription>
      </StyledTextContainer>
    ),
    [translate],
  )

  const handleProvideInfo = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>{translate('policy:provideInfo')}</StyledSubTitle>
        <StyledDescription>
          {translate('policy:provideInfoDesc', {name: COMPANY_NAME})}
        </StyledDescription>
        <StyledList>
          <StyledListItem>
            <StyledParagraph>
              {translate('policy:provideInfoRegist')}
            </StyledParagraph>
            <StyledParagraph>
              {translate('policy:provideInfoRegistDesc')}
            </StyledParagraph>
          </StyledListItem>
          <StyledListItem>
            <StyledParagraph>
              {translate('policy:provideInfoProfile')}
            </StyledParagraph>
            <StyledParagraph>
              {translate('policy:provideInfoProfileDesc')}
            </StyledParagraph>
          </StyledListItem>
          <StyledListItem>
            <StyledParagraph>
              {translate('policy:provideInfoConnection')}
            </StyledParagraph>
            <StyledParagraph>
              {translate('policy:provideInfoConnectionDesc')}
            </StyledParagraph>
          </StyledListItem>
          <StyledListItem>
            <StyledParagraph>
              {translate('policy:provideInfoContent')}
            </StyledParagraph>
            <StyledParagraph>
              {translate('policy:provideInfoContentDesc')}
            </StyledParagraph>
          </StyledListItem>
          <StyledListItem>
            <StyledParagraph>
              {translate('policy:provideInfoPurchase')}
            </StyledParagraph>
            <StyledParagraph>
              {translate('policy:provideInfoPurchaseDesc')}
            </StyledParagraph>
          </StyledListItem>
          <StyledListItem>
            <StyledParagraph>
              {translate('policy:provideInfoPhone')}
            </StyledParagraph>
            <StyledParagraph>
              {translate('policy:provideInfoPhoneDesc')}
            </StyledParagraph>
          </StyledListItem>
          <StyledListItem>
            <StyledParagraph>
              {translate('policy:provideInfoVerif')}
            </StyledParagraph>
            <StyledParagraph>
              {translate('policy:provideInfoVerifDesc')}
              <StyledLink
                href={isSection ? getHostBiometric() : '#'}
                target={isSection ? '_blank' : undefined}
                onClick={() => scrollTo(7)}>
                {translate('global:here')}.
              </StyledLink>
            </StyledParagraph>
          </StyledListItem>
          <StyledListItem>
            <StyledParagraph>
              {translate('policy:provideInfoAdditional')}
            </StyledParagraph>
            <StyledParagraph>
              {translate('policy:provideInfoAdditionalDesc')}
            </StyledParagraph>
          </StyledListItem>
        </StyledList>
      </StyledTextContainer>
    ),
    [isSection, scrollTo, translate],
  )

  const handleThirdParty = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>{translate('policy:thirdParty')}</StyledSubTitle>
        <StyledDescription>
          {translate('policy:thirdPartyDesc', {name: COMPANY_NAME})}
        </StyledDescription>
        <StyledList>
          <StyledListItem>
            <StyledParagraph>
              {translate('policy:thirdPartyLogin')}
            </StyledParagraph>
            <StyledParagraph>
              {translate('policy:thirdPartyLoginDesc')}
            </StyledParagraph>
          </StyledListItem>
          <StyledListItem>
            <StyledParagraph>
              {translate('policy:thirdPartyServices')}
            </StyledParagraph>
            <StyledParagraph>
              {translate('policy:thirdPartyServicesDesc')}
            </StyledParagraph>
          </StyledListItem>
          <StyledListItem>
            <StyledParagraph>
              {translate('policy:thirdPartyOther')}
            </StyledParagraph>
            <StyledParagraph>
              {translate('policy:thirdPartyOtherDesc')}
            </StyledParagraph>
          </StyledListItem>
        </StyledList>
      </StyledTextContainer>
    ),
    [translate],
  )

  const handleAutoCollect = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>{translate('policy:autoCollect')}</StyledSubTitle>
        <StyledDescription>
          {translate('policy:autoCollectDesc', {name: COMPANY_NAME})}
        </StyledDescription>
        <StyledList>
          <StyledListItem>
            <StyledParagraph>
              {translate('policy:autoCollectUsage')}
            </StyledParagraph>
            <StyledParagraph>
              {translate('policy:autoCollectUsageDesc')}
            </StyledParagraph>
          </StyledListItem>
          <StyledListItem>
            <StyledParagraph>
              {translate('policy:autoCollectLocation')}
            </StyledParagraph>
            <StyledParagraph>
              {translate('policy:autoCollectLocationDesc')}
            </StyledParagraph>
          </StyledListItem>
          <StyledListItem>
            <StyledParagraph>
              {translate('policy:autoCollectImage')}
            </StyledParagraph>
            <StyledParagraph>
              {translate('policy:autoCollectImageDesc')}
            </StyledParagraph>
          </StyledListItem>
          <StyledListItem>
            <StyledParagraph>
              {translate('policy:autoCollectMessage')}
            </StyledParagraph>
            <StyledParagraph>
              {translate('policy:autoCollectMessageDesc')}
            </StyledParagraph>
          </StyledListItem>
          <StyledListItem>
            <StyledParagraph>
              {translate('policy:autoCollectMetadata')}
            </StyledParagraph>
            <StyledParagraph>
              {translate('policy:autoCollectMetadataDesc')}
            </StyledParagraph>
          </StyledListItem>
          <StyledListItem>
            <StyledParagraph>
              {translate('policy:autoCollectDevice')}
            </StyledParagraph>
            <StyledParagraph>
              {translate('policy:autoCollectDeviceDesc')}
            </StyledParagraph>
            <StyledList type="a">
              <StyledListItem>
                <StyledParagraph>
                  {translate('policy:autoCollectDeviceAttributes')}
                </StyledParagraph>
                <StyledParagraph>
                  {translate('policy:autoCollectDeviceAttributesDesc')}
                </StyledParagraph>
              </StyledListItem>
              <StyledListItem>
                <StyledParagraph>
                  {translate('policy:autoCollectDeviceIdentifiers')}
                </StyledParagraph>
                <StyledParagraph>
                  {translate('policy:autoCollectDeviceIdentifiersDesc')}
                </StyledParagraph>
              </StyledListItem>
              <StyledListItem>
                <StyledParagraph>
                  {translate('policy:autoCollectDeviceCookie')}
                </StyledParagraph>
                <StyledParagraph>
                  {translate('policy:autoCollectDeviceCookieDesc')}
                  <StyledLink
                    href={isSection ? getAuthCookie() : '#'}
                    target={isSection ? '_blank' : undefined}
                    onClick={() => scrollTo(12)}>
                    {translate('policy:cookie')}.
                  </StyledLink>
                </StyledParagraph>
              </StyledListItem>
              <StyledListItem>
                <StyledParagraph>
                  {translate('policy:autoCollectDeviceOther')}
                </StyledParagraph>
                <StyledParagraph>
                  {translate('policy:autoCollectDeviceOtherDesc')}
                </StyledParagraph>
              </StyledListItem>
            </StyledList>
          </StyledListItem>
        </StyledList>
      </StyledTextContainer>
    ),
    [isSection, scrollTo, translate],
  )

  const handleUseInfo = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>{translate('policy:useInfo')}</StyledSubTitle>
        <StyledDescription>{translate('policy:useInfoDesc')}</StyledDescription>
        <StyledList>
          {Array.from({length: 19}).map((_, index) => (
            <StyledListItem key={index.toString()}>
              {translate('policy:useInfo', {
                context: `${index + 1}`,
              })}
            </StyledListItem>
          ))}
        </StyledList>
      </StyledTextContainer>
    ),
    [translate],
  )

  const handleThirdPartyService = useMemo(
    () => (
      <>
        <StyledSubTitle>
          {translate('policy:shareInfo', {context: 'ThirdParty'})}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:shareInfo', {
            context: 'ThirdPartyDesc',
            name: COMPANY_NAME,
          })}
        </StyledDescription>
        <StyledList>
          <StyledListItem>
            <StyledParagraph>
              {translate('policy:shareInfo', {
                context: 'ThirdPartyPayment',
              })}
            </StyledParagraph>
            <StyledParagraph>
              {translate('policy:shareInfo', {
                context: 'ThirdPartyPaymentDesc',
              })}
            </StyledParagraph>
          </StyledListItem>
          <StyledListItem>
            <StyledParagraph>
              {translate('policy:shareInfo', {
                context: 'ThirdPartyTechnical',
              })}
            </StyledParagraph>
            <StyledParagraph>
              {translate('policy:shareInfo', {
                context: 'ThirdPartyTechnicalDesc',
              })}
            </StyledParagraph>
          </StyledListItem>
          <StyledListItem>
            <StyledParagraph>
              {translate('policy:shareInfo', {
                context: 'ThirdPartyCloud',
              })}
            </StyledParagraph>
            <StyledParagraph>
              {translate('policy:shareInfo', {
                context: 'ThirdPartyCloudDesc',
              })}
            </StyledParagraph>
          </StyledListItem>
          <StyledListItem>
            <StyledParagraph>
              {translate('policy:shareInfo', {
                context: 'ThirdPartyAPI',
              })}
            </StyledParagraph>
            <StyledParagraph>
              {translate('policy:shareInfo', {
                context: 'ThirdPartyAPIDesc',
              })}
            </StyledParagraph>
          </StyledListItem>
          <StyledListItem>
            <StyledParagraph>
              {translate('policy:shareInfo', {
                context: 'ThirdPartyAds',
              })}
            </StyledParagraph>
            <StyledParagraph>
              {translate('policy:shareInfo', {
                context: 'ThirdPartyAdsDesc',
              })}
            </StyledParagraph>
          </StyledListItem>
          <StyledListItem>
            <StyledParagraph>
              {translate('policy:shareInfo', {
                context: 'ThirdPartyPartner',
              })}
            </StyledParagraph>
            <StyledParagraph>
              {translate('policy:shareInfo', {
                context: 'ThirdPartyPartnerDesc',
              })}
            </StyledParagraph>
          </StyledListItem>
          <StyledListItem>
            <StyledParagraph>
              {translate('policy:shareInfo', {
                context: 'ThirdPartyLegal',
              })}
            </StyledParagraph>
            <StyledParagraph>
              {translate('policy:shareInfo', {
                context: 'ThirdPartyLegalDesc',
              })}
            </StyledParagraph>
          </StyledListItem>
        </StyledList>
      </>
    ),
    [translate],
  )

  const handleShareInfo = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>{translate('policy:shareInfo')}</StyledSubTitle>
        <StyledDescription>
          {translate('policy:shareInfoDesc', {name: COMPANY_NAME})}
        </StyledDescription>
        <StyledSubTitle fontSize="l">
          {translate('policy:shareInfo', {context: 'Network'})}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:shareInfo', {
            context: 'NetworkDesc',
            name: COMPANY_NAME,
          })}
          <StyledLink
            href={isSection ? getCommunityGuideline() : '#'}
            target={isSection ? '_blank' : undefined}
            onClick={() => scrollTo(6)}>
            {translate('policy:communityGuideline')}.
          </StyledLink>
        </StyledDescription>
        <StyledSubTitle fontSize="l">
          {translate('policy:shareInfo', {context: 'Owner'})}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:shareInfo', {
            context: 'OwnerDesc',
            name: COMPANY_NAME,
          })}
        </StyledDescription>
        {handleThirdPartyService}
      </StyledTextContainer>
    ),
    [handleThirdPartyService, isSection, scrollTo, translate],
  )

  const handleYourRights = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>
          {translate('policy:shareInfo', {context: 'Rights'})}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:shareInfo', {context: 'RightsDesc'})}
        </StyledDescription>
        <StyledList>
          <StyledListItem>
            <StyledParagraph>
              {translate('policy:shareInfo', {context: 'RightsInfo'})}
            </StyledParagraph>
            <StyledParagraph>
              {translate('policy:shareInfo', {
                context: 'RightsInfoDesc',
              })}
              <StyledLink
                href="#"
                onClick={() =>
                  document
                    .getElementById('penghapusanAkun')
                    ?.scrollIntoView({behavior: 'smooth'})
                }>
                {translate('global:here')}.
              </StyledLink>
            </StyledParagraph>
          </StyledListItem>
          <StyledListItem>
            <StyledParagraph>
              {translate('policy:shareInfo', {context: 'RightsPayment'})}
            </StyledParagraph>
            <StyledParagraph>
              {translate('policy:shareInfo', {
                context: 'RightsPaymentDesc',
              })}
            </StyledParagraph>
          </StyledListItem>
          <StyledListItem>
            <StyledParagraph>
              {translate('policy:shareInfo', {
                context: 'RightsCopyright',
              })}
            </StyledParagraph>
            <StyledParagraph>
              {translate('policy:shareInfo', {
                context: 'RightsCopyrightDesc',
              })}
              <StyledLink
                href={`mailto:${translate('policy:emailSupport', {
                  Domain: DOMAIN_NAME,
                })}`}>
                {translate('policy:emailSupport', {Domain: DOMAIN_NAME})}.
              </StyledLink>
            </StyledParagraph>
          </StyledListItem>
        </StyledList>
      </StyledTextContainer>
    ),
    [translate],
  )

  const handleYourChoice = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>{translate('policy:yuserChoice')}</StyledSubTitle>
        <StyledDescription>
          <ParsedText parse={patterns}>
            {translate('policy:yuserChoiceDesc')}
          </ParsedText>
          <StyledLink
            href={isSection ? getHowItWorksWebUrl() : '#'}
            target={isSection ? '_blank' : undefined}
            onClick={() => scrollTo(0)}>
            {translate('global:here')}.
          </StyledLink>
        </StyledDescription>
      </StyledTextContainer>
    ),
    [isSection, patterns, scrollTo, translate],
  )

  const handleWhereInformationKept = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>
          {translate('policy:shareInfo', {context: 'Store'})}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:shareInfo', {context: 'StoreDesc'})}
        </StyledDescription>
      </StyledTextContainer>
    ),
    [translate],
  )

  const handleUnderageYuser = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>
          {translate('policy:shareInfo', {context: 'ChildYuser'})}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:shareInfo', {
            context: 'ChildYuserDesc',
            age: AUTH_USER_MIN_AGE,
            name: COMPANY_NAME,
          })}
          <StyledLink
            href={isSection ? getUnderagePolicy() : '#'}
            target={isSection ? '_blank' : undefined}
            onClick={() => scrollTo(13)}>
            {translate('policy:privacyPolicyChild')}.
          </StyledLink>
        </StyledDescription>
      </StyledTextContainer>
    ),
    [isSection, scrollTo, translate],
  )

  const handleChanges = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>
          {translate('policy:shareInfo', {context: 'Changes'})}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:shareInfo', {context: 'ChangesDesc'})}
        </StyledDescription>
      </StyledTextContainer>
    ),
    [translate],
  )

  const handleDelete = useMemo(
    () => (
      <StyledTextContainer id="penghapusanAkun">
        <StyledSubTitle>{translate('policy:deleteAccount')}</StyledSubTitle>
        <StyledDescription>
          {translate('policy:deleteAccountDesc')}
        </StyledDescription>
        <StyledList>
          {Array.from({length: 5}).map((_, index) => (
            <StyledListItem key={index.toString()}>
              {translate('policy:deleteAccountDesc', {
                context: `${index + 1}`,
              })}
            </StyledListItem>
          ))}
        </StyledList>
        <StyledParagraph>
          {translate('policy:deleteAccountDescFooter')}
        </StyledParagraph>
        <StyledSubTitle fontSize="l">
          {translate('policy:deleteAccountCondition')}
        </StyledSubTitle>
        <StyledParagraph>
          {translate('policy:deleteAccountConditionDesc')}
        </StyledParagraph>
        <StyledList>
          {Array.from({length: 6}).map((_, index) => (
            <StyledListItem key={index.toString()}>
              {translate('policy:deleteAccountConditionDesc', {
                context: `${index + 1}`,
              })}
              {index === 5 && (
                <StyledList type="a">
                  {Array.from({length: 4}).map((__, subIndex) => (
                    <StyledListItem key={subIndex.toString()}>
                      {translate('policy:deleteAccountConditionDesc', {
                        context: `${subIndex + 7}`,
                      })}
                    </StyledListItem>
                  ))}
                </StyledList>
              )}
            </StyledListItem>
          ))}
        </StyledList>
      </StyledTextContainer>
    ),
    [translate],
  )

  const handleContacts = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>
          {translate('policy:shareInfo', {context: 'Contact'})}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:shareInfo', {context: 'ContactThrough'})}
        </StyledDescription>
        <StyledListUl>
          <StyledListItem>
            <StyledLink
              href={`mailto:${translate('policy:emailSupport', {
                Domain: DOMAIN_NAME,
              })}`}>
              {translate('policy:emailSupport', {Domain: DOMAIN_NAME})}
            </StyledLink>
          </StyledListItem>
        </StyledListUl>
      </StyledTextContainer>
    ),
    [translate],
  )

  useEffect(() => {
    if (document.location.href.includes('#penghapusanAkun')) {
      document
        .getElementById('penghapusanAkun')
        ?.scrollIntoView({behavior: 'smooth'})
    }
  }, [])

  return (
    <div>
      {handlePrivacyPolicy}
      {handleCollectInfo}
      {handleProvideInfo}
      {handleThirdParty}
      {handleAutoCollect}
      {handleUseInfo}
      {handleShareInfo}
      {handleYourRights}
      {handleYourChoice}
      {handleWhereInformationKept}
      {handleUnderageYuser}
      {handleChanges}
      {handleDelete}
      {handleContacts}
    </div>
  )
}
