import React, {useMemo} from 'react'
import styled from 'styled-components'
import {Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {getFontFamily, getFontSize} from 'utils'
import {useTranslation} from 'i18n'

const StyledContainer = styled.div`
  text-align: justify;
`

const StyledSubTitle = styled(Paragraph)`
  ${({fontSize = 'xl', fontWeight = 'bold'}) => ({
    fontSize: typeof fontSize === 'number' ? fontSize : getFontSize(fontSize),
    fontFamily: getFontFamily(fontWeight),
  })}
  margin-top: ${convertUnit(20)};
  text-align: justify;
`

const StyledDescription = styled(Paragraph)`
  ${({fontSize = 'l'}) => ({
    fontSize: typeof fontSize === 'number' ? fontSize : getFontSize(fontSize),
  })}
  margin-top: ${convertUnit(10)};
  line-height: ${convertUnit(31)};
  white-space: pre-wrap;
  text-align: justify;
`

const StyledList = styled.ol`
  padding-left: ${convertUnit(25)};
`

const StyledListUl = styled.ul`
  padding-left: ${convertUnit(25)};
`

const StyledListItem = styled.li`
  font-family: ${getFontFamily('regular')};
  font-size: ${getFontSize('l')};
  padding-left: ${convertUnit(25)};
  line-height: ${convertUnit(31)};
`

const StyledLink = styled.a`
  color: ${({theme}) => theme.primary_5};
  text-decoration: underline;
  cursor: pointer;
`

export interface LegalSellVideoContentProps {
  scrollTo: (childRef: number | undefined) => void
}

export default function LegalSellVideoContent({
  scrollTo,
}: LegalSellVideoContentProps) {
  const {translate} = useTranslation()
  const handleRenderDescription = useMemo(
    () => (
      <>
        <StyledSubTitle>
          {translate('policy:howItWorksSellVideo')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:howItWorksSellVideoDesc')}
        </StyledDescription>
      </>
    ),
    [translate],
  )

  const handleRenderVideoProvision = useMemo(
    () => (
      <>
        <StyledSubTitle fontSize="l">
          {translate('policy:howItWorksSellVideoProvision')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:howItWorksSellVideoProvisionDesc')}
        </StyledDescription>
      </>
    ),
    [translate],
  )

  const handleRenderVideoPreview = useMemo(
    () => (
      <>
        <StyledSubTitle fontSize="l">
          {translate('policy:howItWorksSellVideoPreview')}
        </StyledSubTitle>
        <StyledListUl>
          {Array.from({length: 3}).map((_, index) => (
            <StyledListItem key={index.toString()}>
              {translate('policy:howItWorksSellVideoPreview', {
                context: `${index + 1}`,
              })}
            </StyledListItem>
          ))}
        </StyledListUl>
      </>
    ),
    [translate],
  )

  const handleRenderHiresVideo = useMemo(
    () => (
      <>
        <StyledSubTitle fontSize="l">
          {translate('policy:howItWorksSellVideoHires')}
        </StyledSubTitle>
        <StyledListUl>
          {Array.from({length: 3}).map((_, index) => (
            <StyledListItem key={index.toString()}>
              {translate('policy:howItWorksSellVideoHires', {
                context: `${index + 1}`,
              })}
            </StyledListItem>
          ))}
        </StyledListUl>
      </>
    ),
    [translate],
  )

  const handleRenderCreator = useMemo(
    () => (
      <>
        <StyledSubTitle fontSize="l">
          {translate('policy:howItWorksSellVideoAsCreator')}
        </StyledSubTitle>
        <StyledList>
          {Array.from({length: 3}).map((_, index) => (
            <StyledListItem key={index.toString()}>
              {translate('policy:howItWorksSellVideoAsCreator', {
                context: `${index + 1}`,
              })}
              {index === 2 && (
                <StyledLink href="#" onClick={() => scrollTo(6)}>
                  {translate('policy:communityGuideline')}.
                </StyledLink>
              )}
            </StyledListItem>
          ))}
        </StyledList>
      </>
    ),
    [scrollTo, translate],
  )

  return (
    <StyledContainer>
      {handleRenderDescription}
      {handleRenderVideoProvision}
      {handleRenderVideoPreview}
      {handleRenderHiresVideo}
      {handleRenderCreator}
    </StyledContainer>
  )
}
