import React, {useMemo} from 'react'
import styled from 'styled-components'
import {DOMAIN_NAME} from 'consts'
import {useTranslation} from 'i18n'
import {getFontFamily, getFontSize} from 'utils'
import {Paragraph} from 'common/components'
import convertUnit from 'lib/unit'

const StyledList = styled.ol`
  font-family: 'Roboto-Regular' !important;
  padding-left: ${convertUnit(25)};
`
const StyledListItem = styled.li`
  font-family: 'Roboto-regular' !important;
  line-height: ${convertUnit(31)};
  padding-left: ${convertUnit(25)};
`
const StyledTextContainer = styled.div`
  text-align: justify;
`
const StyledTitle = styled(Paragraph)`
  ${({theme}) => ({
    color: theme.primary_5,
  })}
  text-align: justify;
`
const StyledSubTitle = styled(Paragraph)`
  ${({fontSize = 'xl', fontWeight = 'bold'}) => ({
    fontSize: typeof fontSize === 'number' ? fontSize : getFontSize(fontSize),
    fontFamily: getFontFamily(fontWeight),
  })}
  margin-top: ${convertUnit(20)};
  text-align: justify;
`
const StyledDescription = styled(Paragraph)`
  ${({fontSize = 'l'}) => ({
    fontSize: typeof fontSize === 'number' ? fontSize : getFontSize(fontSize),
  })}
  margin-top: ${convertUnit(10)};
  line-height: ${convertUnit(31)};
  white-space: pre-wrap;
  text-align: justify;
`
const StyledLink = styled.a`
  color: ${({theme}) => theme.primary_5};
  text-decoration: underline;
  cursor: pointer;
`

export default function LegalRefundPolicy() {
  const {translate} = useTranslation()

  const handleTitle = useMemo(
    () => (
      <StyledTextContainer>
        <StyledTitle fontWeight="bold" fontSize="xxl">
          {translate('policy:refundPolicy')}
        </StyledTitle>
        <StyledDescription>
          {translate('policy:refundPolicyAboutDescA')}
          <br />
          <br />
          {translate('policy:refundPolicyAboutDescB')}
          <br />
          <br />
          {translate('policy:refundPolicyAboutDescC')}
          <StyledLink
            href={`mailto:${translate('policy:emailSupport', {
              Domain: DOMAIN_NAME,
            })}`}>
            {translate('policy:emailSupport', {Domain: DOMAIN_NAME})}
          </StyledLink>
          .
        </StyledDescription>
      </StyledTextContainer>
    ),
    [translate],
  )

  const handlePolicy = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>{translate('policy:refundConditions')}</StyledSubTitle>
        <StyledDescription>
          {translate('policy:refundConditionsDescA')}
        </StyledDescription>
        <StyledDescription>
          {translate('policy:refundConditionsDescB')}
        </StyledDescription>
        <StyledList>
          {Array.from({length: 4}).map((_, index) => (
            <StyledListItem key={index.toString()}>
              {translate('policy:refundConditionsDescB', {
                context: `${index + 1}`,
              })}
            </StyledListItem>
          ))}
        </StyledList>
        <StyledDescription>
          {translate('policy:refundConditionsDescC')}
        </StyledDescription>
      </StyledTextContainer>
    ),
    [translate],
  )

  const handleTimeLimit = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>{translate('policy:refundDeadline')}</StyledSubTitle>
        <StyledDescription>
          {translate('policy:refundDeadlineDescA')}
          <br />
          <br />
          {translate('policy:refundDeadlineDescB')}
        </StyledDescription>
      </StyledTextContainer>
    ),
    [translate],
  )

  const handleProcedure = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>{translate('policy:refundProcedure')}</StyledSubTitle>
        <StyledDescription>
          {translate('policy:refundProcedureDescA')}
        </StyledDescription>
        <StyledList>
          {Array.from({length: 3}).map((_, index) => (
            <StyledListItem key={index.toString()}>
              {translate('policy:refundProcedureDescA', {
                context: `${index + 1}`,
              })}
              {index === 2 && (
                <StyledLink
                  href={`mailto:${translate('policy:emailSupport', {
                    Domain: DOMAIN_NAME,
                  })}`}>
                  {translate('policy:emailSupport', {Domain: DOMAIN_NAME})}.
                </StyledLink>
              )}
            </StyledListItem>
          ))}
        </StyledList>
        <StyledDescription>
          {translate('policy:refundProcedureDescB')}
        </StyledDescription>
      </StyledTextContainer>
    ),
    [translate],
  )
  return (
    <div>
      {handleTitle}
      {handlePolicy}
      {handleTimeLimit}
      {handleProcedure}
    </div>
  )
}
