import React, {useEffect, useMemo} from 'react'
import styled from 'styled-components'
import {getBorder, getFontFamily, getFontSize} from 'utils'
import {Paragraph, ParsedText} from 'common/components'
import convertUnit from 'lib/unit'
import {
  COMPANY_NAME,
  CREATOR_PRICE_EXAMPLE,
  CREATOR_PRICE_PERCENTAGE,
  FOTOYU_FEE_EXAMPLE,
  FOTOYU_FEE_PERCENTAGE,
  NET_CREATOR_EXAMPLE,
  NET_CREATOR_PERCENTAGE,
} from 'consts'
import {useTranslation} from 'i18n'
import {parseShape} from 'types'

const StyledList = styled.ol`
  padding-left: ${convertUnit(25)};
`

const StyledListItem = styled.li`
  font-family: ${getFontFamily('regular')};
  font-size: ${getFontSize('l')};
  line-height: ${convertUnit(31)};
  padding-left: ${convertUnit(25)};
`

const StyledTextContainer = styled.div`
  text-align: justify;
`

const StyledSubTitle = styled(Paragraph)`
  ${({fontSize = 'xl', fontWeight = 'bold'}) => ({
    fontSize: typeof fontSize === 'number' ? fontSize : getFontSize(fontSize),
    fontFamily: getFontFamily(fontWeight),
  })}
  margin-top: ${convertUnit(20)};
  text-align: justify;
`

const StyledDescription = styled(Paragraph)`
  ${({fontSize = 'l'}) => ({
    fontSize: typeof fontSize === 'number' ? fontSize : getFontSize(fontSize),
  })}
  margin-top: ${convertUnit(10)};
  line-height: ${convertUnit(31)};
  white-space: pre-wrap;
  text-align: justify;
`

const StyledTable = styled.table`
  margin: ${convertUnit(18)} 0;
  width: ${convertUnit(400)};
  border-collapse: collapse;
`

const StyledTableHeader = styled.tr`
  border: ${({theme}) => getBorder(2, 'solid', theme.black)};
  border-collapse: collapse;
`

const StyledTableContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const StyledPlusMinusIcon = styled.div`
  padding-top: ${convertUnit(65)};
  padding-left: ${convertUnit(10)};
`

export default function LegalServiceFee() {
  const {translate} = useTranslation()

  const patterns: parseShape[] = useMemo(() => {
    const regex = /\*\*(.+?)\*\*/
    return [
      {
        pattern: regex,
        style: {fontFamily: getFontFamily('bold')},
        renderText: (text) => text.replace(regex, `$1`),
      },
    ]
  }, [])

  const handleServiceFee = useMemo(
    () => (
      <StyledTextContainer id="pembayaran">
        <StyledSubTitle>{translate('policy:yuserPayment')}</StyledSubTitle>
        <StyledSubTitle fontSize="l">
          {translate('policy:serviceFee')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:serviceFeeDesc', {name: COMPANY_NAME})}
        </StyledDescription>
        <StyledSubTitle fontSize="l">
          {translate('policy:serviceFee_CreatorFee')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:serviceFee_CreatorFeeDesc')}
        </StyledDescription>
        <StyledTableContainer>
          <StyledTable>
            <tr>
              <td>
                <Paragraph fontSize="l">
                  {translate('policy:serviceFeeCreatorPrice')}
                </Paragraph>
              </td>
              <td>
                <Paragraph fontSize="l">{CREATOR_PRICE_PERCENTAGE}</Paragraph>
              </td>
              <td>
                <Paragraph fontSize="l">{CREATOR_PRICE_EXAMPLE}</Paragraph>
              </td>
            </tr>
            <tr>
              <td>
                <Paragraph fontSize="l">
                  {translate('policy:serviceFeeFotoyuFee')}
                </Paragraph>
              </td>
              <td>
                <Paragraph fontSize="l">{FOTOYU_FEE_PERCENTAGE}</Paragraph>
              </td>
              <td>
                <Paragraph fontSize="l">{FOTOYU_FEE_EXAMPLE}</Paragraph>
              </td>
            </tr>
            <StyledTableHeader>
              <th>
                <Paragraph fontSize="l" fontWeight="bold">
                  {translate('policy:serviceFeeNetCreator')}
                </Paragraph>
              </th>
              <th>
                <Paragraph fontSize="l" fontWeight="bold">
                  {NET_CREATOR_PERCENTAGE}
                </Paragraph>
              </th>
              <th>
                <Paragraph fontSize="l" fontWeight="bold">
                  {NET_CREATOR_EXAMPLE}
                </Paragraph>
              </th>
            </StyledTableHeader>
          </StyledTable>
          <StyledPlusMinusIcon>
            <Paragraph fontSize="l" fontWeight="bold">
              {'-'}
            </Paragraph>
          </StyledPlusMinusIcon>
        </StyledTableContainer>
        <StyledDescription>
          {translate('policy:serviceFee_CreatorFeeDescBottom')}
        </StyledDescription>
      </StyledTextContainer>
    ),
    [translate],
  )

  const handleWitdrawal = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>{translate('policy:withdraw')}</StyledSubTitle>
        <StyledList>
          {Array.from({length: 5}).map((_, index) => (
            <StyledListItem key={index.toString()}>
              <ParsedText parse={patterns}>
                {translate('policy:withdraw', {
                  context: `${index + 1}`,
                })}
              </ParsedText>
            </StyledListItem>
          ))}
        </StyledList>
      </StyledTextContainer>
    ),
    [patterns, translate],
  )

  const handleRounding = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>{translate('policy:rounding')}</StyledSubTitle>
        <StyledDescription>
          {translate('policy:roundingDesc')}
        </StyledDescription>
        <StyledSubTitle fontSize="l">
          {translate('policy:rounding_minimum')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:rounding_minimumDesc')}
        </StyledDescription>
        <StyledSubTitle fontSize="l">
          {translate('policy:rounding_refund')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:rounding_refundDesc')}
        </StyledDescription>
      </StyledTextContainer>
    ),
    [translate],
  )

  useEffect(() => {
    if (document.location.href.includes('#pembayaran')) {
      document
        .getElementById('pembayaran')
        ?.scrollIntoView({behavior: 'smooth'})
    }
  }, [])

  return (
    <div id="pembayaran">
      {handleServiceFee}
      {handleWitdrawal}
      {handleRounding}
    </div>
  )
}
