import React, {useCallback, useEffect, useMemo, useState} from 'react'
import styled from 'styled-components'
import {useForm} from 'react-hook-form'
import {AUTH_DOB_MIN_YEAR, AUTH_USER_MIN_AGE, IMAGE_ASSET} from 'consts'
import {useTranslation} from 'i18n'
import {AuthFormRegisterEasySignupData} from 'types'
import {
  useHistory,
  validateMinimumAge,
  parseDate,
  getUnderagePolicy,
  getReasonOfUsage,
  useLocation,
  showSnackbar,
} from 'utils'
import {requestData} from 'services'
import {
  Button,
  CheckboxItem,
  Icon,
  Image,
  Input,
  Paragraph,
} from 'common/components'
import convertUnit from 'lib/unit'
import {useSelector} from 'lib/redux'
import {TemplateLegalModal} from '../../../template'

const StyledIconContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${convertUnit(20)};
  width: 100%;
  margin-bottom: ${convertUnit(12)};
`

const StyledBackIconCursor = styled.div`
  cursor: pointer;
  width: fit-content;
`

const StyledImageContainer = styled.div`
  justify-content: center;
  display: flex;
  margin: ${convertUnit(30)} 0;
`

const AuthSelfieYuser = styled(Image)`
  object-fit: contain;
  width: ${convertUnit(226)};
  height: ${convertUnit(140)};
  flex-grow: 0;
`

const StyledParagraph = styled(Paragraph)`
  text-align: center;
  padding: 0;
`

const StyledParagraphContainer = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin-top: ${convertUnit(20)};
`
const StyledFooter = styled.div`
  padding: 0 ${convertUnit(20)};
`
const StyledButton = styled(Button)`
  margin-top: ${convertUnit(20)};
`
const StyledError = styled(Paragraph)`
  ${({theme}) => ({color: theme.danger_5})}
  box-sizing: border-box;
  margin-top: ${convertUnit(7)};
  margin-bottom: ${convertUnit(2)};
`
const StyledAgreement = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: ${convertUnit(20)};
`
const StyledCheckbox = styled(CheckboxItem)`
  margin-right: ${convertUnit(10)};
`
const StyledContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: center;
`

interface AuthEasySignupChildrenRegisterParentProps {
  isChildReapply?: boolean
}
export default function AuthEasySignupChildrenRegisterParent({
  isChildReapply,
}: AuthEasySignupChildrenRegisterParentProps) {
  const {translate} = useTranslation()
  const history = useHistory()
  const form = useForm<AuthFormRegisterEasySignupData>({
    defaultValues: {
      dob: undefined,
    },
  })
  const {watch, setValue} = form
  const values = watch()
  const {dob} = values
  const selfieWefie = IMAGE_ASSET('giftshop', 'wefie-illustration.png')
  const offset = useMemo(() => new Date().getTimezoneOffset(), [])
  const [dobError, setDobError] = useState(true)
  const data = useLocation('auth_easy_signup_underage').state

  const date = useMemo(() => new Date(new Date().getTime() - offset * 60000), [
    offset,
  ])
  const today = date.toISOString().substring(0, 10)
  const [focus, setFocus] = useState(false)
  type LegalModalType = 'underage' | 'reason'
  const [legalModal, setLegalModal] = useState<LegalModalType>()
  const invalidParentAge = useMemo(
    () =>
      dob &&
      !validateMinimumAge(
        parseDate(dob.toString(), 'DD/MM/YYYY'),
        AUTH_USER_MIN_AGE,
      ),
    [dob],
  )
  const {lang} = useSelector('languageState')
  const [showCheckbox, setShowCheckbox] = useState(false)
  const [agree, setAgree] = useState(false)
  const stateLoaded = useState(false)
  const loaded = stateLoaded[0]
  const [submitLoading, setSubmitLoading] = useState(false)

  const disabled = useMemo(
    () => dob === undefined || dobError || invalidParentAge || submitLoading,
    [dob, dobError, invalidParentAge, submitLoading],
  )

  const handleGetChildConsentCode = useCallback(() => {
    if (dob) {
      setSubmitLoading(true)
      requestData('giftshop_get_account_status', {
        onRequestReceived: () => setSubmitLoading(false),
        onRequestSuccess: ({status, data: {result}}) => {
          if (status === 200) {
            history.replace('giftshop_robopet_incomplete_child_wefie', {
              isChildReapply,
              child_consent_code: result.child_consent_code,
              parentDob: parseDate(dob.toString(), 'DD/MM/YYYY'),
            })
          } else {
            showSnackbar(
              translate('global:messageError', {context: `code-${status}`}),
            )
          }
        },
      })
    }
  }, [dob, history, isChildReapply, translate])

  const handleCheckDob = useCallback(() => {
    if (values.dob) {
      const value = values.dob
      const dobStr = value.toString()
      const dobDate = new Date(value)
      const dobYear = dobStr.substring(0, 4)
      const intDobYear = parseInt(dobYear, 10)

      if ((!focus && dobDate > date) || dobStr.length > 10) {
        setValue('dob', today)
      }

      if (
        (!dobYear.startsWith('0') && intDobYear < AUTH_DOB_MIN_YEAR) ||
        (!focus && dobYear.startsWith('0'))
      ) {
        setValue('dob', `${AUTH_DOB_MIN_YEAR}${dobStr.substring(4, 10)}`)
      }

      if (!focus && intDobYear > new Date().getFullYear()) {
        setValue('dob', today)
      }
      return dob === undefined || dobYear.startsWith('0')
    }
    return true
  }, [date, dob, focus, setValue, today, values.dob])

  const handleRenderInput = useMemo(
    () => (
      <>
        <Input
          name="dob"
          type="date"
          form={form}
          defaultValue={today}
          label={translate('auth:registerEasySignupParentDob')}
          labelDescriptionDirection="column"
          labelDescriptionWeight="regular"
          labelDescriptionColor="gray_7"
          labelDescription={translate(
            'auth:registerEasySignupParentDobRestriction',
            {
              age: AUTH_USER_MIN_AGE,
            },
          )}
          placeholder={translate('auth:registerDateOfBirthInputPlaceholder')}
          containerStyle={{marginTop: convertUnit(25)}}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          min={`${AUTH_DOB_MIN_YEAR}-01-01`}
          max={today}
        />
        {invalidParentAge && (
          <StyledError fontWeight="medium">
            {translate('auth:validationParentMinimumAge', {
              age: AUTH_USER_MIN_AGE,
            })}
          </StyledError>
        )}
      </>
    ),
    [form, invalidParentAge, today, translate],
  )

  const handleRenderContent = useMemo(
    () => (
      <>
        <StyledParagraphContainer>
          <StyledParagraph fontSize="m">
            {translate('auth:registerEasySignupChildDescription_1')}
          </StyledParagraph>
          <StyledParagraph fontSize="m">
            {translate('auth:registerEasySignupChildDescription_2')}
          </StyledParagraph>
        </StyledParagraphContainer>
        <StyledImageContainer>
          <AuthSelfieYuser src={selfieWefie} alt="SelfieWefie" />
        </StyledImageContainer>
        <StyledParagraph fontSize="m">
          {translate('auth:registerEasySignupParentInputDob')}
        </StyledParagraph>
        {handleRenderInput}
        <StyledButton
          label={translate('global:start')}
          disabled={disabled}
          isLoading={submitLoading}
          onClick={() => setLegalModal('underage')}
        />
      </>
    ),
    [disabled, handleRenderInput, selfieWefie, submitLoading, translate],
  )

  const handleClose = useCallback(() => {
    setShowCheckbox(false)
    setAgree(false)
    setLegalModal(undefined)
  }, [])

  const handleRenderLegalModal = useMemo(() => {
    let src: string | undefined = ''
    switch (legalModal) {
      case 'underage':
        src = getUnderagePolicy()
        break
      case 'reason':
        src = getReasonOfUsage()
        break
      default:
        return ''
    }
    return `${src}&lang=${lang}`
  }, [lang, legalModal])

  const handleAgree = useCallback(() => {
    if (legalModal === 'underage') {
      setLegalModal('reason')
      setShowCheckbox(true)
    } else if (legalModal === 'reason') {
      handleClose()
      if (dob) {
        if (isChildReapply) {
          handleGetChildConsentCode()
        } else {
          history.replace('auth_easy_signup_children_wefie', {
            ...data,
            parentDob: parseDate(dob.toString(), 'DD/MM/YYYY'),
          })
        }
      }
    }
  }, [
    data,
    dob,
    handleClose,
    handleGetChildConsentCode,
    history,
    isChildReapply,
    legalModal,
  ])

  useEffect(() => {
    setDobError(handleCheckDob())
  }, [handleCheckDob])

  return (
    <StyledContainer>
      <StyledIconContainer>
        <StyledBackIconCursor onClick={history.goBack}>
          <Icon type="back" size={convertUnit(20)} color="primary_5" />
        </StyledBackIconCursor>
        <Paragraph fontSize="l" fontWeight="bold">
          {translate('auth:registerEasySignupChildHeader')}
        </Paragraph>
      </StyledIconContainer>
      {handleRenderContent}
      <TemplateLegalModal
        visible={legalModal !== undefined}
        src={handleRenderLegalModal}
        onToggle={handleClose}
        stateLoaded={stateLoaded}
        footer={
          <StyledFooter>
            {showCheckbox && (
              <StyledAgreement>
                <StyledCheckbox
                  isSelected={agree}
                  onClick={() => setAgree((prev) => !prev)}
                />
                <Paragraph color="gray_5" fontSize="l">
                  {translate('giftShop:kycAgreement')}
                </Paragraph>
              </StyledAgreement>
            )}
            <StyledButton
              onClick={handleAgree}
              label={translate('global:iAgree')}
              disabled={!loaded || (showCheckbox && !agree)}
            />
          </StyledFooter>
        }
      />
    </StyledContainer>
  )
}
