import React, {useCallback, useMemo, useState} from 'react'
import Lottie from 'lottie-react'
import styled from 'styled-components'
import {Icon, Image, List, Modal, Paragraph} from 'common/components'
import {IMAGE_ASSET} from 'consts'
import {useTranslation} from 'i18n'
import convertUnit from 'lib/unit'
import {useDispatch, useSelector} from 'lib/redux'
import {requestData} from 'services'
import {
  TreeFototreeEventCalendarData,
  TreeFototreeEventCalendarResponse,
} from 'types'
import {getBorder, useDidMount, useHistory} from 'utils'
import animationData from 'assets/lottie/activity_indicator.json'
import {FototreeMapEventScheduleProps} from './FototreeMapEventScheduleProps'
import FototreeMapEventScheduleItem from './FototreeMapEventScheduleItem'

const StyledLottie = styled(Lottie)`
  height: ${convertUnit(100)};
  width: ${convertUnit(100)};
`

const StyledModal = styled(Modal)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 75%;
  background-color: ${({theme}) => theme.white_3};
  max-width: ${convertUnit(480)};
  max-height: ${convertUnit(677)};
  margin: 0 ${convertUnit(20)};
  box-sizing: border-box;
  overflow: hidden;
`

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  width: fill-available;
  background-color: ${({theme}) => theme.white_1};
  border-bottom: ${({theme}) => getBorder(1, 'solid', theme.white_4)};
  padding: ${convertUnit(12)} ${convertUnit(16)};
  gap: ${convertUnit(12)};
`

const StyledLoadingContainer = styled.div`
  align-items: center;
  justify-content: center;
  margin: auto;
`

const StyledEmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding: ${convertUnit(20)};
  gap: ${convertUnit(20)};
`

const StyledEmptyImg = styled(Image)`
  object-fit: contain;
  max-width: ${convertUnit(100)};
  max-height: ${convertUnit(130)};
`

export default function FototreeMapEventSchedule({
  location,
  toggleModal,
}: FototreeMapEventScheduleProps) {
  const emptyImg = IMAGE_ASSET('giftshop', 'not-found.png')
  const history = useHistory()
  const {update} = useDispatch()
  const {translate} = useTranslation()
  const {lang} = useSelector('user') || {}
  const [data, setData] = useState<TreeFototreeEventCalendarResponse[]>([])
  const [loading, setLoading] = useState(false)

  const handleLoadData = useCallback(() => {
    setLoading(true)
    requestData('tree_get_event_calendar', {
      useDefaultMessage: true,
      headers: {'X-Location': `${location.longitude},${location.latitude}`},
      onRequestReceived: () => setLoading(false),
      onRequestSuccess: ({status, data: {result}}) => {
        if (status === 200) {
          setData(result)
        }
      },
    })
  }, [location.latitude, location.longitude])

  const handleNavigateFotoTreeDetail = useCallback(
    (item: TreeFototreeEventCalendarData) => {
      update('lastTreeState', {videoIntro: true})
      history.push(
        'tree_fototree_detail',
        {
          treeId: item.id,
          treeAlias: item.alias,
        },
        item.alias,
      )
    },
    [history, update],
  )

  const handleRenderHeader = useMemo(
    () => (
      <StyledHeader>
        <Paragraph fontSize="m" fontWeight="medium">
          {translate('tree:eventSchedule')}
        </Paragraph>
        <Paragraph
          fontSize="m"
          fontWeight="medium"
          style={{marginLeft: 'auto'}}>
          {new Intl.DateTimeFormat(lang, {
            month: 'long',
            year: 'numeric',
          }).format(new Date())}
        </Paragraph>
        <Icon
          type="close"
          color="primary_7"
          style={{cursor: 'pointer'}}
          onClick={toggleModal}
        />
      </StyledHeader>
    ),
    [lang, toggleModal, translate],
  )

  const handleRenderEmpty = useMemo(
    () => (
      <StyledEmptyContainer>
        <StyledEmptyImg src={emptyImg} alt={emptyImg} />
        <Paragraph fontSize="l" fontWeight="bold" style={{textAlign: 'center'}}>
          {translate('tree:eventScheduleEmpty')}
        </Paragraph>
      </StyledEmptyContainer>
    ),
    [emptyImg, translate],
  )

  const handleRenderLoading = useMemo(
    () => (
      <StyledLoadingContainer>
        <StyledLottie animationData={animationData} />
      </StyledLoadingContainer>
    ),
    [],
  )

  const handleRenderListItem = useCallback(
    (item: TreeFototreeEventCalendarResponse) => (
      <FototreeMapEventScheduleItem
        data={item}
        onClick={handleNavigateFotoTreeDetail}
      />
    ),
    [handleNavigateFotoTreeDetail],
  )

  const handleRenderList = useMemo(
    () => (
      <List
        data={data}
        scrollbar={false}
        keyExtractor={({regency}) => regency}
        renderItem={handleRenderListItem}
        listEmptyElement={handleRenderEmpty}
        contentContainerStyle={{
          padding: convertUnit(12),
          gap: convertUnit(8),
        }}
      />
    ),
    [data, handleRenderEmpty, handleRenderListItem],
  )

  useDidMount(handleLoadData)

  return (
    <StyledModal visible>
      {handleRenderHeader}
      {loading ? handleRenderLoading : handleRenderList}
    </StyledModal>
  )
}
