import React, {ReactElement} from 'react'
import styled from 'styled-components'
import {Icon, Modal} from 'common/components'
import convertUnit from 'lib/unit'
import {ObjectState} from 'types'

const StyledModal = styled(Modal)`
  width: ${convertUnit(420)};
  padding-bottom: ${convertUnit(20)};
  display: flex;
  flex-direction: column;
  height: ${convertUnit(470)};
  overflow-y: scroll;
  scrollbar-width: none;
  position: relative;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`

const StyledIcon = styled.div`
  margin: ${convertUnit(20)};
  width: ${convertUnit(20)};
  height: ${convertUnit(20)};
  background-color: ${({theme}) => theme.gray_5};
  border-radius: ${convertUnit(6)};
  padding: ${convertUnit(3)} ${convertUnit(2)};
  align-self: flex-end;
`

const StyledIframe = styled.iframe`
  flex: 1;
  border: 0;
  width: 100%;
  height: ${convertUnit(470)};
`

interface TemplateLegalModalProps {
  visible: boolean
  src: string
  onToggle(): void
  footer?: ReactElement
  stateLoaded?: ObjectState<boolean>
}

export default function TemplateLegalModal({
  visible,
  onToggle,
  src,
  footer,
  stateLoaded,
}: TemplateLegalModalProps) {
  const setLoaded = stateLoaded && stateLoaded[1]
  return (
    <StyledModal visible={visible}>
      <StyledIcon onClick={onToggle}>
        <Icon type="close" size={14} color={'white_1'} />
      </StyledIcon>
      <StyledIframe
        src={src}
        title="Legal"
        onLoad={() => setLoaded && setLoaded(true)}
      />
      {footer || <></>}
    </StyledModal>
  )
}
