import React, {useCallback, useMemo, useRef, useState} from 'react'
import styled from 'styled-components'
import {
  GIFT_SHOP_UPLOAD_TYPE,
  INITIAL_DATA_IMAGE,
  WINDOW_MODE_TABLET_WIDTH,
} from 'consts'
import {useTranslation} from 'i18n'
import {GiftshopTemplateUploadResendModal} from 'pages'
import {
  GiftshopResendDataType,
  GiftshopResendModalState,
  GiftshopResendSrcType,
  GiftShopUploadResendData,
  WindowModeType,
} from 'types'
import {
  clearUserCache,
  getBorder,
  handleGiftshopReadFile,
  showSnackbar,
  useDebounce,
  useDidMount,
  useDidUpdate,
  useGiftShopResendHandler,
  useHistory,
} from 'utils'
import {useWindowMode} from 'windows'
import {Button, ButtonOutline, InputPicker, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {useSelector} from 'lib/redux'
import {GiftShopProfileResendSingleListProps} from './GiftShopProfileResendSingleListProps'
import {GiftShopProfileResendSingleContent} from '../ResendSingleContent'

interface StyledFooterContainerProps {
  mode: WindowModeType
}

const StyledHeaderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: ${convertUnit(12)} 0;
  margin-bottom: ${convertUnit(20)};

  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    margin-bottom: ${convertUnit(1)};
  }
`

const StyledScrollContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: ${convertUnit(25)};
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${({theme}) => theme.primary_5};
    background-clip: content-box;
    border: ${convertUnit(8)} solid ${({theme}) => theme.white_1};
    border-radius: ${convertUnit(16)};
  }
`

const StyledHeaderItem = styled(Paragraph)`
  width: 50%;
  text-align: center;
`

const StyledFooterContainer = styled.div<StyledFooterContainerProps>`
  ${({theme, mode}) => ({
    borderTop: getBorder(1, 'solid', theme.gray_1),
    padding: `${convertUnit(15)} ${convertUnit(mode === 'mobile' ? 25 : 50)}`,
    backgroundColor: theme.white_1,
  })}
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`

const StyledUploadButton = styled(Button)`
  flex: 1;
  margin: ${convertUnit(5)};
`

const StyledAddPhotoButton = styled(ButtonOutline)`
  flex: 1;
  margin: ${convertUnit(5)};
`

const StyledTryAgain = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${convertUnit(20)};
  margin-top: ${convertUnit(20)};
`

const StyledButton = styled(Button)`
  width: 100%;
`

export default function GiftShopProfileResendSingleList({
  data,
  source = 'profile',
}: GiftShopProfileResendSingleListProps) {
  const {translate} = useTranslation()
  const {username} = useSelector('user') || {}
  const mode = useWindowMode()
  const stateResendData = useState<GiftshopResendDataType>()
  const [resendData, setResendData] = stateResendData
  const stateResendContent = useState<GiftShopUploadResendData>({
    image: INITIAL_DATA_IMAGE,
  })
  const {uploadResend} = useGiftShopResendHandler({
    stateContent: stateResendContent,
  })
  const resendRef = useRef<HTMLInputElement>(null)
  const resendModalState = useState<GiftshopResendModalState>('none')
  const setResendModal = resendModalState[1]
  const history = useHistory()
  const [success, setSuccess] = useState(0)
  const debounce = useDebounce()
  const [src, setSrc] = useState<GiftshopResendSrcType>(undefined)
  const [loadingImg, setLoadingImg] = useState(false)

  const handleResend = useCallback(async () => {
    setResendModal('loading')
    const isSuccess =
      resendData && (await uploadResend(resendData.image, resendData.id))
    if (isSuccess) {
      setSuccess((prev) => prev + 1)
    } else {
      setResendModal('failed')
    }
  }, [resendData, setResendModal, uploadResend])

  const handleRenderHeader = useMemo(
    () => (
      <StyledHeaderContainer>
        <StyledHeaderItem
          fontSize={mode === 'website' ? 'm' : 's'}
          fontWeight="bold"
          color="primary_5">
          {translate('global:standard')}
        </StyledHeaderItem>
        <StyledHeaderItem
          fontSize={mode === 'website' ? 'm' : 's'}
          fontWeight="bold"
          color="primary_5">
          {translate('global:hires')}
        </StyledHeaderItem>
      </StyledHeaderContainer>
    ),
    [mode, translate],
  )

  const handleRenderItem = useMemo(
    () => (
      <>
        <GiftShopProfileResendSingleContent
          data={data}
          src={src}
          loadingImg={loadingImg}
        />
      </>
    ),
    [data, loadingImg, src],
  )

  const handleRenderButton = useMemo(
    () => (
      <StyledFooterContainer mode={mode}>
        <StyledAddPhotoButton
          label={
            src
              ? translate('giftShop:changePhotoButton')
              : translate('giftShop:addPhoto')
          }
          borderColor="gray_5"
          color="gray_5"
          onClick={() => resendRef.current?.click()}
        />
        <StyledUploadButton
          disabled={resendData === undefined}
          label={translate('global:upload')}
          backgroundColor="primary_5"
          onClick={handleResend}
        />
      </StyledFooterContainer>
    ),
    [handleResend, mode, resendData, src, translate],
  )

  const handleRenderTryAgain = useMemo(
    () => (
      <StyledTryAgain>
        <StyledButton
          label={translate('global:close')}
          color="primary_5"
          backgroundColor="white_2"
          onClick={() =>
            history.replace('giftshop_profile', {self: true}, username)
          }
        />
        <StyledButton
          label={translate('global:tryAgain')}
          onClick={() => setResendModal('none')}
        />
      </StyledTryAgain>
    ),
    [history, setResendModal, translate, username],
  )

  const handleRenderModalResendStatus = useMemo(
    () => (
      <GiftshopTemplateUploadResendModal
        resendModalState={resendModalState}
        bottomElement={handleRenderTryAgain}
        success={success}
        total={1}
      />
    ),
    [handleRenderTryAgain, resendModalState, success],
  )

  const handleReadFile = useCallback(
    async (file: File) => {
      setLoadingImg(true)
      if (!GIFT_SHOP_UPLOAD_TYPE.includes(file.type)) {
        showSnackbar(translate('giftShop:invalidFormatData'))
        setLoadingImg(false)
        return
      }

      const image = await handleGiftshopReadFile(file, undefined, false)

      if (image !== null) {
        setResendData({id: data.content_id, image})
        setSrc({
          date:
            image.exif?.CreateDate?.toISOString() ||
            new Date(image.file.lastModified).toISOString() ||
            new Date().toISOString(),
          name: image.file.name,
          resolution: {
            height: image.height,
            width: image.width,
          },
          size: image.size,
          url: image.src,
        })
      }
      if (file.name !== data.title) {
        showSnackbar(translate('giftShop:resendContentDifferentFile'))
      }
      setLoadingImg(false)
    },
    [data.content_id, data.title, setResendData, translate],
  )

  const handleLoadFiles = useCallback(
    (files: File[]) => {
      Array.from(files).forEach((file) => {
        handleReadFile(file)
      })
    },
    [handleReadFile],
  )

  useDidMount(() => {
    resendRef.current?.click()
  })

  useDidUpdate(() => {
    if (success === 1) {
      setResendModal('success')
      clearUserCache('creation')
      debounce(() => {
        if (source === 'host') {
          history.replace('tree_host_profile', {})
        } else history.replace('giftshop_profile', {self: true}, username)
      }, 500)
    }
  }, [success, history])

  return (
    <>
      {handleRenderModalResendStatus}
      <StyledScrollContainer>
        {handleRenderHeader}
        {handleRenderItem}
      </StyledScrollContainer>
      {handleRenderButton}
      <InputPicker inputRef={resendRef} onLoadFiles={handleLoadFiles} />
    </>
  )
}
