import React, {useMemo} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {getFontSize} from 'utils'
import {Paragraph} from 'common/components'
import convertUnit from 'lib/unit'

const StyledTextContainer = styled.div`
  text-align: justify;
`
const StyledTitle = styled(Paragraph)`
  ${({theme}) => ({
    color: theme.primary_5,
  })}
  text-align: justify;
`
const StyledParagraph = styled(Paragraph)`
  ${({fontSize = 'l'}) => ({
    fontSize: typeof fontSize === 'number' ? fontSize : getFontSize(fontSize),
  })}
  margin-top: ${convertUnit(20)};
  text-align: justify;
`
const StyledLink = styled.a`
  color: ${({theme}) => theme.primary_5};
  text-decoration: underline;
  cursor: pointer;
`

export default function LegalSupergigaContent() {
  const {translate} = useTranslation()

  const handleMaintenance = useMemo(
    () => (
      <StyledTextContainer>
        <StyledTitle fontSize="xxl" fontWeight="bold">
          {translate('policy:supergiga')}
        </StyledTitle>
        <StyledParagraph>
          <StyledLink href="https://www.supergiga.com" target="_blank">
            www.supergiga.com
          </StyledLink>
          <br />
          <br />
        </StyledParagraph>
      </StyledTextContainer>
    ),
    [translate],
  )

  return handleMaintenance
}
