import React, {useMemo} from 'react'
import styled from 'styled-components'
import {howItWorkLang, useTranslation} from 'i18n'
import {getFontFamily, getFontSize} from 'utils'
import {Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {LegalSellGuidelinesProps} from './LegalSellGuidelinesProps'

const StyledOL = styled.ol`
  padding: 0;
  margin: 0;
  padding-left: ${convertUnit(16)};
  font-family: ${getFontFamily('regular')};
  font-size: ${getFontSize('s')};
`

const StyledInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: ${convertUnit(16)};
  padding-top: ${convertUnit(4)};
  padding-left: ${convertUnit(16)};
  padding-right: ${convertUnit(16)};
`

const StyledTitle = styled(Paragraph)`
  padding: 0 ${convertUnit(16)};
  line-height: ${convertUnit(26)};
`

const StyledParagraph = styled(Paragraph)`
  line-height: ${convertUnit(21)};
`

export default function LegalSellGuidelines({
  isVerified,
}: LegalSellGuidelinesProps) {
  const {translate} = useTranslation()

  const handleRenderTitle = useMemo(
    () => (
      <StyledTitle fontSize="m" fontWeight="bold">
        {translate('policy:guidelineSellTitle')}
      </StyledTitle>
    ),
    [translate],
  )

  const handleRenderInfo = useMemo(
    () => (
      <StyledInfoContainer>
        <StyledOL>
          <li>
            <StyledParagraph>
              {translate('policy:guidelineSellContent1')}
            </StyledParagraph>
          </li>
          <li>
            <StyledParagraph>
              {translate('policy:guidelineSellContent2')}
            </StyledParagraph>
          </li>
          <li>
            <StyledParagraph>
              {translate('policy:guidelineSellContent3')}
            </StyledParagraph>
          </li>
          <li>
            <StyledParagraph>
              {translate('policy:guidelineSellContent4')}
            </StyledParagraph>
          </li>
          <li>
            <StyledParagraph>
              {translate('policy:guidelineSellContent5')}
            </StyledParagraph>
          </li>
          <li>
            <StyledParagraph>
              {translate('policy:guidelineSellContent6')}
            </StyledParagraph>
          </li>
          <li>
            <StyledParagraph>
              {translate('policy:guidelineSellContent7')}
            </StyledParagraph>
          </li>
          <li>
            <StyledParagraph>
              {translate('policy:guidelineSellContent8')}
            </StyledParagraph>
          </li>
          <li>
            <StyledParagraph>
              {translate('policy:guidelineSellContent9')}
            </StyledParagraph>
          </li>
          {isVerified && (
            <li>
              <StyledParagraph>
                {translate('policy:guidelineSellContent10_verifiedCreator')}
              </StyledParagraph>
            </li>
          )}
          <li>
            <StyledParagraph>
              {translate('policy:guidelineSellContent11')}
            </StyledParagraph>
          </li>
        </StyledOL>
        {howItWorkLang() === 'id' && (
          <StyledParagraph>
            {translate('policy:guidelineSellFooter')}
          </StyledParagraph>
        )}
      </StyledInfoContainer>
    ),
    [isVerified, translate],
  )

  return (
    <div>
      {handleRenderTitle}
      {handleRenderInfo}
    </div>
  )
}
