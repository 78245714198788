import React, {useMemo} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {
  getCommunityGuideline,
  getCopyright,
  getFontFamily,
  getFontSize,
} from 'utils'
import {useTheme} from 'themes'
import {parseShape} from 'types'
import {Paragraph, ParsedText} from 'common/components'
import convertUnit from 'lib/unit'

const StyledList = styled.ol`
  font-family: ${getFontFamily('regular')};
  font-size: ${getFontSize('l')};
  padding-left: ${convertUnit(25)};
`
const StyledListItem = styled.li`
  line-height: ${convertUnit(31)};
  padding-left: ${convertUnit(25)};
`
const StyledTextContainer = styled.div`
  text-align: justify;
`
const StyledSubTitle = styled(Paragraph)`
  ${({fontSize = 'l'}) => ({
    fontSize: typeof fontSize === 'number' ? fontSize : getFontSize(fontSize),
  })}
  margin-top: ${convertUnit(20)};
  text-align: justify;
`
const StyledDescription = styled(Paragraph)`
  ${({fontSize = 'l'}) => ({
    fontSize: typeof fontSize === 'number' ? fontSize : getFontSize(fontSize),
  })}
  white-space: pre-wrap;
  margin-top: ${convertUnit(10)};
  line-height: ${convertUnit(31)};
  text-align: justify;
`
const StyledLink = styled.a`
  color: ${({theme}) => theme.primary_5};
  text-decoration: underline;
  cursor: pointer;
`

export default function LegalDosAndDontsContent({
  scrollTo,
}: {
  scrollTo: (childRef: number | undefined) => void
}) {
  const theme = useTheme()
  const {translate} = useTranslation()
  const isSection = useMemo(
    () => document.location.href.includes('section'),
    [],
  )

  const pattern = useMemo((): parseShape[] => {
    const regex = /\[(.+?)\]/
    return [
      {
        pattern: regex,
        style: {
          cursor: 'pointer',
          color: theme.primary_5,
          textDecoration: 'underline',
        },
        renderText: (text) => text.replace(regex, `$1`),
        onClick: () => (isSection ? window.open(getCopyright()) : scrollTo(8)),
      },
    ]
  }, [isSection, scrollTo, theme.primary_5])

  const handleYuser = useMemo(
    () => (
      <>
        <StyledSubTitle fontWeight="bold">
          {translate('policy:termsOfUseOtherDoAndDont')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:termsOfUseOtherDoAndDontDesc')}
        </StyledDescription>
        <StyledDescription>
          {translate('policy:termsOfUseYuserDoList')}
        </StyledDescription>
        <StyledList>
          {Array.from({length: 7}).map((_, index) => (
            <StyledListItem key={index.toString()}>
              {translate('policy:termsOfUseYuserDoList', {
                context: `${index + 1}`,
              })}
              {index === 5 && (
                <StyledLink
                  href={isSection ? getCopyright() : '#'}
                  target={isSection ? '_blank' : undefined}
                  onClick={() => scrollTo(8)}>
                  {translate('policy:copyright')}.
                </StyledLink>
              )}
              {index === 6 && (
                <StyledLink
                  href={isSection ? getCommunityGuideline() : '#'}
                  target={isSection ? '_blank' : undefined}
                  onClick={() => scrollTo(6)}>
                  {translate('policy:communityGuideline')}.
                </StyledLink>
              )}
            </StyledListItem>
          ))}
        </StyledList>
        <StyledDescription>
          {translate('policy:termsOfUseYuserDontList')}
        </StyledDescription>
        <StyledList>
          {Array.from({length: 10}).map((_, index) => {
            if (index === 5) {
              return (
                <StyledListItem>
                  <ParsedText parse={pattern}>
                    {translate('policy:termsOfUseYuserDontList', {
                      context: '6',
                    })}
                  </ParsedText>
                </StyledListItem>
              )
            }
            return (
              <StyledListItem key={index.toString()}>
                {translate('policy:termsOfUseYuserDontList', {
                  context: `${index + 1}`,
                })}
                {index === 1 && (
                  <StyledLink
                    href={isSection ? getCommunityGuideline() : '#'}
                    target={isSection ? '_blank' : undefined}
                    onClick={() => scrollTo(6)}>
                    {translate('policy:communityGuideline')}.
                  </StyledLink>
                )}
              </StyledListItem>
            )
          })}
        </StyledList>
      </>
    ),
    [isSection, pattern, scrollTo, translate],
  )

  const handleCreator = useMemo(
    () => (
      <>
        <StyledDescription>
          {translate('policy:termsOfUseCreatorDoList')}
        </StyledDescription>
        <StyledList>
          {Array.from({length: 3}).map((_, index) => (
            <StyledListItem key={index.toString()}>
              {translate('policy:termsOfUseCreatorDoList', {
                context: `${index + 1}`,
              })}
              {index === 1 && (
                <StyledLink
                  href={isSection ? getCommunityGuideline() : '#'}
                  target={isSection ? '_blank' : undefined}
                  onClick={() => scrollTo(6)}>
                  {translate('policy:communityGuideline')}.
                </StyledLink>
              )}
            </StyledListItem>
          ))}
        </StyledList>
        <StyledDescription>
          {translate('policy:termsOfUseCreatorDontList')}
        </StyledDescription>
        <StyledList>
          {Array.from({length: 2}).map((_, index) => (
            <StyledListItem key={index.toString()}>
              {translate('policy:termsOfUseCreatorDontList', {
                context: `${index + 1}`,
              })}
              {index === 1 && (
                <StyledLink
                  href={isSection ? getCopyright() : '#'}
                  target={isSection ? '_blank' : undefined}
                  onClick={() => scrollTo(8)}>
                  {translate('policy:copyright')}.
                </StyledLink>
              )}
            </StyledListItem>
          ))}
        </StyledList>
      </>
    ),
    [isSection, scrollTo, translate],
  )

  const handleBoth = useMemo(
    () => (
      <>
        <StyledDescription fontWeight="bold">
          {translate('policy:termsOfUseYuserAndCreatorDontList')}
        </StyledDescription>
        <StyledList>
          {Array.from({length: 4}).map((_, index) => (
            <StyledListItem key={index.toString()}>
              {translate('policy:termsOfUseYuserAndCreatorDontList', {
                context: `${index + 1}`,
              })}
            </StyledListItem>
          ))}
        </StyledList>
      </>
    ),
    [translate],
  )

  return (
    <StyledTextContainer>
      {handleYuser}
      {handleCreator}
      {handleBoth}
    </StyledTextContainer>
  )
}
