import React, {useCallback, useMemo, useRef, useState} from 'react'
import {useParams} from 'react-router'
import {UseFormMethods} from 'react-hook-form'
import styled from 'styled-components'
import Lottie from 'lottie-react'
import {useTranslation} from 'i18n'
import {requestData} from 'services'
import {
  ForkygramPostTagData,
  ForkygramUploadData,
  TreeFotoTreeLeafData,
  TreeFotoTreeLeafFavorite,
  TreeFotoTreeLeafFootprint,
  TreeFotoTreeLeafResponse,
} from 'types'
import {
  showSnackbar,
  useDidMount,
  useHistory,
  useLocation,
  useQueryParamValue,
} from 'utils'
import {ModalLoading} from 'common/components'
import {
  FORKYGRAM_FAVORITES_QUERY_LIMIT,
  FORKYGRAM_FEED_QUERY_LIMIT,
  WINDOW_MODE_MOBILE_WIDTH,
  WINDOW_MODE_TABLET_WIDTH,
} from 'consts'
import convertUnit from 'lib/unit'
import {useSelector} from 'lib/redux'
import animationBackgroundData from 'assets/lottie/tree_background.json'
import {
  FototreeMemoryContentList,
  FototreeMemoryEditContent,
} from '../containers'

interface StyledRootContainerProps {
  isEdit: boolean
}

const StyledRootContainer = styled.div<StyledRootContainerProps>`
  ${({isEdit, theme}) => ({
    backgroundColor: isEdit ? theme.white_1 : theme.black,
  })}
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
`

const StyledLottieBackgound = styled(Lottie)`
  position: fixed;
  height: 100%;
  width: 100%;
  margin-left: ${convertUnit(-20)};
  margin-top: ${convertUnit(180)};
  background: radial-gradient(40% 75% at 50% 30%, #3c5366 0%, #06080b 55%);
  transform: scale(1.8);

  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    margin-top: ${convertUnit(50)};
    transform: scale(2.6);
  }

  @media (max-width: ${WINDOW_MODE_MOBILE_WIDTH}px) {
    margin-top: 0;
    transform: scale(4);
  }
`

export default function TreeFototreeFeedScreen() {
  const history = useHistory()
  const {translate} = useTranslation()
  const {alias}: {alias: string} = useParams()
  const {id} = useQueryParamValue(['id'])
  const {
    tagId,
    treeId: treeIdState = '',
    treeAlias,
    selectedItemId,
    memberId,
    initialPage,
    initialPageFavorite,
  } = useLocation('tree_fototree_feed').state
  const {id: userId} = useSelector('user') || {}
  const stateData = useState<ReadonlyArray<TreeFotoTreeLeafData>>([])
  const shareData = useRef<TreeFotoTreeLeafResponse>()
  const formRef = useRef<UseFormMethods<ForkygramUploadData>>()
  const favPage = useRef({page: initialPageFavorite, isEnd: false})
  const [loading, setLoading] = useState(false)
  const [data, setData] = stateData
  const [dataTag, setDataTag] = useState<ForkygramPostTagData>()
  const stateScrollPos = useState(0)
  const statePause = useState(false)
  const stateMute = useState(true)
  const stateEdit = useState<TreeFotoTreeLeafFootprint>()
  const [edit, setEdit] = stateEdit
  const [treeId, setTreeId] = useState(treeIdState)

  const handleChackTreeExist = useCallback(() => {
    requestData('tree_get_fototree_check', {
      params: {alias},
      onRequestSuccess: ({status, data: {result: detail}}) => {
        if (status === 200) {
          if (alias !== treeAlias) setTreeId(detail.id)
          setDataTag({
            ...detail,
            id: detail.tag_id,
            tree_id: detail.id,
          })
        }
      },
    })
  }, [alias, treeAlias])

  const handleLoadShareData = useCallback(async () => {
    if (id) {
      await requestData('tree_get_leaf_share', {
        params: {id},
        onRequestSuccess: ({status, data: {result}}) => {
          if (status === 200) shareData.current = result
        },
      })
    }
  }, [id])

  const handleLoadLeavesData = useCallback(
    async (
      page: number,
      limit: number,
    ): Promise<TreeFotoTreeLeafFootprint[]> => {
      if (page === 1) await handleLoadShareData()
      const response = await requestData('tree_get_leaves', {
        actionType: 'fetch',
        useDefaultMessage: true,
        params: {
          page,
          limit,
          tree_id: treeId,
          member_id: memberId,
        },
      })
      if (typeof response !== 'string' && response.status === 200) {
        if (shareData.current) {
          const responses = [shareData.current, ...response.data.result]
          return responses.map((item) => ({
            ...item,
            type: 'footprint',
          }))
        }
        return response.data.result.map((item) => ({
          ...item,
          type: 'footprint',
        }))
      }
      return []
    },
    [handleLoadShareData, memberId, treeId],
  )

  const handleLoadFavoriteData = useCallback(async (): Promise<
    TreeFotoTreeLeafFavorite[]
  > => {
    const {page, isEnd} = favPage.current

    if (userId !== memberId) return []
    if (!tagId || !page || isEnd) return []

    const response = await requestData('giftshop_get_favorite', {
      useDefaultMessage: true,
      actionType: 'fetch',
      params: {
        page,
        limit: FORKYGRAM_FAVORITES_QUERY_LIMIT,
        tag_id: tagId,
      },
    })

    favPage.current.page = page + 1

    if (typeof response !== 'string' && response.status === 200) {
      const {result} = response.data
      if (result.length < FORKYGRAM_FAVORITES_QUERY_LIMIT) {
        favPage.current.isEnd = true
      }
      return result.filter(
        ({type}) => type === 'fotoyu',
      ) as TreeFotoTreeLeafFavorite[]
    }
    return []
  }, [memberId, tagId, userId])

  const handleLoadData = useCallback(
    async (page: number, limit: number) => {
      const [leaves, favorites] = await Promise.all<TreeFotoTreeLeafData[]>([
        handleLoadLeavesData(page, limit),
        handleLoadFavoriteData(),
      ])

      const favEndIndex = Math.floor(
        (leaves.length + Math.min(favorites.length, 1)) /
          FORKYGRAM_FAVORITES_QUERY_LIMIT,
      )

      if (
        initialPageFavorite &&
        initialPageFavorite + 1 === favPage.current.page &&
        leaves.length >= FORKYGRAM_FEED_QUERY_LIMIT
      ) {
        return [...favorites.slice(0, 1), ...leaves, ...favorites.slice(1)]
      }

      return [
        ...leaves.slice(0, favEndIndex),
        ...favorites.slice(0, 1),
        ...leaves.slice(favEndIndex),
        ...favorites.slice(1),
      ]
    },
    [handleLoadFavoriteData, handleLoadLeavesData, initialPageFavorite],
  )

  const handlePatchPost = useCallback(() => {
    if (edit && formRef.current) {
      const {caption, tag} = formRef.current.getValues()
      setLoading(true)
      requestData('forkygram_patch_post', {
        useDefaultMessage: true,
        actionType: 'execute',
        data: {id: edit.post_id, caption, tag_id: tag.id},
        onRequestReceived: () => setLoading(false),
        onRequestSuccess: ({status}) => {
          if (status === 200) {
            showSnackbar(translate('forkygram:postUpdated'))
            setData((prev) =>
              prev.map((item) =>
                item.type === 'footprint' && item.post_id === edit.post_id
                  ? {...item, caption}
                  : item,
              ),
            )
            if (tag.id !== dataTag?.id) {
              setData((prev) =>
                prev.filter(
                  (item) =>
                    item.type === 'footprint' && item.post_id !== edit.post_id,
                ),
              )
              data.length <= 1 && history.goBack()
            }
            setEdit(undefined)
          }
        },
      })
    }
  }, [data.length, dataTag?.id, edit, history, setData, setEdit, translate])

  const handleRenderList = useMemo(
    () =>
      treeId && (
        <FototreeMemoryContentList
          stateScrollPos={stateScrollPos}
          stateData={stateData}
          stateEdit={stateEdit}
          stateMute={stateMute}
          statePause={statePause}
          selectedId={selectedItemId}
          dataTag={dataTag}
          initialPage={initialPage}
          loadData={handleLoadData}
        />
      ),
    [
      dataTag,
      handleLoadData,
      initialPage,
      selectedItemId,
      stateData,
      stateEdit,
      stateMute,
      statePause,
      stateScrollPos,
      treeId,
    ],
  )

  const handleRenderEditContent = useMemo(
    () => (
      <FototreeMemoryEditContent
        stateEdit={stateEdit}
        dataTag={dataTag}
        formRef={formRef}
        onPatchPost={handlePatchPost}
      />
    ),
    [dataTag, handlePatchPost, stateEdit],
  )

  useDidMount(handleChackTreeExist)

  return (
    <StyledRootContainer isEdit={!!edit}>
      <ModalLoading visible={loading} />
      {edit ? (
        handleRenderEditContent
      ) : (
        <>
          <StyledLottieBackgound animationData={animationBackgroundData} />
          {handleRenderList}
        </>
      )}
    </StyledRootContainer>
  )
}
