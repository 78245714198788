import React, {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {GiftShopTemplateMasonry} from 'pages'
import {requestData} from 'services'
import {
  GiftShopContentData,
  GiftShopCreationType,
  TreeFetchCreationGroupData,
} from 'types'
import {useDidMount, useHistory, useLocation} from 'utils'
import {useDispatch, useSelector} from 'lib/redux'
import {
  TemplateTabListHeaderCreation,
  TemplateProfileEmptyTab,
  TemplateProfileCreationGroup,
} from '../../../../template'

const StyledListContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`

export default function TreeHostProfileContent() {
  const location = useLocation('tree_host_profile')
  const {type: locationCreationType} = location.query
  const stateCreationType = useState<GiftShopCreationType>(
    locationCreationType || 'all',
  )
  const creationType = stateCreationType[0]
  const {update} = useDispatch()
  const {resendCount} = useSelector('hostState')
  const stateCreationData = useState<ReadonlyArray<GiftShopContentData>>([])
  const data = stateCreationData[0]
  const stateGroupData = useState<ReadonlyArray<TreeFetchCreationGroupData>>([])
  const history = useHistory()
  const {translate} = useTranslation()

  const handleClickChip = useCallback(
    (type: GiftShopCreationType) => {
      history.replaceQuery({
        path: 'tree_host_profile',
        state: undefined,
        queryObject: {
          type,
        },
      })
    },
    [history],
  )

  const handleFetchHost = useCallback(() => {
    requestData('tree_fetch_hosts', {
      onRequestSuccess: (value) => {
        if (value.status === 200) {
          const {
            host_id,
            cover_photo,
            logo_photo,
            admin_count,
            creator_count,
            maximum_price,
            minimum_price,
            tag_name,
            balance,
            biodata: newBiodata,
            independent_creator_fee,
            internal_creator_fee,
            role,
            is_first_buy_enabled,
          } = value.data.result
          update('hostState', {
            hostId: host_id,
            coverPhoto: cover_photo,
            logoPhoto: logo_photo,
            adminCount: admin_count,
            creatorCount: creator_count,
            maximumPrice: maximum_price,
            minimumPrice: minimum_price,
            tagName: tag_name,
            balance,
            biodata: newBiodata,
            independentCreatorFee: independent_creator_fee,
            internalCreatorFee: internal_creator_fee,
            role,
            isFirstBuyEnabled: is_first_buy_enabled,
          })
        }
      },
    })
  }, [update])

  const handleLoadMasonry = useCallback(
    async (page: number, limit: number) => {
      const response = await requestData('tree_fetch_creation', {
        useDefaultMessage: true,
        actionType: 'fetch',
        params: {
          page,
          limit,
          filter: creationType,
          sort_by: creationType === 'sold' ? 'purchased_at' : 'created_at',
          sort_dir: 'desc',
        },
        onRequestSuccess: ({status, data: {result}}) => {
          const {total_resend, total_data} = result
          status === 200 &&
            update('hostState', {
              resendCount: total_resend,
              sellCount: total_data,
            })
        },
      })
      if (typeof response !== 'string' && response.status === 200) {
        const {data: creationData} = response.data.result
        return creationData
      }
      return []
    },
    [creationType, update],
  )

  const handleLoadGroup = useCallback(
    async (page: number, limit: number) => {
      const response = await requestData('tree_get_creation_group', {
        useDefaultMessage: true,
        actionType: 'fetch',
        params: {
          page,
          limit,
          filter: creationType,
          sort_by: 'created_at',
          sort_dir: 'desc',
        },
        onRequestSuccess: ({status, data: {result}}) => {
          const {total_resend, total_data} = result
          status === 200 &&
            update('hostState', {
              resendCount: total_resend,
              sellCount: total_data,
            })
        },
      })

      return typeof response !== 'string' && response.status === 200
        ? response.data.result.group_data
        : []
    },
    [creationType, update],
  )

  const handleClickMasonry = useCallback(
    (item: GiftShopContentData) => {
      history.push('tree_host_content_detail', {
        data: [...data],
        selectedItemId: item.content_id,
        creationType,
      })
    },
    [creationType, data, history],
  )

  const handleClickGroup = useCallback(
    (item: TreeFetchCreationGroupData) => {
      history.pushQuery({
        path: 'tree_host_creation_group',
        state: {
          date: item.date.substring(0, 10),
          creationType,
        },
        queryObject: {
          date: item.date.substring(0, 10),
          type: creationType,
        },
      })
    },
    [creationType, history],
  )

  const handleRefreshContent = useMemo(
    () => (
      <TemplateTabListHeaderCreation
        resendCount={resendCount || 0}
        resendpologyCount={0}
        stateCreationType={stateCreationType}
        isHost
        onClickChip={handleClickChip}
      />
    ),
    [handleClickChip, resendCount, stateCreationType],
  )

  const handleRenderEmptyTabAll = useMemo(
    () => (
      <TemplateProfileEmptyTab
        title={translate('giftShop:hostCreationEmptyTitle')}
        description={translate('giftShop:hostCreationEmptyDesc')}
      />
    ),
    [translate],
  )

  const handleRenderEmpty = useMemo(
    () => (
      <TemplateProfileEmptyTab
        title={translate('giftShop:hostCreationEmptyTitle', {
          context: creationType,
        })}
        description={translate('giftShop:hostCreationEmptyDesc', {
          context: creationType,
        })}
      />
    ),
    [creationType, translate],
  )

  const handleRenderGroup = useMemo(
    () =>
      (creationType === 'all' || creationType === 'available') && (
        <TemplateProfileCreationGroup
          loadData={handleLoadGroup}
          onClickItem={handleClickGroup}
          search={creationType}
          stateData={stateGroupData}
          scrollbar={false}
          listEmptyElement={
            creationType === 'all' ? handleRenderEmptyTabAll : handleRenderEmpty
          }
        />
      ),
    [
      creationType,
      handleClickGroup,
      handleLoadGroup,
      handleRenderEmpty,
      handleRenderEmptyTabAll,
      stateGroupData,
    ],
  )

  const handleRenderMasonry = useMemo(
    () =>
      (creationType === 'resend' || creationType === 'sold') && (
        <GiftShopTemplateMasonry
          showUnwishlistBadge
          showPurchaseStatusBadge
          loadData={handleLoadMasonry}
          listEmptyElement={handleRenderEmpty}
          onClickItem={handleClickMasonry}
          scrollbar={false}
          stateData={stateCreationData}
          search={creationType}
        />
      ),
    [
      creationType,
      handleClickMasonry,
      handleLoadMasonry,
      handleRenderEmpty,
      stateCreationData,
    ],
  )

  useDidMount(handleFetchHost)

  return (
    <>
      {handleRefreshContent}
      <StyledListContainer>
        {handleRenderGroup}
        {handleRenderMasonry}
      </StyledListContainer>
    </>
  )
}
