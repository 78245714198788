import React, {useMemo} from 'react'
import styled from 'styled-components'
import {getFontFamily, getFontSize} from 'utils'
import {Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {useTranslation} from 'i18n'
import {COMPANY_NAME} from 'consts'

const StyledList = styled.ol`
  padding-left: ${convertUnit(25)};
`
const StyledListItem = styled.li`
  font-family: ${getFontFamily('regular')};
  font-size: ${getFontSize('l')};
  line-height: ${convertUnit(31)};
  padding-left: ${convertUnit(25)};
`
const StyledTextContainer = styled.div`
  text-align: justify;
`
const StyledTitle = styled(Paragraph)`
  ${({theme}) => ({
    color: theme.primary_5,
  })}
  text-align: justify;
`
const StyledSubTitle = styled(Paragraph)`
  ${({fontSize = 'xl'}) => ({
    fontSize: typeof fontSize === 'number' ? fontSize : getFontSize(fontSize),
  })}
  margin-top: ${convertUnit(20)};
  text-align: justify;
`

const StyledDescription = styled(Paragraph)`
  ${({fontSize = 'l'}) => ({
    fontSize: typeof fontSize === 'number' ? fontSize : getFontSize(fontSize),
  })}
  margin-top: ${convertUnit(10)};
  line-height: ${convertUnit(31)};
  white-space: pre-wrap;
  text-align: justify;
`

export default function LegalPolicyCookie() {
  const {translate} = useTranslation()

  const handleTitle = useMemo(
    () => (
      <StyledTextContainer>
        <StyledTitle fontWeight="bold" fontSize="xxl">
          {translate('policy:cookie')}
        </StyledTitle>
        <StyledSubTitle fontSize="xl" fontWeight="bold">
          {translate('policy:cookieDefine')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:cookieDefineDesc')}
        </StyledDescription>
      </StyledTextContainer>
    ),
    [translate],
  )

  const handleFunction = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle fontSize="xl" fontWeight="bold">
          {translate('policy:cookiePurpose')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:cookiePurposeDesc')}
        </StyledDescription>
      </StyledTextContainer>
    ),
    [translate],
  )

  const handleShouldWeUse = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle fontSize="xl" fontWeight="bold">
          {translate('policy:cookieMatter')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:cookieMatterDesc')}
        </StyledDescription>
      </StyledTextContainer>
    ),
    [translate],
  )
  const handleLocation = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle fontSize="xl" fontWeight="bold">
          {translate('policy:cookieDataLocation')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:cookieDataLocationDesc', {
            name: COMPANY_NAME,
          })}
          <StyledList>
            {Array.from({length: 6}).map((_, index) => (
              <StyledListItem key={index.toString()}>
                {translate(`policy:cookieDataLocation`, {
                  context: `${index + 1}`,
                })}
              </StyledListItem>
            ))}
          </StyledList>
        </StyledDescription>
      </StyledTextContainer>
    ),
    [translate],
  )
  const handleParty = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle fontSize="xl" fontWeight="bold">
          {translate('policy:cookieThridParty')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:cookieThridPartyDesc', {name: COMPANY_NAME})}
        </StyledDescription>
      </StyledTextContainer>
    ),
    [translate],
  )
  const handleControlCookie = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle fontSize="xl" fontWeight="bold">
          {translate('policy:cookieControl')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:cookieControlDesc', {name: COMPANY_NAME})}
        </StyledDescription>
      </StyledTextContainer>
    ),
    [translate],
  )

  return (
    <div>
      {handleTitle}
      {handleFunction}
      {handleShouldWeUse}
      {handleLocation}
      {handleParty}
      {handleControlCookie}
    </div>
  )
}
