import React, {useMemo} from 'react'
import styled from 'styled-components'
import {IMAGE_ASSET, WINDOW_MODE_MOBILE_WIDTH} from 'consts'
import {useTranslation} from 'i18n'
import {getFontFamily, getFontSize, getHowItWorksWebUrl} from 'utils'
import {Icon, Image, Paragraph, ParsedText} from 'common/components'
import convertUnit from 'lib/unit'
import {parseShape} from 'types'

const StyledList = styled.ol`
  padding-left: ${convertUnit(25)};
`
const StyledListItem = styled.li`
  font-family: ${getFontFamily('regular')};
  font-size: ${getFontSize('l')};
  line-height: ${convertUnit(31)};
  padding-left: ${convertUnit(25)};
`
const StyledTextContainer = styled.div`
  text-align: justify;
`
const StyledFullImageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  @media (max-width: ${WINDOW_MODE_MOBILE_WIDTH}px) {
    flex-direction: column;
  }
`
const StyledImageContainer = styled.div`
  padding: ${convertUnit(10)};
  max-width: 350px;
  @media (max-width: ${WINDOW_MODE_MOBILE_WIDTH}px) {
    display: flex;
    flex-direction: column;
  }
`
const StyledTitle = styled(Paragraph)`
  ${({theme}) => ({
    color: theme.primary_5,
  })}
  text-align: justify;
`
const StyledSubTitle = styled(Paragraph)`
  ${({fontSize = 'xl', fontWeight = 'bold'}) => ({
    fontSize: typeof fontSize === 'number' ? fontSize : getFontSize(fontSize),
    fontFamily: getFontFamily(fontWeight),
  })}
  margin-top: ${convertUnit(20)};
  text-align: justify;
`
const StyledParagraph = styled(Paragraph)`
  line-height: ${convertUnit(31)};
  margin: auto;
  white-space: pre-wrap;
`
const StyledDescription = styled(Paragraph)`
  ${({fontSize = 'l'}) => ({
    fontSize: typeof fontSize === 'number' ? fontSize : getFontSize(fontSize),
  })}
  margin-top: ${convertUnit(10)};
  line-height: ${convertUnit(31)};
  white-space: pre-wrap;
  text-align: justify;
`
const StyledImage = styled(Image)`
  height: 100%;
  max-width: 100%;
  max-height: 300px;
  margin: ${convertUnit(10)} ${convertUnit(5)};
  object-fit: contain;
  border-radius: 5px;
`
const StyledLink = styled.a`
  color: ${({theme}) => theme.primary_5};
  text-decoration: underline;
  cursor: pointer;
`

export default function LegalBiometricFaceContent({
  scrollTo,
}: {
  scrollTo: (childRef: number | undefined) => void
}) {
  const {translate} = useTranslation()
  const isSection = useMemo(
    () => document.location.href.includes('section'),
    [],
  )

  const patterns = useMemo((): parseShape[] => {
    const regex = /\*\*(.+?)\*\*/
    return [
      {
        pattern: regex,
        style: {fontFamily: getFontFamily('bold')},
        renderText: (text) => text.replace(regex, `$1`),
      },
    ]
  }, [])

  const handleTitle = useMemo(
    () => (
      <StyledTextContainer>
        <StyledTitle fontWeight="bold" fontSize="xxl">
          {translate('policy:biometricPolicy')}
        </StyledTitle>
        <StyledSubTitle>
          {translate('policy:biometricFaceRecognition')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:biometricFaceRecognitionDescA')}
          <StyledLink
            href={isSection ? getHowItWorksWebUrl() : '#'}
            target={isSection ? '_blank' : undefined}
            onClick={() => scrollTo(0)}>
            {translate('policy:howItWorks')}.
          </StyledLink>
          <br />
          {translate('policy:biometricFaceRecognitionDescB')}
          <br />
          {translate('policy:biometricFaceRecognitionDescC')}
          <br />
          <ParsedText parse={patterns}>
            {translate('policy:biometricFaceRecognitionDescD')}
          </ParsedText>
        </StyledDescription>
      </StyledTextContainer>
    ),
    [isSection, patterns, scrollTo, translate],
  )

  const handleWhy = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>
          {translate('policy:biometricWhyNeeded')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:biometricWhyNeededA')}
        </StyledDescription>
      </StyledTextContainer>
    ),
    [translate],
  )

  const handleChoice = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>
          {translate('policy:biometricYourChoice')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:biometricYourChoiceDescA')}
          <br />
          <br />
          <ParsedText parse={patterns}>
            {translate('policy:biometricYourChoiceDescB')}
          </ParsedText>
          <br />
          <ParsedText parse={patterns}>
            {translate('policy:biometricYourChoiceDescC')}
          </ParsedText>
          <br />
          <ParsedText parse={patterns}>
            {translate('policy:biometricYourChoiceDescD')}
          </ParsedText>
          <br />
          <ParsedText parse={patterns}>
            {translate('policy:biometricYourChoiceDescE')}
          </ParsedText>
        </StyledDescription>
      </StyledTextContainer>
    ),
    [patterns, translate],
  )

  const handleHowItWorks = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>
          {translate('policy:biometricHowFaceRecognitionWorks')}
        </StyledSubTitle>
        <StyledDescription>
          <StyledList>
            {Array.from({length: 3}).map((_, index) => (
              <StyledListItem key={index.toString()}>
                {translate('policy:biometricHowFaceRecognitionWorks', {
                  context: `${index + 1}`,
                })}
              </StyledListItem>
            ))}
          </StyledList>
        </StyledDescription>
      </StyledTextContainer>
    ),
    [translate],
  )

  const handleFaceRecognition = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>
          {translate('policy:biometricFaceRecognitionInAI')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:biometricFaceRecognitionInAIDescA')}
        </StyledDescription>
        <StyledFullImageContainer>
          <StyledImageContainer>
            <StyledImage src={IMAGE_ASSET('howitworks', '2.png')} alt={'img'} />
            <StyledParagraph fontSize="m">
              {translate('policy:biometricFaceRecognitionInAIDescAPhoto1')}
            </StyledParagraph>
          </StyledImageContainer>
          <Icon type="double-arrow-right" size={50} />
          <StyledImageContainer>
            <StyledImage src={IMAGE_ASSET('howitworks', '3.png')} alt={'img'} />
            <StyledParagraph fontSize="m">
              {translate('policy:biometricFaceRecognitionInAIDescAPhoto2')}
            </StyledParagraph>
          </StyledImageContainer>
        </StyledFullImageContainer>
        <StyledDescription>
          {translate('policy:biometricFaceRecognitionInAIDescB')}
          <br />
          {translate('policy:biometricFaceRecognitionInAIDescC')}
          <br />
          <ParsedText parse={patterns}>
            {translate('policy:biometricFaceRecognitionInAIDescD')}
          </ParsedText>
          <br />
          {translate('policy:biometricFaceRecognitionInAIDescE')}
        </StyledDescription>
      </StyledTextContainer>
    ),
    [patterns, translate],
  )

  const handleConfirmation = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>
          {translate('policy:biometricConfirmPhoto')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:biometricConfirmPhotoDescA')}
          <br />
          {translate('policy:biometricConfirmPhotoDescB')}
        </StyledDescription>
      </StyledTextContainer>
    ),
    [translate],
  )

  const handleBuyPhoto = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>
          {translate('policy:biometricBuyYourPhotoOnly')}
        </StyledSubTitle>
        <StyledDescription>
          <ParsedText parse={patterns}>
            {translate('policy:biometricBuyYourPhotoOnlyDescA')}
          </ParsedText>
          <br />
          {translate('policy:biometricBuyYourPhotoOnlyDescB')}
          <br />
          {translate('policy:biometricBuyYourPhotoOnlyDescC')}
          <br />
          {translate('policy:biometricBuyYourPhotoOnlyDescD')}
          <br />
          {translate('policy:biometricBuyYourPhotoOnlyDescE')}
          <br />
          {translate('policy:biometricBuyYourPhotoOnlyDescF')}
          <br />
          {translate('policy:biometricBuyYourPhotoOnlyDescG')}
          <br />
          {translate('policy:biometricBuyYourPhotoOnlyDescH')}
          <br />
          {translate('policy:biometricBuyYourPhotoOnlyDescI')}
        </StyledDescription>
      </StyledTextContainer>
    ),
    [patterns, translate],
  )

  const handleDestroyData = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>
          {translate('policy:biometricDataDestruction')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:biometricDataDestructionDesc')}
        </StyledDescription>
      </StyledTextContainer>
    ),
    [translate],
  )

  return (
    <div>
      {handleTitle}
      {handleWhy}
      {handleChoice}
      {handleHowItWorks}
      {handleFaceRecognition}
      {handleConfirmation}
      {handleBuyPhoto}
      {handleDestroyData}
    </div>
  )
}
