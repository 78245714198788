import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {UseFormMethods} from 'react-hook-form'
import {
  FlyToInterpolator,
  InteractiveMapProps,
  MapRef,
  Marker,
  Popup,
  MapEvent,
} from 'react-map-gl'
import {Visibility} from 'maplibre-gl'
import styled, {CSSProperties} from 'styled-components'
import {PointFeature} from 'supercluster'
import {
  GIFT_SHOP_UPLOAD_DEFAULT_LOCATION,
  IMAGE_ASSET,
  SERVICE_CANCELLATION_GET_CREATOR_LIVE,
  TREE_MAP_CREATOR_ZOOM,
  TREE_MAP_DEFAULT_ZOOM,
  TREE_MAP_FOCUS_ZOOM,
  TREE_MAP_LAYER_ID_ACTIVE,
  TREE_MAP_LAYER_ID_CREATOR_LIVE,
  TREE_MAP_LAYER_ID_CREATOR_LIVE_ACTIVE,
  TREE_MAP_LAYER_ID_FOTOTREE,
  TREE_MAP_MAX_CLUSTER_ZOOM,
  TREE_MAP_MAX_ZOOM,
  TREE_MAP_MIN_ZOOM,
  WINDOW_MODE_MOBILE_WIDTH,
  WINDOW_MODE_TABLET_WIDTH,
} from 'consts'
import {useTranslation} from 'i18n'
import {requestData} from 'services'
import {
  GiftshopLiveLocationData,
  TreeCreatorActiveFeatureData,
  TreeFototreeForm,
  TreeFotoTreeMapPointData,
  TreeFotoTreeResponse,
  TreeFotoTreeSearchFeatureData,
  TreeMapFootprintOverlayType,
  TreeMapModalActionType,
  TreeMapViewType,
  UserPosition,
  WindowModeType,
} from 'types'
import {
  formatHours,
  getBoxShadow,
  isCanGoBack,
  isInsideBounds,
  showModalLimitedAccess,
  showSnackbar,
  useCountdown,
  useDebounce,
  useDidMount,
  useDidUpdate,
  useHistory,
  useLocation,
  useMapImage,
  useMapSource,
  useQueryParamValue,
} from 'utils'
import {useWindowMode} from 'windows'
import {Button, ButtonIcon, Icon, Map, ModalFullscreen} from 'common/components'
import convertUnit from 'lib/unit'
import {Location} from 'types/location'
import {useDispatch, useSelector} from 'lib/redux'
import {GiftShopTemplateShareModal, TemplateAuthAccessModal} from 'pages'
import {TreeFototreeModalProps} from './TreeFototreeModalProps'
import TreeSearchInput from './TreeSearchInput'
import TreePopupContent from './TreePopupContent'
import TreeSelfMarker from './TreeSelfMarker'
import TreeActiveMarker from './TreeActiveMarker'
import {FototreeFootprint} from '../footprint'
import {FototreeTallestTree} from '../tallest-tree'
import {
  FototreeMapCreatorCameraLiveModal,
  FototreeMapCreatorCatwalk,
  FototreeMapCreatorPopupContent,
  FototreeMapEventSchedule,
  FototreeMapPinpoint,
} from '../map'

interface StyledContainerProps {
  mode: WindowModeType
}

const StyledModalFullscreen = styled(ModalFullscreen)<CSSProperties>`
  display: ${({display}) => display};
  border-radius: 0;
  z-index: 0;
`

const StyledMapContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  background-color: ${({theme}) => theme.white_2};
  order: 2;

  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    order: 1;
  }
`
const StyledIcon = styled(ButtonIcon)`
  background-color: ${({theme}) => theme.white_1};
`

const StyledButtonTopContainer = styled.div<StyledContainerProps>`
  position: absolute;
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: ${({mode}) =>
    mode === 'mobile' ? convertUnit(335) : convertUnit(509)};
  top: ${convertUnit(0)};
  left: ${convertUnit(0)};
  padding: ${convertUnit(20)};
  gap: ${convertUnit(10)};
  justify-content: flex-start;
`

const StyledButtonBottomLeftVert = styled.div`
  display: flex;
  gap: ${convertUnit(12)};
`

const StyledButtonBottomLeftContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  bottom: ${convertUnit(20)};
  left: ${convertUnit(20)};
  gap: ${convertUnit(12)};

  @media (max-width: ${WINDOW_MODE_MOBILE_WIDTH}px) {
    max-width: 40%;
  }
`

const StyledButtonBottomRightContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  bottom: ${convertUnit(20)};
  right: ${convertUnit(20)};
  gap: ${convertUnit(20)};
  align-items: flex-end;
  justify-content: flex-end;

  @media (max-width: ${WINDOW_MODE_MOBILE_WIDTH}px) {
    max-width: 40%;
  }
`

const StyledView = styled.div`
  position: relative;
  display: flex;
  box-sizing: border-box;
  border-radius: ${convertUnit(12)};
  width: ${convertUnit(180)};
  height: ${convertUnit(42)};
  background-color: ${({theme}) => theme.white_1};
  box-shadow: ${({theme}) =>
    getBoxShadow(theme, {
      horizontalOffset: 0,
      opacity: 0.24,
    })};
`

const StyledButtonViewLabel = styled(Button)`
  width: calc(100% / 3);
  height: inherit;
  border-radius: ${convertUnit(12)};
`

const StyledButtonViewIcon = styled(ButtonIcon)`
  width: calc(100% / 3);
  height: inherit;
  border-radius: ${convertUnit(12)};
`

export default function TreeFototreeModal({
  gpsEnabled,
  permissionModal,
}: TreeFototreeModalProps) {
  const loc: Location = JSON.parse(GIFT_SHOP_UPLOAD_DEFAULT_LOCATION)
  const {coord, member_id} = useQueryParamValue(['member_id', 'coord'])

  const {translate} = useTranslation()
  const {update} = useDispatch()
  const debounce = useDebounce()
  const history = useHistory()
  const {latitude, longitude, username, access_token, id} =
    useSelector('user') || {}
  const lastTree = useSelector('lastTreeState') || {}
  const {creatorStatus} = useSelector('yuserActivationState')
  const {status: childStatus} = useSelector('parentalConsentState')
  const mode = useWindowMode()

  const popupInfo = useRef({coords: [0, 0], data: {} as any})
  const mapRef = useRef<MapRef>(null)
  const {initialData} = useLocation('tree_fototree').state || {}

  const mapAttributeState = useState<InteractiveMapProps>({
    latitude:
      (coord && Number(coord?.split(',')[1])) ||
      initialData?.location.latitude ||
      lastTree.latitude ||
      latitude ||
      loc.latitude ||
      0,
    longitude:
      (coord && Number(coord?.split(',')[0])) ||
      initialData?.location.longitude ||
      lastTree.longitude ||
      longitude ||
      loc.longitude ||
      0,
    zoom: coord
      ? TREE_MAP_CREATOR_ZOOM
      : initialData
      ? TREE_MAP_FOCUS_ZOOM
      : TREE_MAP_DEFAULT_ZOOM,
    maxZoom: TREE_MAP_MAX_ZOOM,
    minZoom: TREE_MAP_MIN_ZOOM,
  })
  const [mapAttribute, setMapAttribute] = mapAttributeState
  const [lastMapCoordinate, setLastMapCoordinate] = useState<
    UserPosition & {zoom?: number}
  >()
  const [bbox, setBbox] = useState<{zoom: number; bounds: GeoJSON.Position[]}>()
  const [mapForm] = useState<UseFormMethods<TreeFototreeForm>>()
  const [showPopup, setShowPopup] = useState(false)
  const setPermissionModal = permissionModal[1]
  const [pinPointData, setPinPointData] = useState<TreeFotoTreeResponse[]>([])
  const [pinPointFootprintData, setPinPointFootprintData] = useState<
    TreeFotoTreeResponse[]
  >([])
  const stateSearchFeatureData = useState<
    PointFeature<TreeFotoTreeSearchFeatureData>
  >()
  const [activeFeatureData, setActiveFeatureData] = stateSearchFeatureData
  const [creatorActiveFeatureData, setCreatorActiveFeatureData] = useState<
    PointFeature<TreeCreatorActiveFeatureData>
  >()
  const [loginModal, setLoginModal] = useState(false)
  const stateLoading = useState(false)
  const setLoading = stateLoading[1]
  const stateOverlayFootprint = useState<TreeMapFootprintOverlayType>('hidden')
  const stateTallestTreeModal = useState(false)
  const [overlayFootprint, setOverlayFootprint] = stateOverlayFootprint
  const setTallestTreeModal = stateTallestTreeModal[1]
  const stateCreatorLiveExpired = useState<number | null>(null)
  const [creatorLiveData, setCreatorLiveData] = useState<
    GiftshopLiveLocationData[]
  >([])
  const [modal, setModal] = useState<TreeMapModalActionType>('hidden')
  const [creatorLiveExpired, setCreatorLiveExpired] = stateCreatorLiveExpired
  const [viewMode, setViewMode] = useState<TreeMapViewType>(
    access_token && coord ? 'creator' : access_token ? 'all' : 'fototree',
  )

  const countdown = useCountdown({
    endTime: creatorLiveExpired || 0,
  })

  const isCreatorLive = useMemo(
    () => countdown.hours > 0 || countdown.minutes > 0 || countdown.seconds > 0,
    [countdown],
  )

  useMapImage({
    mapRef,
    url: [
      IMAGE_ASSET('tree', 'icons/pinpoint-community.png'),
      IMAGE_ASSET('tree', 'icons/pinpoint-event.png'),
      IMAGE_ASSET('tree', 'icons/pinpoint-place.png'),
      IMAGE_ASSET('tree', 'icons/pinpoint-creator.png'),
    ],
    name: [
      'map-pin-community',
      'map-pin-event',
      'map-pin-place',
      'map-pin-creator',
    ],
  })

  const {cluster, creatorCluster} = useMapSource({
    data: overlayFootprint === 'hidden' ? pinPointData : pinPointFootprintData,
    dataCreator: creatorLiveData,
    zoomLevel:
      viewMode === 'creator' && isCreatorLive
        ? TREE_MAP_MAX_ZOOM
        : Math.floor(mapAttribute.zoom ?? TREE_MAP_MIN_ZOOM),
  })

  const isMapInteractive = useMemo(
    () =>
      viewMode !== 'creator' ||
      (viewMode === 'creator' &&
        (overlayFootprint !== 'hidden' || !isCreatorLive)),
    [isCreatorLive, overlayFootprint, viewMode],
  )

  const handleGetCreatorLiveStatus = useCallback(async () => {
    if (!access_token || creatorStatus !== 'verified') return
    await requestData('giftshop_get_live_location_status', {
      onRequestSuccess: ({status, data}) => {
        if (status === 200) {
          setCreatorLiveExpired(new Date(data.result.expired_at).getTime())
        }
      },
    })
  }, [access_token, creatorStatus, setCreatorLiveExpired])

  const handleLoadData = useCallback(
    (zoom: number, bounds: GeoJSON.Position[]) => {
      requestData('tree_get_fototree', {
        params: {
          bbox: JSON.stringify(bounds),
          zoom_level: zoom,
        },
        onRequestSuccess: ({status, data}) => {
          if (status === 200) {
            setPinPointData(data.result)
          }
        },
      })
    },
    [],
  )

  const handleLoadMapFootprintData = useCallback(() => {
    setLoading(true)
    requestData('tree_get_fototree_footprint', {
      useDefaultMessage: true,
      onRequestReceived: () => setLoading((prev) => !prev),
      onRequestSuccess: ({status, data}) => {
        if (status === 200) {
          setPinPointFootprintData(data.result)
        }
      },
    })
  }, [setLoading])

  const handleGetCreatorStatus = useCallback(() => {
    if (!access_token) return
    requestData('giftshop_get_creator_verification_status', {
      useDefaultMessage: true,
      onRequestSuccess: ({status, data: {result}}) => {
        if (status === 200) {
          update('yuserActivationState', {
            creatorStatus: result.status,
          })
        }
      },
    })
  }, [access_token, update])

  const handleUpdateCreatorActiveFeatureData = useCallback(
    (data: GiftshopLiveLocationData[]) => {
      if (member_id) {
        const matchingMember = data.find(({member}) => member?.id === member_id)

        if (matchingMember) {
          const {member, expired_at, location} = matchingMember
          setCreatorActiveFeatureData({
            type: 'Feature',
            properties: {
              member_id: member.id,
              member_url: member.url,
              member_username: member.username,
              expired_at,
            },
            geometry: {
              type: 'Point',
              coordinates: [location.long, location.lat],
            },
          })
        } else {
          if (member_id !== id && !creatorLiveExpired) {
            setShowPopup(false)
            showSnackbar(translate('tree:creatorLiveExpired'))
          }
          setCreatorActiveFeatureData(undefined)
          history.replace('tree_fototree', {})
        }
      }
    },
    [creatorLiveExpired, history, id, member_id, translate],
  )

  const handleLoadCreatorLiveData = useCallback(
    (coor: Omit<Location, 'name'>) => {
      if (!access_token) return
      requestData('giftshop_get_live_location', {
        useDefaultMessage: true,
        cancelId: SERVICE_CANCELLATION_GET_CREATOR_LIVE,
        headers: {
          'X-Location': `${coor.longitude},${coor.latitude}`,
        },
        onRequestSuccess: ({status, data}) => {
          if (status === 200) {
            setCreatorLiveData(data.result)
            handleUpdateCreatorActiveFeatureData(data.result)
          }
        },
      })
    },
    [access_token, handleUpdateCreatorActiveFeatureData],
  )

  const updateMapBoxPosition = useCallback(
    (newLat: number, newLng: number) => {
      setMapAttribute((old) => ({
        ...old,
        latitude: newLat,
        longitude: newLng,
        transitionInterpolator: new FlyToInterpolator(),
        dragRotate: false,
      }))
    },
    [setMapAttribute],
  )

  const handleActiveFeatureData = useCallback(() => {
    if (member_id) {
      setCreatorActiveFeatureData({
        type: 'Feature',
        properties: {
          member_id,
        },
        geometry: {
          type: 'Point',
          coordinates: [
            Number(coord?.split(',')[0]),
            Number(coord?.split(',')[1]),
          ],
        },
      })
      return
    }

    if (initialData) {
      setActiveFeatureData({
        type: 'Feature',
        properties: {
          id: initialData.id,
          name: initialData.name,
          category: initialData.category_name,
          alias: '',
        },
        geometry: {
          type: 'Point',
          coordinates: [
            initialData.location.longitude,
            initialData.location.latitude,
          ],
        },
      })
      return
    }
    setCreatorActiveFeatureData(undefined)
    setActiveFeatureData(undefined)
  }, [coord, initialData, member_id, setActiveFeatureData])

  const handleFocusUserLocation = useCallback(() => {
    if (gpsEnabled) {
      if (latitude && longitude) {
        updateMapBoxPosition(latitude, longitude)
      } else {
        updateMapBoxPosition(loc.latitude, loc.longitude)
      }
    } else if (mapForm && !gpsEnabled) setPermissionModal(true)
  }, [
    gpsEnabled,
    latitude,
    loc.latitude,
    loc.longitude,
    longitude,
    mapForm,
    setPermissionModal,
    updateMapBoxPosition,
  ])

  const handleGetCurrentLocation = useCallback(() => {
    if (gpsEnabled) {
      if (!latitude || !longitude) update('user', {latitude, longitude})
      setMapAttribute((prev) => ({
        ...prev,
        zoom:
          viewMode === 'creator'
            ? TREE_MAP_CREATOR_ZOOM
            : TREE_MAP_DEFAULT_ZOOM,
        minZoom:
          viewMode === 'creator' && isCreatorLive
            ? TREE_MAP_CREATOR_ZOOM
            : TREE_MAP_MIN_ZOOM,
      }))
      handleFocusUserLocation()
    } else setPermissionModal(true)
  }, [
    gpsEnabled,
    handleFocusUserLocation,
    isCreatorLive,
    latitude,
    longitude,
    setMapAttribute,
    setPermissionModal,
    update,
    viewMode,
  ])

  const handleBoundsChange = useCallback(
    (zoom: number, bounds: GeoJSON.Position[]) => {
      setBbox({zoom, bounds})
      debounce(() => handleLoadData(zoom, bounds), 500)
    },
    [debounce, handleLoadData],
  )

  const handleUpdateClusterData = useCallback(() => {
    if (!mapAttribute.zoom || !mapRef.current) return

    const map = mapRef.current.getMap()
    const bounds = map.getBounds()
    const {_ne, _sw} = bounds
    const visibleBounds = [
      [_sw.lng, _sw.lat],
      [_ne.lng, _ne.lat],
    ]
    const currZoom = Math.ceil(mapAttribute.zoom)

    if (!bbox) {
      handleBoundsChange(currZoom, visibleBounds)
      return
    }

    const isInside = isInsideBounds(bbox.bounds, visibleBounds)
    const isZoomInRange = mapAttribute.zoom <= TREE_MAP_MAX_CLUSTER_ZOOM + 1
    if (isZoomInRange && (bbox.zoom !== currZoom || !isInside)) {
      handleBoundsChange(currZoom, visibleBounds)
    }
  }, [bbox, handleBoundsChange, mapAttribute])

  const handleDragEnd = useCallback(() => {
    if (mapAttribute.latitude && mapAttribute.longitude) {
      update('lastUploadStatePhoto', {
        latitude: mapAttribute.latitude.toString(),
        longitude: mapAttribute.longitude.toString(),
        name: `lat: ${mapAttribute.latitude} lng: ${mapAttribute.longitude}`,
      })
      update('lastUploadStateVideo', {
        latitude: mapAttribute.latitude.toString(),
        longitude: mapAttribute.longitude.toString(),
        name: `lat: ${mapAttribute.latitude} lng: ${mapAttribute.longitude}`,
      })
    }
  }, [mapAttribute.latitude, mapAttribute.longitude, update])

  const handleUpdateReducer = useCallback(
    (_, long?: number, lat?: number) => {
      update('lastTreeState', {
        longitude: long || mapAttribute.longitude,
        latitude: lat || mapAttribute.latitude,
      })
    },
    [mapAttribute.latitude, mapAttribute.longitude, update],
  )

  const handleNavigateFotoTreeDetail = useCallback(
    (properties: TreeFotoTreeMapPointData) => {
      update('lastTreeState', {videoIntro: true})
      history.push(
        'tree_fototree_detail',
        {
          treeId: properties.id,
          treeAlias: properties.alias,
          memberId: overlayFootprint !== 'hidden' ? id : undefined,
        },
        properties.alias,
      )
    },
    [history, id, overlayFootprint, update],
  )

  const handlePressMarker = useCallback(
    (e: MapEvent | undefined) => {
      const coords = (e && e.lngLat) || [0, 0]
      const feature = e && e.features && e.features[0]
      const featureProp = feature?.properties
      const isTreeFeatureData = featureProp?.alias
      const isCreatorFeatureData = featureProp?.member_id

      if (isTreeFeatureData) {
        handleUpdateReducer(e, coords[0], coords[1])
        handleNavigateFotoTreeDetail(feature.properties)
        return
      }
      if (isCreatorFeatureData) {
        popupInfo.current = {coords, data: feature.properties}
        setShowPopup(true)
      }
    },
    [handleNavigateFotoTreeDetail, handleUpdateReducer],
  )

  const handleHoverMarker = useCallback((e: MapEvent | undefined) => {
    const coords = (e && e.lngLat) || [0, 0]
    const feature = e && e.features && e.features[0]
    const featureProp = feature?.properties
    const isTreeFeatureData = featureProp?.alias

    if (isTreeFeatureData) {
      popupInfo.current = {coords, data: feature.properties}
      setShowPopup(true)
    }
  }, [])

  const handleLeaveMarker = useCallback(() => {
    const isTreeFeatureDate = popupInfo.current.data?.alias
    if (isTreeFeatureDate) {
      setShowPopup(false)
    }
  }, [])

  const handleRestoreMapAttribute = useCallback(() => {
    if (viewMode === 'creator' && lastMapCoordinate) {
      setMapAttribute((prev) => ({
        ...prev,
        longitude: lastMapCoordinate.longitude,
        latitude: lastMapCoordinate.latitude,
        zoom: Math.min(lastMapCoordinate.zoom || 0, TREE_MAP_DEFAULT_ZOOM),
      }))
    }
  }, [lastMapCoordinate, setMapAttribute, viewMode])

  const handleClickEventSchedule = useCallback(() => {
    if (!gpsEnabled) {
      setPermissionModal(true)
      return
    }
    setModal('schedule')
  }, [gpsEnabled, setPermissionModal])

  const handleClickFootprint = useCallback(() => {
    if (!access_token) {
      setLoginModal(true)
      return
    }
    setOverlayFootprint('header')
    handleLoadMapFootprintData()
  }, [access_token, handleLoadMapFootprintData, setOverlayFootprint])

  const layerFototreeVisibility = useMemo<Visibility>(
    () =>
      viewMode !== 'creator' ||
      (viewMode === 'creator' && overlayFootprint !== 'hidden')
        ? 'visible'
        : 'none',
    [viewMode, overlayFootprint],
  )

  const layerCreatorVisibility = useMemo<Visibility>(
    () =>
      viewMode !== 'fototree' && overlayFootprint === 'hidden'
        ? 'visible'
        : 'none',
    [viewMode, overlayFootprint],
  )

  const handleRenderSelfMarker = useMemo(
    () =>
      latitude &&
      longitude &&
      gpsEnabled && (
        <Marker longitude={longitude} latitude={latitude}>
          <TreeSelfMarker />
        </Marker>
      ),
    [gpsEnabled, latitude, longitude],
  )

  const handleRenderActiveMarker = useMemo(
    () =>
      (creatorActiveFeatureData || activeFeatureData) && (
        <TreeActiveMarker
          featureData={activeFeatureData}
          creatorFeatureData={creatorActiveFeatureData}
          layerCreatorVisibility={layerCreatorVisibility}
        />
      ),
    [activeFeatureData, creatorActiveFeatureData, layerCreatorVisibility],
  )

  const interactiveLayerIds = useMemo(() => {
    const layerIds: string[] = []
    if (viewMode !== 'creator' && pinPointData.length) {
      layerIds.push(TREE_MAP_LAYER_ID_FOTOTREE)
      if (activeFeatureData) {
        layerIds.push(TREE_MAP_LAYER_ID_ACTIVE)
      }
    }
    if (viewMode !== 'fototree') {
      if (creatorLiveData.length) {
        layerIds.push(TREE_MAP_LAYER_ID_CREATOR_LIVE)
      }
      if (creatorActiveFeatureData) {
        layerIds.push(TREE_MAP_LAYER_ID_CREATOR_LIVE_ACTIVE)
      }
    }
    return layerIds
  }, [
    activeFeatureData,
    creatorActiveFeatureData,
    creatorLiveData.length,
    pinPointData.length,
    viewMode,
  ])

  const handleRenderPinpoint = useMemo(
    () => (
      <FototreeMapPinpoint
        activeFeatureData={activeFeatureData}
        creatorActiveFeatureData={creatorActiveFeatureData}
        cluster={cluster}
        creatorCluster={creatorCluster}
        layerCreatorVisibility={layerCreatorVisibility}
        layerFototreeVisibility={layerFototreeVisibility}
      />
    ),
    [
      activeFeatureData,
      cluster,
      creatorActiveFeatureData,
      creatorCluster,
      layerCreatorVisibility,
      layerFototreeVisibility,
    ],
  )

  const handleRenderCreatorCatwalk = useMemo(
    () => <FototreeMapCreatorCatwalk data={creatorLiveData} />,
    [creatorLiveData],
  )

  const handleRenderPopup = useMemo(() => {
    if (!showPopup) return null

    const {data, coords} = popupInfo.current
    const shareCreatorId =
      creatorActiveFeatureData?.properties.member_id ===
      (member_id || data?.member_id)
    const isDataSharable = shareCreatorId && !data?.id

    const coordinates =
      isDataSharable && isCreatorLive
        ? [longitude || 0, latitude || 0]
        : isDataSharable
        ? creatorActiveFeatureData?.geometry?.coordinates || [0, 0]
        : coords

    const creatorData = isDataSharable
      ? creatorActiveFeatureData?.properties
      : data

    return (
      <Popup
        longitude={coordinates[0]}
        latitude={coordinates[1]}
        anchor="bottom"
        offsetTop={-20}
        closeButton={false}
        onClose={() => {
          setShowPopup(false)
          if (coord) history.replace('tree_fototree', {})
        }}>
        {data?.alias ? (
          <TreePopupContent id={data?.id} />
        ) : (
          <FototreeMapCreatorPopupContent data={creatorData} />
        )}
      </Popup>
    )
  }, [
    coord,
    creatorActiveFeatureData?.geometry?.coordinates,
    creatorActiveFeatureData?.properties,
    history,
    isCreatorLive,
    latitude,
    longitude,
    member_id,
    showPopup,
  ])

  const handleRenderMap = useMemo(
    () =>
      mapAttribute.latitude && mapAttribute.longitude ? (
        <Map
          mapRef={mapRef}
          reuseMaps
          viewportState={mapAttributeState}
          interactiveLayerIds={interactiveLayerIds}
          dragPan={isMapInteractive}
          dragRotate={isMapInteractive}
          touchZoom={isMapInteractive}
          onClick={handlePressMarker}
          onMouseEnter={handleHoverMarker}
          onMouseLeave={handleLeaveMarker}
          onMouseUp={handleDragEnd}>
          {handleRenderActiveMarker}
          {handleRenderCreatorCatwalk}
          {handleRenderPinpoint}
          {handleRenderPopup}
          {handleRenderSelfMarker}
        </Map>
      ) : null,
    [
      handleDragEnd,
      handleHoverMarker,
      handleLeaveMarker,
      handlePressMarker,
      handleRenderActiveMarker,
      handleRenderCreatorCatwalk,
      handleRenderPinpoint,
      handleRenderPopup,
      handleRenderSelfMarker,
      interactiveLayerIds,
      isMapInteractive,
      mapAttribute.latitude,
      mapAttribute.longitude,
      mapAttributeState,
    ],
  )

  const handleRenderOverlayTop = useMemo(
    () => (
      <StyledButtonTopContainer mode={mode}>
        <StyledIcon
          iconType="back"
          iconColor="black"
          onClick={() => {
            const canGoBack = isCanGoBack()
            if (canGoBack) {
              history.goBack()
            } else {
              history.push('giftshop_feed', {})
            }
          }}
        />
        <TreeSearchInput />
      </StyledButtonTopContainer>
    ),
    [history, mode],
  )

  const handleClickPlantTree = useCallback(() => {
    if (!access_token) {
      setLoginModal(true)
      return
    }
    if (childStatus === 'UNDERAGE') {
      showModalLimitedAccess()
      return
    }
    if (username?.toLowerCase() !== 'businessowner') {
      if (creatorStatus === 'verified') {
        history.push('tree_plant_fototree', {})
      } else {
        showSnackbar(
          translate('tree:plantFototreeInvalid', {context: 'verified'}),
        )
      }
    } else history.push('tree_plant_fototree', {})
  }, [access_token, childStatus, creatorStatus, history, translate, username])

  const handleClickCamereLive = useCallback(() => {
    if (!access_token) {
      setLoginModal(true)
      return
    }
    if (creatorStatus !== 'verified') {
      showSnackbar(translate('tree:creatorViewCameraLiveSnackbarMessage'))
      return
    }
    if (gpsEnabled) {
      setModal('creator-live')
    } else {
      setPermissionModal(true)
    }
  }, [access_token, creatorStatus, gpsEnabled, setPermissionModal, translate])

  const handleRenderLoginModal = useMemo(
    () => (
      <TemplateAuthAccessModal
        toggleModal={() => {
          setLoginModal((prev) => !prev)
          if (coord) history.replace('tree_fototree', {})
        }}
        visible={loginModal}
      />
    ),
    [coord, history, loginModal],
  )

  const handleRenderTallestTreeModal = useMemo(
    () => <FototreeTallestTree stateVisible={stateTallestTreeModal} />,
    [stateTallestTreeModal],
  )

  const handleRenderCreatorLiveModal = useMemo(
    () => (
      <FototreeMapCreatorCameraLiveModal
        location={{
          longitude: longitude || 0,
          latitude: latitude || 0,
        }}
        isCreatorLive={isCreatorLive}
        creatorLiveCountdown={countdown}
        stateCreatorLiveExpired={stateCreatorLiveExpired}
        toggleModal={(item) => setModal(item || 'hidden')}
      />
    ),
    [countdown, isCreatorLive, latitude, longitude, stateCreatorLiveExpired],
  )

  const handleRenderEventScheduleModal = useMemo(
    () =>
      longitude &&
      latitude && (
        <FototreeMapEventSchedule
          location={{longitude, latitude}}
          toggleModal={() => setModal('hidden')}
        />
      ),
    [latitude, longitude],
  )

  const handleRenderShareModal = useMemo(
    () => (
      <GiftShopTemplateShareModal
        type="creator_live"
        targetId=""
        toggleModal={() => setModal('hidden')}
      />
    ),
    [],
  )

  const handleRenderModal = useMemo(() => {
    switch (modal) {
      case 'share':
        return handleRenderShareModal
      case 'creator-live':
        return handleRenderCreatorLiveModal
      case 'schedule':
        return handleRenderEventScheduleModal
      case 'hidden':
        return null
    }
  }, [
    handleRenderCreatorLiveModal,
    handleRenderEventScheduleModal,
    handleRenderShareModal,
    modal,
  ])

  const handleRenderButtonBottomLeft = useMemo(
    () => (
      <StyledButtonBottomLeftContainer>
        <StyledButtonBottomLeftVert>
          <StyledIcon
            iconType="calendar-event"
            iconColor="primary_5"
            onClick={handleClickEventSchedule}
          />
          <StyledIcon
            iconType="footprints"
            iconColor="primary_5"
            onClick={handleClickFootprint}
          />
          <StyledIcon
            iconType="tallest-tree"
            iconColor="primary_5"
            onClick={() => setTallestTreeModal(true)}
          />
        </StyledButtonBottomLeftVert>
        <StyledView>
          <StyledButtonViewLabel
            label={translate('global:all')}
            backgroundColor={viewMode === 'all' ? 'primary_5' : 'white_1'}
            backgroundHoverColor={viewMode === 'all' ? 'primary_4' : 'white_1'}
            color={viewMode === 'all' ? 'white_1' : 'gray_5'}
            onClick={() => {
              if (viewMode !== 'all') {
                if (!access_token) {
                  setLoginModal(true)
                  return
                }
                handleRestoreMapAttribute()
                setShowPopup(false)
                setViewMode('all')
              }
            }}
          />
          <StyledButtonViewIcon
            iconType="tree-feature"
            iconColor={viewMode === 'fototree' ? 'white_1' : 'gray_5'}
            backgroundColor={viewMode === 'fototree' ? 'primary_5' : 'white_1'}
            backgroundHoverColor={
              viewMode === 'fototree' ? 'primary_4' : 'white_1'
            }
            hasShadow={false}
            onClick={() => {
              if (viewMode !== 'fototree') {
                handleRestoreMapAttribute()
                setShowPopup(false)
                setViewMode('fototree')
              }
            }}
          />
          <StyledButtonViewIcon
            iconType="fg"
            iconColor={viewMode === 'creator' ? 'white_1' : 'gray_5'}
            backgroundColor={viewMode === 'creator' ? 'primary_5' : 'white_1'}
            backgroundHoverColor={
              viewMode === 'creator' ? 'primary_4' : 'white_1'
            }
            hasShadow={false}
            onClick={() => {
              if (viewMode !== 'creator') {
                if (!access_token) {
                  setLoginModal(true)
                  return
                }
                if (!gpsEnabled) {
                  setPermissionModal(true)
                  return
                }
                if (isCreatorLive) {
                  setLastMapCoordinate({
                    longitude: mapAttribute.longitude,
                    latitude: mapAttribute.latitude,
                    zoom: mapAttribute.zoom,
                  })
                }
                setViewMode('creator')
              }
            }}
          />
        </StyledView>
        <Button
          hasShadow
          leftIcon={
            <Icon type={isCreatorLive ? 'pause' : 'fg'} color="white_1" />
          }
          label={
            isCreatorLive
              ? formatHours(
                  countdown.hours,
                  countdown.minutes,
                  countdown.seconds,
                  'HHhMMm',
                )
              : translate('tree:creatorViewCameraLive')
          }
          onClick={handleClickCamereLive}
        />
      </StyledButtonBottomLeftContainer>
    ),
    [
      access_token,
      countdown,
      gpsEnabled,
      handleClickCamereLive,
      handleClickEventSchedule,
      handleClickFootprint,
      handleRestoreMapAttribute,
      isCreatorLive,
      mapAttribute.latitude,
      mapAttribute.longitude,
      mapAttribute.zoom,
      setPermissionModal,
      setTallestTreeModal,
      translate,
      viewMode,
    ],
  )

  const handleRenderButtonBottomRight = useMemo(
    () => (
      <StyledButtonBottomRightContainer>
        <StyledIcon
          iconType="gps"
          iconColor={gpsEnabled ? 'black' : 'danger_5'}
          onClick={handleGetCurrentLocation}
        />
        <Button
          hasShadow
          disableBackgroundColor="primary_5"
          disabledFontColor="white_1"
          leftIcon={<Icon type="plus" color="white_1" />}
          label={translate('tree:plantFototree')}
          onClick={handleClickPlantTree}
        />
      </StyledButtonBottomRightContainer>
    ),
    [gpsEnabled, handleClickPlantTree, handleGetCurrentLocation, translate],
  )

  const handleRenderOverlay = useMemo(
    () =>
      overlayFootprint !== 'hidden' ? (
        <FototreeFootprint
          stateOverlay={stateOverlayFootprint}
          stateLoading={stateLoading}
          data={pinPointFootprintData}
          onLoadData={() => bbox && handleLoadData(bbox.zoom, bbox.bounds)}
        />
      ) : (
        <>
          {handleRenderOverlayTop}
          {handleRenderButtonBottomLeft}
          {handleRenderButtonBottomRight}
        </>
      ),
    [
      bbox,
      handleLoadData,
      handleRenderButtonBottomLeft,
      handleRenderButtonBottomRight,
      handleRenderOverlayTop,
      overlayFootprint,
      pinPointFootprintData,
      stateLoading,
      stateOverlayFootprint,
    ],
  )

  useDidMount(() => {
    handleGetCreatorLiveStatus()
    if (longitude && latitude) {
      handleLoadCreatorLiveData({latitude, longitude})
    }
  })

  useDidMount(() => {
    if (member_id && !access_token) {
      setLoginModal(true)
      return
    }
    if (member_id && member_id !== id) {
      setShowPopup(true)
    }
  })

  useDidMount(handleActiveFeatureData)

  useDidUpdate(() => {
    if (viewMode === 'creator') {
      if (overlayFootprint !== 'hidden' || !isCreatorLive) {
        setMapAttribute((prev) => ({
          ...prev,
          minZoom: TREE_MAP_MIN_ZOOM,
        }))
      } else if (isCreatorLive) {
        handleGetCurrentLocation()
      }
    } else {
      setMapAttribute((prev) => ({
        ...prev,
        minZoom: TREE_MAP_MIN_ZOOM,
      }))
    }
  }, [isCreatorLive, overlayFootprint, viewMode])

  useDidUpdate(() => {
    if (viewMode !== 'fototree' && latitude && longitude) {
      handleLoadCreatorLiveData({latitude, longitude})
    }
  }, [longitude, viewMode])

  useEffect(() => {
    document.addEventListener('mouseup', handleUpdateReducer)
    return () => {
      document.removeEventListener('mouseup', handleUpdateReducer)
    }
  }, [handleUpdateReducer])

  useEffect(() => {
    handleGetCreatorStatus()
  }, [handleGetCreatorStatus])

  useEffect(() => {
    handleUpdateClusterData()
  }, [handleUpdateClusterData])

  return (
    <StyledModalFullscreen visible>
      <StyledMapContainer>
        {handleRenderMap}
        {handleRenderOverlay}
      </StyledMapContainer>
      {handleRenderLoginModal}
      {handleRenderTallestTreeModal}
      {handleRenderModal}
    </StyledModalFullscreen>
  )
}
