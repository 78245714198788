import React, {useMemo} from 'react'
import styled from 'styled-components'
import {getFontFamily, getFontSize} from 'utils'
import {Paragraph, ParsedText} from 'common/components'
import convertUnit from 'lib/unit'
import {useTranslation} from 'i18n'
import {parseShape} from 'types'
import {COMPANY_NAME} from 'consts'

const StyledListUl = styled.ul`
  padding-left: ${convertUnit(25)};
`
const StyledListItem = styled.li`
  font-family: ${getFontFamily('regular')};
  font-size: ${getFontSize('l')};
  line-height: ${convertUnit(31)};
  padding-left: ${convertUnit(25)};
`
const StyledTextContainer = styled.div`
  text-align: justify;
`
const StyledTitle = styled(Paragraph)`
  ${({theme}) => ({
    color: theme.primary_5,
  })}
  text-align: justify;
`
const StyledSubTitle = styled(Paragraph)`
  ${({fontSize = 'xl', fontWeight = 'bold'}) => ({
    fontSize: typeof fontSize === 'number' ? fontSize : getFontSize(fontSize),
    fontFamily: getFontFamily(fontWeight),
  })}
  margin-top: ${convertUnit(20)};
  text-align: justify;
`
const StyledDescription = styled(Paragraph)`
  ${({fontSize = 'l'}) => ({
    fontSize: typeof fontSize === 'number' ? fontSize : getFontSize(fontSize),
  })}
  margin-top: ${convertUnit(10)};
  line-height: ${convertUnit(31)};
  white-space: pre-wrap;
  text-align: justify;
`

export default function LegalReasonsOfUseContent() {
  const {translate} = useTranslation()

  const patterns: parseShape[] = useMemo(() => {
    const regex = /\*\*(.+?)\*\*/
    return [
      {
        pattern: regex,
        style: {fontFamily: getFontFamily('bold')},
        renderText: (text) => text.replace(regex, `$1`),
      },
    ]
  }, [])

  const handleUsageReason = useMemo(
    () => (
      <StyledTextContainer>
        <StyledTitle fontWeight="bold" fontSize="xxl">
          {translate('policy:reasonOfUse')}
        </StyledTitle>
        <StyledSubTitle>
          {translate('policy:reasonOfUseYourChoice')}
        </StyledSubTitle>
        <StyledDescription>
          <ParsedText parse={patterns}>
            {translate('policy:reasonOfUseYourChoiceDesc', {
              name: COMPANY_NAME,
            })}
          </ParsedText>
        </StyledDescription>
        <StyledListUl>
          <StyledListItem>
            <StyledSubTitle fontSize="l">
              {translate('policy:reasonOfUseFaceRecognition')}
            </StyledSubTitle>
            <StyledDescription>
              {translate('policy:reasonOfUseFaceRecognitionDesc')}
            </StyledDescription>
          </StyledListItem>
          <StyledListItem>
            <StyledSubTitle fontSize="l">
              {translate('policy:reasonOfUseLocationData')}
            </StyledSubTitle>
            <StyledDescription>
              {translate('policy:reasonOfUseLocationDataDesc')}
            </StyledDescription>
          </StyledListItem>
        </StyledListUl>
        <StyledDescription>
          {translate('policy:reasonOfUseDescFooter')}
        </StyledDescription>
      </StyledTextContainer>
    ),
    [patterns, translate],
  )

  return handleUsageReason
}
