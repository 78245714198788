import React from 'react'
import styled from 'styled-components'
import {Image, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {getBorder, formatDateRange} from 'utils'
import {FototreeMapEventScheduleItemProps} from './FototreeMapEventScheduleProps'

const StyledListItemContainer = styled.div`
  background-color: ${({theme}) => theme.white_1};
  border: ${({theme}) => getBorder(2, 'solid', theme.white_4)};
  border-radius: ${convertUnit(8)};
  padding: ${convertUnit(8)} ${convertUnit(12)} ${convertUnit(4)};
`

const StyledEventContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: ${convertUnit(8)};
  padding: ${convertUnit(4)} 0;
`

const StyledImageContainer = styled.div`
  position: relative;
`

const StyledImage = styled(Image)`
  aspect-ratio: 0.75;
  object-fit: contain;
  width: ${convertUnit(21)};
  border-radius: ${convertUnit(2)};
  border: ${({theme}) => getBorder(0.5, 'solid', theme.gray_4)};
`

const StyledTextRegency = styled(Paragraph)`
  text-transform: capitalize;
`

export default function FototreeMapEventScheduleItem({
  data: {regency, datas},
  onClick,
}: FototreeMapEventScheduleItemProps) {
  return (
    <StyledListItemContainer>
      <StyledTextRegency fontSize="m" fontWeight="bold">
        {regency.toLowerCase()}
      </StyledTextRegency>
      {datas.map((event) => (
        <StyledEventContainer key={event.id} onClick={() => onClick(event)}>
          <StyledImageContainer>
            <StyledImage src={event.url} alt={event.name} />
          </StyledImageContainer>
          <div>
            <Paragraph fontWeight="medium">{event.name}</Paragraph>
            <Paragraph fontSize="xs" fontWeight="medium" color="primary_5">
              {formatDateRange(event.event_start_date, event.event_end_date)}
            </Paragraph>
          </div>
        </StyledEventContainer>
      ))}
    </StyledListItemContainer>
  )
}
