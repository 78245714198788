/** @todo: separate this file into multiple files */

import {REGEX_ENCODED_URI, REGEX_NUMBER} from 'consts'
import {GiftShopCreationType, GiftShopCreationViewType} from 'types'
import {RouteParam} from './RouteParam'

type KeyConstraint<
  K,
  T extends keyof T extends K ? (K extends keyof T ? unknown : never) : never
> = T

export type QueryType = KeyConstraint<
  keyof RouteParam,
  {
    giftshop_profile_creation_group: {
      date: string
      type: GiftShopCreationType
      mode?: GiftShopCreationViewType
      page: number
    }
    giftshop_profile_creation_detail: {
      date: string
      type: GiftShopCreationType
      page?: number
    }
    tree_events_bib_masonry: {
      tree_id: string
      tag_id: string
      search: string
    }
    auth_login: {
      next: string
    }
    giftshop_search_by_creator_creation_content: {
      creator_id: string
      username: string
    }
    giftshop_profile: {
      type?: GiftShopCreationType | 'post' | ''
    }
    tree_host_profile: {
      type?: GiftShopCreationType
    }
    tree_host_creation_group: {
      type: GiftShopCreationType
      date: string
    }
    tree_host_content_detail: {
      date: string
      type?: GiftShopCreationType
    }
  }
>

export type QueryKey = keyof QueryType

type ParamType = 'string' | 'number' | 'boolean' | 'numberOptional'
type QueryParamTypeParser<T> = (value: string) => T

interface QueryParamTypeParserMap {
  string: QueryParamTypeParser<string>
  boolean: QueryParamTypeParser<boolean>
  number: QueryParamTypeParser<number>
  numberOptional: QueryParamTypeParser<number | undefined>
}

interface QueryProperty<R> {
  paramParser: ParamType
  default: R
  validator: (input: R) => boolean
}

type QueryRulesType = {
  date: QueryProperty<string>
  creationType: QueryProperty<GiftShopCreationType>
  viewType: QueryProperty<GiftShopCreationViewType | undefined>
  count: QueryProperty<number>
  page: QueryProperty<number | undefined>
  tree_id: QueryProperty<string>
  tag_id: QueryProperty<string>
  search: QueryProperty<string>
  encoded_uri: QueryProperty<string>
  creator_id: QueryProperty<string>
  username: QueryProperty<string>
  profileCreationType: QueryProperty<GiftShopCreationType | 'post' | ''>
}

type QueryParam = {
  [K in QueryKey]: {
    [QK in keyof QueryType[K]]: keyof QueryRulesType
  }
}

const queryParamParser: QueryParamTypeParserMap = {
  string: (input) => input,
  number: (input) => parseFloat(input),
  boolean: (input) => input.toUpperCase() === 'TRUE',
  numberOptional: (input) => {
    const numeric = parseFloat(input)
    return isNaN(numeric) ? undefined : numeric
  },
}

const QUERY_RULES: QueryRulesType = {
  date: {
    paramParser: 'string',
    default: '0000-00-00',
    validator: (input) =>
      /^\d{4}-(0?[1-9]|1[012])-(0?[1-9]|[12][0-9]|3[01])$/.test(input),
  },
  creationType: {
    paramParser: 'string',
    default: 'all',
    validator: (input) =>
      [
        'all',
        'available',
        'sold',
        'resend',
        'resendpology',
        'unwishlist',
      ].includes(input),
  },
  viewType: {
    paramParser: 'string',
    default: 'masonry',
    validator: (input) =>
      typeof input === 'undefined' || ['masonry', 'list'].includes(input),
  },
  count: {
    paramParser: 'number',
    default: 1,
    validator: (input) => input > 0,
  },
  page: {
    paramParser: 'numberOptional',
    default: undefined,
    validator: (input) =>
      typeof input === 'number' || typeof input === 'undefined',
  },
  tree_id: {
    paramParser: 'string',
    default: '',
    validator: (input) => input.length > 0,
  },
  tag_id: {
    paramParser: 'string',
    default: '',
    validator: (input) => input.length > 0,
  },
  search: {
    paramParser: 'string',
    default: '',
    validator: (input) => REGEX_NUMBER.test(input),
  },
  encoded_uri: {
    paramParser: 'string',
    default: '',
    validator: (input) => REGEX_ENCODED_URI.test(input),
  },
  creator_id: {
    paramParser: 'string',
    default: '',
    validator: (input) => input.length > 0,
  },
  username: {
    paramParser: 'string',
    default: '',
    validator: (input) => input.length > 0,
  },
  profileCreationType: {
    paramParser: 'string',
    default: '',
    validator: (input) =>
      ['all', 'available', 'sold', 'resend', 'post', ''].includes(input),
  },
}

export const QUERY_CONSTRAINT: QueryParam = {
  giftshop_profile_creation_group: {
    date: 'date',
    type: 'creationType',
    mode: 'viewType',
    page: 'page',
  },
  giftshop_profile_creation_detail: {
    date: 'date',
    type: 'creationType',
    page: 'page',
  },
  tree_events_bib_masonry: {
    tree_id: 'tree_id',
    tag_id: 'tag_id',
    search: 'search',
  },
  auth_login: {
    next: 'encoded_uri',
  },
  giftshop_search_by_creator_creation_content: {
    creator_id: 'creator_id',
    username: 'username',
  },
  giftshop_profile: {
    type: 'profileCreationType',
  },
  tree_host_profile: {
    type: 'creationType',
  },
  tree_host_creation_group: {
    date: 'date',
    type: 'creationType',
  },
  tree_host_content_detail: {
    date: 'date',
    type: 'creationType',
  },
}

function validateQuery<K extends QueryKey>(
  route: K,
  rawQuery: object,
): QueryType[K] {
  const validated_query = {}
  const queryParameters = QUERY_CONSTRAINT[route]

  for (const [paramName] of Object.entries(queryParameters)) {
    const paramRule = queryParameters[paramName] as keyof QueryRulesType
    const parser = queryParamParser[QUERY_RULES[paramRule].paramParser]
    const {validator} = QUERY_RULES[paramRule]

    validated_query[paramName] = QUERY_RULES[paramRule].default

    if (rawQuery[paramName]) {
      const parsedQuery = parser(rawQuery[paramName])
      // @ts-ignore : TS2345
      if (validator(parsedQuery)) {
        validated_query[paramName] = parsedQuery
      }
    }
  }

  return validated_query as QueryType[K]
}

export function getQueryObject<K extends keyof RouteParam>(
  route: K,
): K extends QueryKey ? QueryType[K] : {} {
  if (!QUERY_CONSTRAINT[route as QueryKey]) {
    return {} as any
  }

  const query = new URLSearchParams(location.search)
  const queryObject = {}

  query.forEach((queryParamValue, queryParamKey) => {
    queryObject[queryParamKey] = queryParamValue
  })

  const validated_query = validateQuery(route as QueryKey, queryObject)

  return validated_query as any
}

export function parseObjectToQuery(state): string {
  let query = '?'
  query += Object.entries(state)
    .map(([k, v]) => `${k}=${encodeURIComponent(v as string)}`)
    .join('&')
  return query
}
