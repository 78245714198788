import React, {CSSProperties, useMemo, useState} from 'react'
import styled from 'styled-components'
import {POLICY_ROOT_MENU_WIDTH} from 'consts'
import {useTranslation} from 'i18n'
import {LegalNavBarOption, WindowModeType} from 'types'
import {useWindowMode} from 'windows'
import convertUnit from 'lib/unit'
import {PolicyRootScreenProps} from './PolicyRootScreenProps'
import {PolicyRootNavBar, PolicyTopNavBar} from '../containers'

interface StyledSectionChildrenProps {
  mode: WindowModeType
  header?: boolean
  hideMarginTop?: boolean
}

const StyledSectionContent = styled.div`
  overflow-x: hidden;
  display: flex;
  position: relative;
  flex: 1;
  flex-direction: column;
`

const StyledSectionChildren = styled.div<StyledSectionChildrenProps>`
  ${({mode, header}) => ({
    paddingLeft:
      mode !== 'website' || !header
        ? undefined
        : convertUnit(POLICY_ROOT_MENU_WIDTH),
  })}
  margin-top: 0;
  display: flex;
  flex: 1;
`

const StyledChildrenBaseContainer = styled.div`
  flex: 1;
  position: relative;
`

const StyledChildrenContainer = styled.div<CSSProperties>`
  background-color: ${({theme, backgroundColor}) =>
    backgroundColor ? theme[backgroundColor] : theme.white_1};
  display: flex;
  position: absolute;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;
  align-items: center;
  justify-content: center;
`

const StyledChildrenContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  z-index: 1;
`

export default function PolicyRootScreen({
  children,
  childrenContainerStyle,
  hideBottomNavbar = false,
  clickState,
  onClick,
  showHeader,
  hideMarginTop = false,
}: PolicyRootScreenProps) {
  const {translate} = useTranslation()
  const mode = useWindowMode()
  const [expand, setExpand] = useState(false)
  const currentState = clickState[0]
  const navbarOptions = useMemo<LegalNavBarOption[]>(
    () => [
      {
        key: 'how-it-works',
        label: translate('global:howItWorks'),
        onClick: () => {
          onClick('how-it-works')
          setExpand((prev) => !prev)
        },
      },
      {
        key: 'yuser',
        label: 'Yuser',
        onClick: () => {
          onClick('yuser')
          setExpand((prev) => !prev)
        },
      },
      {
        key: 'creator',
        label: translate('giftShop:creator'),
        onClick: () => {
          onClick('creator')
          setExpand((prev) => !prev)
        },
      },
      {
        key: 'host',
        label: translate('giftShop:host'),
        onClick: () => {
          onClick('host')
          setExpand((prev) => !prev)
        },
      },
      {
        key: 'privacy',
        label: translate('policy:privacyPolicy'),
        onClick: () => {
          onClick('privacy')
          setExpand((prev) => !prev)
        },
      },
      {
        key: 'terms-of-use',
        label: translate('policy:termsOfUse'),
        onClick: () => {
          onClick('terms-of-use')
          setExpand((prev) => !prev)
        },
      },
      {
        key: 'community-guidelines',
        label: translate('policy:communityGuideline'),
        onClick: () => {
          onClick('community-guidelines')
          setExpand((prev) => !prev)
        },
      },
      {
        key: 'biometric-face-recognition',
        label: translate('policy:biometricPolicy'),
        onClick: () => {
          onClick('biometric-face-recognition')
          setExpand((prev) => !prev)
        },
      },
      {
        key: 'copyright',
        label: translate('policy:copyright'),
        onClick: () => {
          onClick('copyright')
          setExpand((prev) => !prev)
        },
      },
      {
        key: 'faq',
        label: translate('global:faq'),
        onClick: () => {
          onClick('faq')
          setExpand((prev) => !prev)
        },
      },
      {
        key: 'help-center',
        label: translate('policy:helpCenter'),
        onClick: () => {
          onClick('help-center')
          setExpand((prev) => !prev)
        },
      },
      {
        key: 'refund',
        label: translate('policy:refundPolicy'),
        onClick: () => {
          onClick('refund')
          setExpand((prev) => !prev)
        },
      },
      {
        key: 'cookie',
        label: translate('policy:cookie'),
        onClick: () => {
          onClick('cookie')
          setExpand((prev) => !prev)
        },
      },
      {
        key: 'underage-user-policy',
        label: translate('policy:privacyPolicyChild'),
        onClick: () => {
          onClick('underage-user-policy')
          setExpand((prev) => !prev)
        },
      },
      {
        key: 'supergiga',
        label: translate('policy:supergiga'),
        onClick: () => {
          onClick('supergiga')
          setExpand((prev) => !prev)
        },
      },
    ],
    [onClick, translate],
  )
  const handleRenderSideNavBar = useMemo(
    () =>
      mode === 'website' && (
        <>
          <PolicyRootNavBar select={currentState} data={navbarOptions} />
        </>
      ),
    [mode, currentState, navbarOptions],
  )
  const handleRenderTopNavBar = useMemo(
    () =>
      mode !== 'website' &&
      !hideBottomNavbar && (
        <PolicyTopNavBar
          select={currentState}
          data={navbarOptions}
          expand={expand}
          onExpand={() => setExpand((previous) => !previous)}
        />
      ),
    [currentState, expand, hideBottomNavbar, mode, navbarOptions],
  )
  return (
    <>
      <StyledSectionContent>
        {showHeader === true ? handleRenderTopNavBar : null}
        {showHeader === true ? handleRenderSideNavBar : null}
        <StyledSectionChildren
          header={showHeader}
          mode={mode}
          hideMarginTop={hideMarginTop}
          onClick={() => setExpand(false)}>
          <StyledChildrenBaseContainer>
            <StyledChildrenContainer style={childrenContainerStyle}>
              <StyledChildrenContentContainer>
                {children}
              </StyledChildrenContentContainer>
            </StyledChildrenContainer>
          </StyledChildrenBaseContainer>
        </StyledSectionChildren>
      </StyledSectionContent>
    </>
  )
}
