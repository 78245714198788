import React, {useMemo} from 'react'
import styled from 'styled-components'
import {DOMAIN_NAME} from 'consts'
import {useTranslation} from 'i18n'
import {getFontFamily, getFontSize} from 'utils'
import {Paragraph, ParsedText} from 'common/components'
import convertUnit from 'lib/unit'
import {parseShape} from 'types'

const StyledListUl = styled.ul`
  padding-left: ${convertUnit(25)};
`
const StyledListItem = styled.li`
  font-family: ${getFontFamily('regular')};
  font-size: ${getFontSize('l')};
  line-height: ${convertUnit(31)};
  padding-left: ${convertUnit(25)};
`
const StyledTextContainer = styled.div`
  text-align: justify;
`
const StyledTitle = styled(Paragraph)`
  ${({theme}) => ({
    color: theme.primary_5,
  })}
  text-align: justify;
`
const StyledSubTitle = styled(Paragraph)`
  ${({fontSize = 'xl', fontWeight = 'bold'}) => ({
    fontSize: typeof fontSize === 'number' ? fontSize : getFontSize(fontSize),
    fontFamily: getFontFamily(fontWeight),
  })}
  margin-top: ${convertUnit(20)};
  text-align: justify;
`
const StyledDescription = styled(Paragraph)`
  ${({fontSize = 'l'}) => ({
    fontSize: typeof fontSize === 'number' ? fontSize : getFontSize(fontSize),
  })}
  margin-top: ${convertUnit(10)};
  line-height: ${convertUnit(31)};
  white-space: pre-wrap;
  text-align: justify;
`
const StyledLink = styled.a`
  color: ${({theme}) => theme.primary_5};
  text-decoration: underline;
  cursor: pointer;
`

export default function LegalCommunityGuidline() {
  const {translate} = useTranslation()

  const patterns = useMemo((): parseShape[] => {
    const regex = /\*\*(.+?)\*\*/
    return [
      {
        pattern: regex,
        style: {fontFamily: getFontFamily('bold')},
        renderText: (text) => text.replace(regex, `$1`),
      },
    ]
  }, [])

  const handleCommunityGuidlines = useMemo(
    () => (
      <StyledTextContainer>
        <StyledTitle fontWeight="bold" fontSize="xxl">
          {translate('policy:communityGuideline')}
        </StyledTitle>
        <StyledSubTitle>{translate('policy:communityAbout')}</StyledSubTitle>
        <StyledDescription>
          <ParsedText parse={patterns}>
            {translate('policy:communityAboutDesc1')}
          </ParsedText>
          <br />
          <br />
          {translate('policy:communityAboutDesc2')}
        </StyledDescription>
        <StyledSubTitle>
          {translate('policy:communityAuthenticity')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:communityAuthenticityDesc')}
        </StyledDescription>
        <StyledSubTitle>
          {translate('policy:communityAppropriate')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:communityAppropriateDesc')}
        </StyledDescription>
        <StyledSubTitle fontSize="l">
          {translate('policy:communityDisgrace')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:communityDisgraceDesc')}
        </StyledDescription>
        <StyledSubTitle fontSize="l">
          {translate('policy:communityOffensive')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:communityOffensiveDescA')}
        </StyledDescription>
        <StyledListUl>
          {Array.from({length: 11}).map((_, index) => (
            <StyledListItem key={index.toString()}>
              {translate('policy:communityOffensiveDescA', {
                context: `${index + 1}`,
              })}
            </StyledListItem>
          ))}
        </StyledListUl>
        <StyledDescription>
          {translate('policy:communityOffensiveDescB')}
        </StyledDescription>
        <StyledListUl>
          {Array.from({length: 5}).map((_, index) => (
            <StyledListItem key={index.toString()}>
              {translate('policy:communityOffensiveDescB', {
                context: `${index + 1}`,
              })}
            </StyledListItem>
          ))}
        </StyledListUl>
        <StyledSubTitle fontSize="l">
          {translate('policy:communityPromote')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:communityPromoteDesc')}
          <StyledListUl>
            {Array.from({length: 2}).map((_, index) => (
              <StyledListItem key={index.toString()}>
                {translate('policy:communityPromoteDesc', {
                  context: `${index + 1}`,
                })}
              </StyledListItem>
            ))}
          </StyledListUl>
        </StyledDescription>
      </StyledTextContainer>
    ),
    [patterns, translate],
  )

  const handlePornography = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>
          {translate('policy:communityNudityAndPorn')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:communityNudityAndPornDesc')}
        </StyledDescription>
        <StyledSubTitle fontSize="l">
          {translate('policy:communityNudity')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:communityNudityDesc')}
        </StyledDescription>
        <StyledListUl>
          {Array.from({length: 2}).map((_, index) => (
            <StyledListItem key={index.toString()}>
              {translate('policy:communityNudityDesc', {
                context: `${index + 1}`,
              })}
            </StyledListItem>
          ))}
        </StyledListUl>
        <StyledSubTitle fontSize="l">
          {translate('policy:communityPorn')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:communityPornDesc')}
        </StyledDescription>
        <StyledListUl>
          {Array.from({length: 4}).map((_, index) => (
            <StyledListItem key={index.toString()}>
              {translate('policy:communityPornDesc', {
                context: `${index + 1}`,
              })}
            </StyledListItem>
          ))}
        </StyledListUl>
      </StyledTextContainer>
    ),
    [translate],
  )
  const handleBullying = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>
          {translate('policy:communityBullyingAndOthers')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:communityBullyingAndOthersDesc')}
        </StyledDescription>
        <StyledSubTitle fontSize="l">
          {translate('policy:communityBullying')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:communityBullyingDesc')}
        </StyledDescription>
        <StyledListUl>
          {Array.from({length: 3}).map((_, index) => (
            <StyledListItem key={index.toString()}>
              {translate('policy:communityBullyingDesc', {
                context: `${index + 1}`,
              })}
            </StyledListItem>
          ))}
        </StyledListUl>
        <StyledSubTitle fontSize="l">
          {translate('policy:communityVerbal')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:communityVerbalDesc')}
        </StyledDescription>
        <StyledListUl>
          {Array.from({length: 2}).map((_, index) => (
            <StyledListItem key={index.toString()}>
              {translate('policy:communityVerbalDesc', {
                context: `${index + 1}`,
              })}
            </StyledListItem>
          ))}
        </StyledListUl>
        <StyledSubTitle fontSize="l">
          {translate('policy:communitySexual')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:communitySexualDesc')}
        </StyledDescription>
        <StyledListUl>
          {Array.from({length: 4}).map((_, index) => (
            <StyledListItem key={index.toString()}>
              {translate('policy:communitySexualDesc', {
                context: `${index + 1}`,
              })}
            </StyledListItem>
          ))}
        </StyledListUl>
        <StyledSubTitle fontSize="l">
          {translate('policy:communityThreat')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:communityThreatDesc')}
        </StyledDescription>
        <StyledListUl>
          {Array.from({length: 3}).map((_, index) => (
            <StyledListItem key={index.toString()}>
              {translate('policy:communityThreatDesc', {
                context: `${index + 1}`,
              })}
            </StyledListItem>
          ))}
        </StyledListUl>
      </StyledTextContainer>
    ),
    [translate],
  )

  const handleCriminal = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>
          {translate('policy:communityCriminalityAndOthers')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:communityCriminalityAndOthersDesc')}
        </StyledDescription>
        <StyledSubTitle fontSize="l">
          {translate('policy:communityCriminality')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:communityCriminalityDesc')}
        </StyledDescription>
        <StyledListUl>
          {Array.from({length: 5}).map((_, index) => (
            <StyledListItem key={index.toString()}>
              {translate('policy:communityCriminalityDesc', {
                context: `${index + 1}`,
              })}
            </StyledListItem>
          ))}
        </StyledListUl>
        <StyledSubTitle fontSize="l">
          {translate('policy:communityGambling')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:communityGamblingDesc')}
        </StyledDescription>
        <StyledSubTitle fontSize="l">
          {translate('policy:communityControlled')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:communityControlledDesc')}
        </StyledDescription>
        <StyledListUl>
          {Array.from({length: 2}).map((_, index) => (
            <StyledListItem key={index.toString()}>
              {translate('policy:communityControlledDesc', {
                context: `${index + 1}`,
              })}
            </StyledListItem>
          ))}
        </StyledListUl>
      </StyledTextContainer>
    ),
    [translate],
  )

  const handleRadical = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>
          {translate('policy:communityRadicalAndViolence')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:communityRadicalAndViolenceDesc')}
        </StyledDescription>
        <StyledSubTitle fontSize="l">
          {translate('policy:communityViolence')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:communityViolenceDesc')}
        </StyledDescription>
        <StyledListUl>
          {Array.from({length: 2}).map((_, index) => (
            <StyledListItem key={index.toString()}>
              {translate('policy:communityViolenceDesc', {
                context: `${index + 1}`,
              })}
            </StyledListItem>
          ))}
        </StyledListUl>
        <StyledSubTitle fontSize="l">
          {translate('policy:communityRadical')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:communityRadicalDesc')}
        </StyledDescription>
        <StyledSubTitle fontSize="l">
          {translate('policy:communityRadicalReligion')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:communityRadicalReligionDesc')}
        </StyledDescription>
        <StyledSubTitle fontSize="l">
          {translate('policy:communityRadicalPolitics')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:communityRadicalPoliticsDesc')}
        </StyledDescription>
        <StyledSubTitle fontSize="l">
          {translate('policy:communityTerrorist')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:communityTerroristDesc')}
        </StyledDescription>
        <StyledSubTitle fontSize="l">
          {translate('policy:communityCriminal')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:communityCriminalDesc1')}
          <br />
          <br />
          {translate('policy:communityCriminalDesc2')}
        </StyledDescription>
        <StyledSubTitle>{translate('policy:communitySadistic')}</StyledSubTitle>
        <StyledDescription>
          {translate('policy:communitySadisticDesc')}
        </StyledDescription>
        <StyledListUl>
          {Array.from({length: 6}).map((_, index) => (
            <StyledListItem key={index.toString()}>
              {translate('policy:communitySadisticDesc', {
                context: `${index + 1}`,
              })}
            </StyledListItem>
          ))}
        </StyledListUl>
      </StyledTextContainer>
    ),
    [translate],
  )

  const handleDangerous = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>
          {translate('policy:communityDangerousAndOthers')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:communityDangerousAndOthersDesc')}
        </StyledDescription>
        <StyledSubTitle fontSize="l">
          {translate('policy:communityDangerous')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:communityDangerousDesc')}
        </StyledDescription>
        <StyledListUl>
          {Array.from({length: 4}).map((_, index) => (
            <StyledListItem key={index.toString()}>
              {translate('policy:communityDangerousDesc', {
                context: `${index + 1}`,
              })}
            </StyledListItem>
          ))}
        </StyledListUl>
        <StyledSubTitle fontSize="l">
          {translate('policy:communitySelfInjury')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:communitySelfInjuryDesc')}
        </StyledDescription>
        <StyledListUl>
          {Array.from({length: 3}).map((_, index) => (
            <StyledListItem key={index.toString()}>
              {translate('policy:communitySelfInjuryDesc', {
                context: `${index + 1}`,
              })}
            </StyledListItem>
          ))}
        </StyledListUl>
        <StyledSubTitle fontSize="l">
          {translate('policy:communitySuicide')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:communitySuicideDesc')}
        </StyledDescription>
        <StyledListUl>
          {Array.from({length: 4}).map((_, index) => (
            <StyledListItem key={index.toString()}>
              {translate('policy:communitySuicideDesc', {
                context: `${index + 1}`,
              })}
            </StyledListItem>
          ))}
        </StyledListUl>
      </StyledTextContainer>
    ),
    [translate],
  )

  const handleIntegrity = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>
          {translate('policy:communityPromoteIntegrity')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:communityPromoteIntegrityDesc')}
        </StyledDescription>
        <StyledSubTitle fontSize="l">
          {translate('policy:communityImpersonate')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:communityImpersonateDesc')}
        </StyledDescription>
        <StyledListUl>
          {Array.from({length: 2}).map((_, index) => (
            <StyledListItem key={index.toString()}>
              {translate('policy:communityImpersonateDesc', {
                context: `${index + 1}`,
              })}
            </StyledListItem>
          ))}
        </StyledListUl>
        <StyledSubTitle fontSize="l">
          {translate('policy:communitySpreadMisleading')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:communitySpreadMisleadingDesc')}
        </StyledDescription>
        <StyledSubTitle fontSize="l">
          {translate('policy:communityProvideFake')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:communityProvideFakeDesc')}
        </StyledDescription>
        <StyledListUl>
          {Array.from({length: 3}).map((_, index) => (
            <StyledListItem key={index.toString()}>
              {translate('policy:communityProvideFakeDesc', {
                context: `${index + 1}`,
              })}
            </StyledListItem>
          ))}
        </StyledListUl>
      </StyledTextContainer>
    ),
    [translate],
  )

  const handleSecurity = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>
          {translate('policy:communityFotoyuSecurity')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:communityFotoyuSecurityDescA')}
        </StyledDescription>
        <StyledListUl>
          {Array.from({length: 2}).map((_, index) => (
            <StyledListItem key={index.toString()}>
              {translate('policy:communityFotoyuSecurityDescA', {
                context: `${index + 1}`,
              })}
            </StyledListItem>
          ))}
        </StyledListUl>
        <StyledDescription>
          {translate('policy:communityFotoyuSecurityDescB')}
        </StyledDescription>
        <StyledSubTitle>
          {translate('policy:communityComplyWith')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:communityComplyWithDesc')}
        </StyledDescription>
        <StyledSubTitle>{translate('policy:communityLetsKeep')}</StyledSubTitle>
        <StyledDescription>
          {translate('policy:communityLetsKeepDesc')}
          <StyledLink
            href={`mailto:${translate('policy:emailSupport', {
              Domain: DOMAIN_NAME,
            })}`}>
            {translate('policy:emailSupport', {Domain: DOMAIN_NAME})}
          </StyledLink>
          .
        </StyledDescription>
      </StyledTextContainer>
    ),
    [translate],
  )

  return (
    <div>
      {handleCommunityGuidlines}
      {handlePornography}
      {handleBullying}
      {handleCriminal}
      {handleRadical}
      {handleDangerous}
      {handleIntegrity}
      {handleSecurity}
    </div>
  )
}
