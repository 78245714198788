import React, {useCallback, useMemo} from 'react'
import styled from 'styled-components'
import {WINDOW_MODE_TABLET_WIDTH} from 'consts'
import {useTranslation} from 'i18n'
import {getHowItWorksWebUrl, useHistory} from 'utils'
import {useSelector} from 'lib/redux'
import convertUnit from 'lib/unit'
import {GiftShopBannerReferralTemplate} from '../ReferralTemplate'
import {GiftShopSettingItems} from '../Items'
import {GiftShopSettingSupport} from '../Support'
import {GiftShopStorageItem} from '../Storage'
import {GiftShopSettingContentProps} from './GiftShopSettingContentProps'

const StyledContainer = styled.div`
  width: ${convertUnit(580)};
  padding-top: ${convertUnit(30)};
  display: flex;
  flex-direction: column;
  padding-left: ${convertUnit(20)};
  padding-right: ${convertUnit(20)};
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    width: 100%;
    padding-top: ${convertUnit(1)};
  }
`

const StyledLink = styled.a`
  text-decoration: none;
`

export default function GiftShopSettingContent({
  onClickRoboyu,
  onClickEditProfile,
  onLogout,
  onClickPartnership,
  onClickManageAccount,
  onClickShareProfile,
}: GiftShopSettingContentProps) {
  const {translate} = useTranslation()
  const history = useHistory()
  const {creatorStatus} = useSelector('yuserActivationState')
  const {status: hostStatus, tagName} = useSelector('hostRegistrationState')
  const {status: childStatus} = useSelector('parentalConsentState')

  const handleClickHashtag = useCallback(() => {
    if (hostStatus === 'verified') {
      if (tagName) history.push('tree_host_profile', {})
      else history.push('tree_add_host_information', {})
    } else if (hostStatus === 'processing')
      history.push('tree_host_registration', {})
    else history.push('giftshop_setting_host', {})
  }, [history, hostStatus, tagName])

  const handleRenderStorage = useMemo(
    () => (
      <>
        <GiftShopSettingItems
          icon="cloud"
          showArrow={false}
          label={translate('giftShop:storage')}
          cursor="default"
          showBorder={false}
        />
        <GiftShopStorageItem />
      </>
    ),
    [translate],
  )

  const handleRenderEditProfile = useMemo(
    () => (
      <GiftShopSettingItems
        icon="people"
        label={translate('giftShop:editProfile')}
        onClickItem={onClickEditProfile}
      />
    ),
    [onClickEditProfile, translate],
  )

  const handleRenderShareProfile = useMemo(
    () => (
      <GiftShopSettingItems
        icon="share-profile"
        label={translate('giftShop:shareProfile')}
        onClickItem={onClickShareProfile}
      />
    ),
    [onClickShareProfile, translate],
  )

  const handleRenderRoboyu = useMemo(
    () => (
      <GiftShopSettingItems
        icon="robopet"
        label={translate('giftShop:robopet')}
        onClickItem={onClickRoboyu}
      />
    ),
    [onClickRoboyu, translate],
  )

  const handleRenderHostSetting = useMemo(
    () =>
      creatorStatus === 'verified' && (
        <GiftShopSettingItems
          icon="hashtag"
          label={translate('giftShop:host')}
          onClickItem={handleClickHashtag}
        />
      ),
    [creatorStatus, handleClickHashtag, translate],
  )

  const handleRenderManageAccount = useMemo(
    () => (
      <GiftShopSettingItems
        icon="manage-account"
        label={translate('giftShop:manageAccount')}
        onClickItem={onClickManageAccount}
      />
    ),
    [onClickManageAccount, translate],
  )

  const handleRenderPartnership = useMemo(
    () => (
      <GiftShopSettingItems
        icon="file"
        label={translate('giftShop:partnership')}
        onClickItem={onClickPartnership}
      />
    ),
    [onClickPartnership, translate],
  )

  const handleRenderHowItWorks = useMemo(
    () => (
      <StyledLink href={getHowItWorksWebUrl()} target="_blank" rel="noreferrer">
        <GiftShopSettingItems
          icon="book-question"
          label={translate('global:howItWorks')}
        />
      </StyledLink>
    ),
    [translate],
  )

  const handleRenderLogout = useMemo(
    () => (
      <>
        <GiftShopSettingItems
          icon="logout"
          label={translate('global:logout')}
          onClickItem={onLogout}
          primaryColor
          showArrow={false}
        />
        <GiftShopSettingSupport isHorizontal={false} />
      </>
    ),
    [onLogout, translate],
  )

  return (
    <>
      <StyledContainer>
        {childStatus !== 'UNDERAGE' && (
          <GiftShopBannerReferralTemplate page="setting" />
        )}
        {handleRenderStorage}
        {handleRenderEditProfile}
        {handleRenderShareProfile}
        {handleRenderRoboyu}
        {handleRenderHostSetting}
        {handleRenderManageAccount}
        {handleRenderPartnership}
        {handleRenderHowItWorks}
        {handleRenderLogout}
      </StyledContainer>
    </>
  )
}
