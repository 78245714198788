import React, {useMemo} from 'react'
import styled from 'styled-components'
import {getFontFamily, getFontSize} from 'utils'
import {Icon, Paragraph, ParsedText} from 'common/components'
import convertUnit from 'lib/unit'
import {useTranslation} from 'i18n'
import {useTheme} from 'themes'
import {parseShape} from 'types'

const StyledList = styled.ol`
  padding-left: ${convertUnit(25)};
`
const StyledListItem = styled.li`
  font-family: ${getFontFamily('regular')};
  font-size: ${getFontSize('l')};
  line-height: ${convertUnit(31)};
  padding-left: ${convertUnit(25)};
`
const StyledTextContainer = styled.div`
  text-align: justify;
`
const StyledTitle = styled(Paragraph)`
  ${({theme}) => ({
    color: theme.primary_5,
  })}
  text-align: justify;
`
const StyledSubTitle = styled(Paragraph)`
  ${({fontSize = 'xl', fontWeight = 'bold'}) => ({
    fontSize: typeof fontSize === 'number' ? fontSize : getFontSize(fontSize),
    fontFamily: getFontFamily(fontWeight),
  })}
  margin-top: ${convertUnit(20)};
  text-align: justify;
`
const StyledDescription = styled(Paragraph)`
  ${({fontSize = 'l'}) => ({
    fontSize: typeof fontSize === 'number' ? fontSize : getFontSize(fontSize),
  })}
  margin-top: ${convertUnit(10)};
  line-height: ${convertUnit(31)};
  white-space: pre-wrap;
  text-align: justify;
`
const StyledBold = styled.span`
  font-family: ${getFontFamily('bold')};
`
const StyledLink = styled.a`
  color: ${({theme}) => theme.primary_5};
  text-decoration: underline;
  cursor: pointer;
`

export default function LegalHelpCenterContent({
  scrollTo,
}: {
  scrollTo: (childRef: number | undefined) => void
}) {
  const theme = useTheme()
  const {translate} = useTranslation()

  const patterns = useMemo((): parseShape[] => {
    const regexPatterns = [
      {
        regex: /\*\*(.+?)\*\*/,
        style: {fontFamily: getFontFamily('bold')},
      },
      {
        regex: /\[(.+?)\]/,
        style: {
          cursor: 'pointer',
          color: theme.primary_5,
          textDecoration: 'underline',
        },
      },
    ]
    return regexPatterns.map(({regex, style}) => ({
      pattern: regex,
      style,
      renderText: (text) => text.replace(regex, '$1'),
    }))
  }, [theme.primary_5])

  const handleGetStarted = useMemo(
    () => (
      <StyledTextContainer>
        <StyledTitle fontWeight="bold" fontSize="xxl">
          {translate('policy:helpCenterPart1')}
        </StyledTitle>
        <StyledSubTitle>
          {translate('policy:helpCenterPart1Start')}
        </StyledSubTitle>
        <StyledSubTitle fontSize="l">
          {translate('policy:helpCenterPart1CreateAccount')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:helpCenterPart1CreateAccountViaApp')}
        </StyledDescription>
        <StyledList>
          {Array.from({length: 7}).map((_, index) => (
            <StyledListItem key={index.toString()}>
              <ParsedText parse={patterns}>
                {translate('policy:helpCenterPart1CreateAccountViaApp', {
                  context: `${index + 1}`,
                })}
              </ParsedText>
            </StyledListItem>
          ))}
        </StyledList>
        <StyledDescription>
          {translate('policy:helpCenterPart1CreateAccountViaWeb')}
        </StyledDescription>
        <StyledList>
          {Array.from({length: 6}).map((_, index) => (
            <StyledListItem key={index.toString()}>
              <ParsedText parse={patterns}>
                {translate('policy:helpCenterPart1CreateAccountViaWeb', {
                  context: `${index + 1}`,
                })}
              </ParsedText>
            </StyledListItem>
          ))}
        </StyledList>
        <StyledSubTitle fontSize="l">
          {translate('policy:helpCenterPart1Login')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:helpCenterPart1LoginViaApp')}
        </StyledDescription>
        <StyledList>
          <StyledListItem>
            {translate('policy:helpCenterPart1LoginViaApp1')}
            <StyledList>
              {Array.from({length: 4}).map((_, index) => (
                <StyledListItem key={index.toString()}>
                  <ParsedText parse={patterns}>
                    {translate('policy:helpCenterPart1LoginViaApp1', {
                      context: `${index + 1}`,
                    })}
                  </ParsedText>
                </StyledListItem>
              ))}
            </StyledList>
          </StyledListItem>
          <StyledListItem>
            {translate('policy:helpCenterPart1LoginViaApp2')}
            <StyledList>
              {Array.from({length: 2}).map((_, index) => (
                <StyledListItem key={index.toString()}>
                  {translate('policy:helpCenterPart1LoginViaApp2', {
                    context: `${index + 1}`,
                  })}
                </StyledListItem>
              ))}
            </StyledList>
          </StyledListItem>
          <StyledListItem>
            {translate('policy:helpCenterPart1LoginViaApp3')}
            <StyledList>
              {Array.from({length: 2}).map((_, index) => (
                <StyledListItem key={index.toString()}>
                  {translate('policy:helpCenterPart1LoginViaApp3', {
                    context: `${index + 1}`,
                  })}
                </StyledListItem>
              ))}
            </StyledList>
          </StyledListItem>
        </StyledList>
        <StyledDescription>
          {translate('policy:helpCenterPart1LoginViaWeb')}
        </StyledDescription>
        <StyledList>
          <StyledListItem>
            {translate('policy:helpCenterPart1LoginViaWeb1')}
            <StyledList>
              {Array.from({length: 4}).map((_, index) => (
                <StyledListItem key={index.toString()}>
                  <ParsedText parse={patterns}>
                    {translate('policy:helpCenterPart1LoginViaWeb1', {
                      context: `${index + 1}`,
                    })}
                  </ParsedText>
                </StyledListItem>
              ))}
            </StyledList>
          </StyledListItem>
          <StyledListItem>
            {translate('policy:helpCenterPart1LoginViaWeb2')}
            <StyledList>
              {Array.from({length: 2}).map((_, index) => (
                <StyledListItem key={index.toString()}>
                  <ParsedText parse={patterns}>
                    {translate('policy:helpCenterPart1LoginViaWeb2', {
                      context: `${index + 1}`,
                    })}
                  </ParsedText>
                </StyledListItem>
              ))}
            </StyledList>
          </StyledListItem>
          <StyledListItem>
            {translate('policy:helpCenterPart1LoginViaWeb3')}
            <StyledList>
              {Array.from({length: 2}).map((_, index) => (
                <StyledListItem key={index.toString()}>
                  <ParsedText parse={patterns}>
                    {translate('policy:helpCenterPart1LoginViaWeb3', {
                      context: `${index + 1}`,
                    })}
                  </ParsedText>
                </StyledListItem>
              ))}
            </StyledList>
          </StyledListItem>
        </StyledList>
      </StyledTextContainer>
    ),
    [patterns, translate],
  )

  const handleProfile = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>
          {translate('policy:helpCenterPart1Profile')}
        </StyledSubTitle>
        <StyledSubTitle fontSize="l">
          {translate('policy:helpCenterPart1ProfileUpdate')}
        </StyledSubTitle>

        <StyledDescription>
          <StyledBold>
            {translate('policy:helpCenterPart1ProfileInformation')}
          </StyledBold>
          <br />
          {translate('policy:helpCenterPart1ProfileInformationDesc')}
        </StyledDescription>

        <StyledDescription>
          <StyledBold>
            {translate('policy:helpCenterPart1ProfileEmail')}
          </StyledBold>
          <br />
          {translate('policy:helpCenterPart1ProfileEmailAdd')}
        </StyledDescription>
        <StyledList>
          <StyledListItem>
            {translate('policy:helpCenterPart1ProfileEmailAddViaApp')}
            <StyledList>
              {Array.from({length: 6}).map((_, index) => (
                <StyledListItem key={index.toString()}>
                  <ParsedText parse={patterns}>
                    {translate('policy:helpCenterPart1ProfileEmailAddViaApp', {
                      context: `${index + 1}`,
                    })}
                  </ParsedText>
                  {index === 0 && (
                    <>
                      <Icon type="account" />
                      <StyledBold>
                        {translate(
                          'policy:helpCenterPart1ProfileEmailAddViaApp_1_A',
                        )}
                      </StyledBold>
                    </>
                  )}
                </StyledListItem>
              ))}
            </StyledList>
          </StyledListItem>
          <StyledListItem>
            {translate('policy:helpCenterPart1ProfileEmailAddViaWeb')}
            <StyledList>
              {Array.from({length: 6}).map((_, index) => (
                <StyledListItem key={index.toString()}>
                  <ParsedText parse={patterns}>
                    {translate('policy:helpCenterPart1ProfileEmailAddViaWeb', {
                      context: `${index + 1}`,
                    })}
                  </ParsedText>
                  {index === 0 && (
                    <>
                      <Icon type="account" />
                      <StyledBold>
                        {translate(
                          'policy:helpCenterPart1ProfileEmailAddViaWeb_1_A',
                        )}
                      </StyledBold>
                    </>
                  )}
                </StyledListItem>
              ))}
            </StyledList>
          </StyledListItem>
        </StyledList>

        <StyledDescription>
          <StyledBold>
            {translate('policy:helpCenterPart1ProfilePhone')}
          </StyledBold>
          <br />
          {translate('policy:helpCenterPart1ProfilePhoneAdd')}
        </StyledDescription>
        <StyledList>
          <StyledListItem>
            {translate('policy:helpCenterPart1ProfilePhoneAddViaApp')}
            <StyledList>
              {Array.from({length: 6}).map((_, index) => (
                <StyledListItem key={index.toString()}>
                  <ParsedText parse={patterns}>
                    {translate('policy:helpCenterPart1ProfilePhoneAddViaApp', {
                      context: `${index + 1}`,
                    })}
                  </ParsedText>
                  {index === 0 && (
                    <>
                      <Icon type="account" />
                      <StyledBold>
                        {translate(
                          'policy:helpCenterPart1ProfilePhoneAddViaApp_1_A',
                        )}
                      </StyledBold>
                    </>
                  )}
                </StyledListItem>
              ))}
            </StyledList>
          </StyledListItem>
          <StyledListItem>
            {translate('policy:helpCenterPart1ProfilePhoneAddViaWeb')}
            <StyledList>
              {Array.from({length: 6}).map((_, index) => (
                <StyledListItem key={index.toString()}>
                  <ParsedText parse={patterns}>
                    {translate('policy:helpCenterPart1ProfilePhoneAddViaWeb', {
                      context: `${index + 1}`,
                    })}
                  </ParsedText>
                  {index === 0 && (
                    <>
                      <Icon type="account" />
                      <StyledBold>
                        {translate(
                          'policy:helpCenterPart1ProfilePhoneAddViaWeb_1_A',
                        )}
                      </StyledBold>
                    </>
                  )}
                </StyledListItem>
              ))}
            </StyledList>
          </StyledListItem>
        </StyledList>

        <StyledDescription>
          <StyledBold>
            {translate('policy:helpCenterPart1ProfilePicture')}
          </StyledBold>
          <br />
          {translate('policy:helpCenterPart1ProfilePictureAdd')}
        </StyledDescription>
        <StyledList>
          <StyledListItem>
            {translate('policy:helpCenterPart1ProfilePictureAddViaApp')}
            <StyledList>
              {Array.from({length: 6}).map((_, index) => (
                <StyledListItem key={index.toString()}>
                  <ParsedText parse={patterns}>
                    {translate(
                      'policy:helpCenterPart1ProfilePictureAddViaApp',
                      {
                        context: `${index + 1}`,
                      },
                    )}
                  </ParsedText>
                  {index === 0 && (
                    <>
                      <Icon type="account" />
                      <StyledBold>
                        {translate(
                          'policy:helpCenterPart1ProfilePictureAddViaApp_1_A',
                        )}
                      </StyledBold>
                    </>
                  )}
                  {index === 2 && (
                    <>
                      <Icon type="camera" />
                      {'.'}
                    </>
                  )}
                  {index === 5 && (
                    <>
                      <Icon type="check" />
                      <ParsedText parse={patterns}>
                        {translate(
                          'policy:helpCenterPart1ProfilePictureAddViaApp_6_A',
                        )}
                      </ParsedText>
                    </>
                  )}
                </StyledListItem>
              ))}
            </StyledList>
          </StyledListItem>
          <StyledListItem>
            {translate('policy:helpCenterPart1ProfilePictureAddViaWeb')}
            <StyledList>
              {Array.from({length: 6}).map((_, index) => (
                <StyledListItem key={index.toString()}>
                  <ParsedText parse={patterns}>
                    {translate(
                      'policy:helpCenterPart1ProfilePictureAddViaWeb',
                      {
                        context: `${index + 1}`,
                      },
                    )}
                  </ParsedText>
                  {index === 0 && (
                    <>
                      <Icon type="account" />
                      <StyledBold>
                        {translate(
                          'policy:helpCenterPart1ProfilePictureAddViaWeb_1_A',
                        )}
                      </StyledBold>
                    </>
                  )}
                  {index === 2 && (
                    <>
                      <Icon type="camera" />
                      {'.'}
                    </>
                  )}
                </StyledListItem>
              ))}
            </StyledList>
          </StyledListItem>
        </StyledList>

        <StyledDescription>
          <StyledBold>
            {translate('policy:helpCenterPart1ProfileBio')}
          </StyledBold>
          <br />
          {translate('policy:helpCenterPart1ProfileBioAdd')}
        </StyledDescription>
        <StyledList>
          <StyledListItem>
            {translate('policy:helpCenterPart1ProfileBioAddViaApp')}
            <StyledList>
              {Array.from({length: 5}).map((_, index) => (
                <StyledListItem key={index.toString()}>
                  <ParsedText parse={patterns}>
                    {translate('policy:helpCenterPart1ProfileBioAddViaApp', {
                      context: `${index + 1}`,
                    })}
                  </ParsedText>
                  {index === 0 && (
                    <>
                      <Icon type="account" />
                      <StyledBold>
                        {translate(
                          'policy:helpCenterPart1ProfileBioAddViaApp_1_A',
                        )}
                      </StyledBold>
                    </>
                  )}
                </StyledListItem>
              ))}
            </StyledList>
          </StyledListItem>
          <StyledListItem>
            {translate('policy:helpCenterPart1ProfileBioAddViaWeb')}
            <StyledList>
              {Array.from({length: 5}).map((_, index) => (
                <StyledListItem key={index.toString()}>
                  <ParsedText parse={patterns}>
                    {translate('policy:helpCenterPart1ProfileBioAddViaWeb', {
                      context: `${index + 1}`,
                    })}
                  </ParsedText>
                  {index === 0 && (
                    <>
                      <Icon type="account" />
                      <StyledBold>
                        {translate(
                          'policy:helpCenterPart1ProfileBioAddViaWeb_1_A',
                        )}
                      </StyledBold>
                    </>
                  )}
                </StyledListItem>
              ))}
            </StyledList>
          </StyledListItem>
        </StyledList>

        <StyledDescription>
          <StyledBold>
            {translate('policy:helpCenterPart1ProfilePassword')}
          </StyledBold>
          <br />
          <ParsedText parse={patterns}>
            {translate('policy:helpCenterPart1ProfilePasswordAdd')}
          </ParsedText>
        </StyledDescription>
        <StyledList>
          <StyledListItem>
            {translate('policy:helpCenterPart1ProfilePasswordAddViaApp')}
            <StyledList>
              {Array.from({length: 7}).map((_, index) => (
                <StyledListItem key={index.toString()}>
                  <ParsedText parse={patterns}>
                    {translate(
                      'policy:helpCenterPart1ProfilePasswordAddViaApp',
                      {context: `${index + 1}`},
                    )}
                  </ParsedText>
                  {index === 0 && (
                    <>
                      <Icon type="account" />
                      <StyledBold>
                        {translate(
                          'policy:helpCenterPart1ProfilePasswordAddViaApp_1_A',
                        )}
                      </StyledBold>
                    </>
                  )}
                </StyledListItem>
              ))}
            </StyledList>
          </StyledListItem>
          <StyledListItem>
            {translate('policy:helpCenterPart1ProfilePasswordAddViaWeb')}
            <StyledList>
              {Array.from({length: 7}).map((_, index) => (
                <StyledListItem key={index.toString()}>
                  <ParsedText parse={patterns}>
                    {translate(
                      'policy:helpCenterPart1ProfilePasswordAddViaWeb',
                      {context: `${index + 1}`},
                    )}
                  </ParsedText>
                  {index === 0 && (
                    <>
                      <Icon type="account" />
                      <StyledBold>
                        {translate(
                          'policy:helpCenterPart1ProfilePasswordAddViaWeb_1_A',
                        )}
                      </StyledBold>
                    </>
                  )}
                </StyledListItem>
              ))}
            </StyledList>
          </StyledListItem>
        </StyledList>

        <StyledDescription>
          <StyledBold>
            {translate('policy:helpCenterPart1ProfileSettings')}
          </StyledBold>
          <br />
          {translate('policy:helpCenterPart1ProfileSettingsDesc')}
        </StyledDescription>
        <StyledList>
          <StyledListItem>
            {translate('policy:helpCenterPart1ProfileSettingsViaApp')}
            <StyledList>
              {Array.from({length: 2}).map((_, index) => (
                <StyledListItem key={index.toString()}>
                  <ParsedText parse={patterns}>
                    {translate('policy:helpCenterPart1ProfileSettingsViaApp', {
                      context: `${index + 1}`,
                    })}
                  </ParsedText>
                  {index === 0 && (
                    <>
                      <Icon type="account" />
                      <StyledBold>
                        {translate(
                          'policy:helpCenterPart1ProfileSettingsViaApp_1_A',
                        )}
                      </StyledBold>
                    </>
                  )}
                </StyledListItem>
              ))}
            </StyledList>
          </StyledListItem>
          <StyledListItem>
            {translate('policy:helpCenterPart1ProfileSettingsViaWeb')}
            <StyledList>
              {Array.from({length: 2}).map((_, index) => (
                <StyledListItem key={index.toString()}>
                  <ParsedText parse={patterns}>
                    {translate('policy:helpCenterPart1ProfileSettingsViaWeb', {
                      context: `${index + 1}`,
                    })}
                  </ParsedText>
                  {index === 0 && (
                    <>
                      <Icon type="account" />
                      <StyledBold>
                        {translate(
                          'policy:helpCenterPart1ProfileSettingsViaWeb_1_A',
                        )}
                      </StyledBold>
                    </>
                  )}
                </StyledListItem>
              ))}
            </StyledList>
          </StyledListItem>
        </StyledList>

        <StyledDescription>
          <StyledBold>
            {translate('policy:helpCenterPart1ProfileLogOut')}
          </StyledBold>
          <br />
          {translate('policy:helpCenterPart1ProfileLogOutDesc')}
        </StyledDescription>
        <StyledList>
          <StyledListItem>
            {translate('policy:helpCenterPart1ProfileLogOutViaApp')}
            <StyledList>
              <StyledListItem>
                {translate('policy:helpCenterPart1ProfileLogOutViaApp_1')}
                <Icon type="account" />
                <StyledBold>
                  {translate('policy:helpCenterPart1ProfileLogOutViaApp_1_A')}
                </StyledBold>
              </StyledListItem>
              <StyledListItem>
                {translate('policy:helpCenterPart1ProfileLogOutViaApp_2')}
                <Icon type="cog" />
                {translate('policy:helpCenterPart1ProfileLogOutViaApp_2_A')}
              </StyledListItem>
              <StyledListItem>
                {translate('policy:helpCenterPart1ProfileLogOutViaApp_3')}
                <Icon type="logout" />
                <StyledBold>
                  {translate('policy:helpCenterPart1ProfileLogOutViaApp_3_A')}
                </StyledBold>
              </StyledListItem>
            </StyledList>
          </StyledListItem>
          <StyledListItem>
            {translate('policy:helpCenterPart1ProfileLogOutViaWeb')}
            <StyledList>
              <StyledListItem>
                {translate('policy:helpCenterPart1ProfileLogOutViaWeb_1')}
                <Icon type="account" />
                <StyledBold>
                  {translate('policy:helpCenterPart1ProfileLogOutViaWeb_1_A')}
                </StyledBold>
              </StyledListItem>
              <StyledListItem>
                {translate('policy:helpCenterPart1ProfileLogOutViaWeb_2')}
                <Icon type="cog" />
                {translate('policy:helpCenterPart1ProfileLogOutViaWeb_2_A')}
              </StyledListItem>
              <StyledListItem>
                {translate('policy:helpCenterPart1ProfileLogOutViaWeb_3')}
                <Icon type="logout" />
                <StyledBold>
                  {translate('policy:helpCenterPart1ProfileLogOutViaWeb_3_A')}
                </StyledBold>
              </StyledListItem>
            </StyledList>
          </StyledListItem>
        </StyledList>

        <StyledDescription>
          <StyledBold>
            {translate('policy:helpCenterPart1ProfileLanguage')}
          </StyledBold>
          <br />
          {translate('policy:helpCenterPart1ProfileLanguageDescA')}
        </StyledDescription>
        <StyledList>
          <StyledListItem>
            {translate('policy:helpCenterPart1ProfileLanguageViaApp')}
            <StyledList>
              <StyledListItem>
                {translate('policy:helpCenterPart1ProfileLanguageViaApp_1')}
                <Icon type="account" />
                <StyledBold>
                  {translate('policy:helpCenterPart1ProfileLanguageViaApp_1_A')}
                </StyledBold>
              </StyledListItem>
              <StyledListItem>
                {translate('policy:helpCenterPart1ProfileLanguageViaApp_2')}
                <Icon type="cog" />
                {translate('policy:helpCenterPart1ProfileLanguageViaApp_2_A')}
              </StyledListItem>
              <StyledListItem>
                {translate('policy:helpCenterPart1ProfileLanguageViaApp_3')}
                <Icon type="language" />
                <StyledBold>
                  {translate('policy:helpCenterPart1ProfileLanguageViaApp_3_A')}
                </StyledBold>
              </StyledListItem>
              <StyledListItem>
                {translate('policy:helpCenterPart1ProfileLanguageViaApp_4')}
              </StyledListItem>
            </StyledList>
          </StyledListItem>
          <StyledListItem>
            {translate('policy:helpCenterPart1ProfileLanguageViaWeb')}
            <StyledList>
              <StyledListItem>
                {translate('policy:helpCenterPart1ProfileLanguageViaWeb_1')}
                <Icon type="account" />
                <StyledBold>
                  {translate('policy:helpCenterPart1ProfileLanguageViaWeb_1_A')}
                </StyledBold>
              </StyledListItem>
              <StyledListItem>
                {translate('policy:helpCenterPart1ProfileLanguageViaWeb_2')}
                <Icon type="cog" />
                {translate('policy:helpCenterPart1ProfileLanguageViaWeb_2_A')}
              </StyledListItem>
              <StyledListItem>
                {translate('policy:helpCenterPart1ProfileLanguageViaWeb_3')}
                <Icon type="language" />
                <StyledBold>
                  {translate('policy:helpCenterPart1ProfileLanguageViaWeb_3_A')}
                </StyledBold>
              </StyledListItem>
              <StyledListItem>
                {translate('policy:helpCenterPart1ProfileLanguageViaWeb_4')}
              </StyledListItem>
            </StyledList>
          </StyledListItem>
        </StyledList>
        <StyledDescription>
          {translate('policy:helpCenterPart1ProfileLanguageDescB')}
        </StyledDescription>
      </StyledTextContainer>
    ),
    [patterns, translate],
  )

  const handleUpload = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>
          {translate('policy:helpCenterPart1Upload')}
        </StyledSubTitle>
        <StyledSubTitle fontSize="l">
          {translate('policy:helpCenterPart1UploadPost')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:helpCenterPart1UploadPostDesc')}
        </StyledDescription>
        <StyledList>
          <StyledListItem>
            {translate('policy:helpCenterPart1UploadPostViaApp')}
            <StyledList>
              {Array.from({length: 5}).map((_, index) => (
                <StyledListItem key={index.toString()}>
                  <ParsedText parse={patterns}>
                    {translate('policy:helpCenterPart1UploadPostViaApp', {
                      context: `${index + 1}`,
                    })}
                  </ParsedText>
                  {index === 0 && (
                    <>
                      <Icon type="account" />
                      <StyledBold>
                        {translate(
                          'policy:helpCenterPart1UploadPostViaApp_1_A',
                        )}
                      </StyledBold>
                    </>
                  )}
                  {index === 4 && (
                    <>
                      <Icon type="check" />
                      <StyledBold>
                        {translate(
                          'policy:helpCenterPart1UploadPostViaApp_5_A',
                        )}
                      </StyledBold>
                    </>
                  )}
                </StyledListItem>
              ))}
            </StyledList>
          </StyledListItem>
          <StyledListItem>
            {translate('policy:helpCenterPart1UploadPostViaWeb')}
            <StyledList>
              {Array.from({length: 5}).map((_, index) => (
                <StyledListItem key={index.toString()}>
                  <ParsedText parse={patterns}>
                    {translate('policy:helpCenterPart1UploadPostViaWeb', {
                      context: `${index + 1}`,
                    })}
                  </ParsedText>
                  {index === 0 && (
                    <>
                      <Icon type="account" />
                      <StyledBold>
                        {translate(
                          'policy:helpCenterPart1UploadPostViaWeb_1_A',
                        )}
                      </StyledBold>
                    </>
                  )}
                  {index === 4 && (
                    <>
                      <Icon type="check" />
                      <StyledBold>
                        {translate(
                          'policy:helpCenterPart1UploadPostViaWeb_5_A',
                        )}
                      </StyledBold>
                    </>
                  )}
                </StyledListItem>
              ))}
            </StyledList>
          </StyledListItem>
        </StyledList>
      </StyledTextContainer>
    ),
    [patterns, translate],
  )

  const handleFeed = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>
          {translate('policy:helpCenterPart1Home')}
        </StyledSubTitle>

        <StyledSubTitle fontSize="l">
          {translate('policy:helpCenterPart1HomeYouSee')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:helpCenterPart1HomeYouSeeDesc')}
        </StyledDescription>

        <StyledSubTitle fontSize="l">
          {translate('policy:helpCenterPart1HomePhotoSequence')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:helpCenterPart1HomePhotoSequenceDesc')}
        </StyledDescription>

        <StyledSubTitle fontSize="l">
          {translate('policy:helpCenterPart1HomePhotoPrevious')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:helpCenterPart1HomePhotoPreviousDesc')}
        </StyledDescription>
      </StyledTextContainer>
    ),
    [translate],
  )

  const handleDM = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>
          {translate('policy:helpCenterPart1Message')}
        </StyledSubTitle>
        <StyledSubTitle fontSize="l">
          {translate('policy:helpCenterPart1MessageDesc')}
        </StyledSubTitle>

        <StyledDescription>
          <StyledBold>
            {translate('policy:helpCenterPart1MessageHowToSend')}
          </StyledBold>
          <br />
          {translate('policy:helpCenterPart1MessageHowToSendDesc')}
          <br />
        </StyledDescription>
        <StyledList>
          <StyledListItem>
            {translate('policy:helpCenterPart1MessageHowToSendStep')}
            <StyledList>
              {Array.from({length: 4}).map((_, index) => (
                <StyledListItem key={index.toString()}>
                  <ParsedText parse={patterns}>
                    {translate('policy:helpCenterPart1MessageHowToSendStep', {
                      context: `${index + 1}`,
                    })}
                  </ParsedText>
                  {index === 1 && (
                    <>
                      <Icon type="message" />
                      <ParsedText parse={patterns}>
                        {translate(
                          'policy:helpCenterPart1MessageHowToSendStep_2_A',
                        )}
                      </ParsedText>
                    </>
                  )}
                </StyledListItem>
              ))}
            </StyledList>
          </StyledListItem>
        </StyledList>

        <StyledDescription>
          <StyledBold>
            {translate('policy:helpCenterPart1MessageHowToView')}
          </StyledBold>
          <br />
          {translate('policy:helpCenterPart1MessageHowToViewDesc')}
          <br />
        </StyledDescription>
        <StyledList>
          <StyledListItem>
            {translate('policy:helpCenterPart1MessageHowToViewStep')}
            <StyledList>
              {Array.from({length: 2}).map((_, index) => (
                <StyledListItem key={index.toString()}>
                  <ParsedText parse={patterns}>
                    {translate('policy:helpCenterPart1MessageHowToViewStep', {
                      context: `${index + 1}`,
                    })}
                  </ParsedText>
                  {index === 0 && (
                    <>
                      <Icon type="message" />
                      <ParsedText parse={patterns}>
                        {translate(
                          'policy:helpCenterPart1MessageHowToViewStep_1_A',
                        )}
                      </ParsedText>
                    </>
                  )}
                </StyledListItem>
              ))}
            </StyledList>
          </StyledListItem>
        </StyledList>

        <StyledDescription>
          <StyledBold>
            {translate('policy:helpCenterPart1MessageHowToDelete')}
          </StyledBold>
          <br />
          {translate('policy:helpCenterPart1MessageHowToDeleteDesc')}
          <br />
        </StyledDescription>
        <StyledList>
          <StyledListItem>
            {translate('policy:helpCenterPart1MessageHowToDeleteStep')}
            <StyledList>
              {Array.from({length: 3}).map((_, index) => (
                <StyledListItem key={index.toString()}>
                  <ParsedText parse={patterns}>
                    {translate('policy:helpCenterPart1MessageHowToDeleteStep', {
                      context: `${index + 1}`,
                    })}
                  </ParsedText>
                  {index === 0 && (
                    <>
                      <Icon type="message" />
                      <ParsedText parse={patterns}>
                        {translate(
                          'policy:helpCenterPart1MessageHowToDeleteStep_1_A',
                        )}
                      </ParsedText>
                    </>
                  )}
                  {index === 2 && (
                    <>
                      <Icon type="ellipsis-v" />
                      <ParsedText parse={patterns}>
                        {translate(
                          'policy:helpCenterPart1MessageHowToDeleteStep_3_A',
                        )}
                      </ParsedText>
                    </>
                  )}
                </StyledListItem>
              ))}
            </StyledList>
          </StyledListItem>
        </StyledList>
      </StyledTextContainer>
    ),
    [patterns, translate],
  )

  const handleReport = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>
          {translate('policy:helpCenterPart1Report')}
        </StyledSubTitle>
        <StyledSubTitle fontSize="l">
          {translate('policy:helpCenterPart1ReportPhoto')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:helpCenterPart1ReportPhotoDesc')}
        </StyledDescription>
        <StyledList>
          <StyledListItem>
            {translate('policy:helpCenterPart1ReportPhotoViaApp')}
            <StyledList>
              {Array.from({length: 4}).map((_, index) => (
                <StyledListItem key={index.toString()}>
                  <ParsedText parse={patterns}>
                    {translate('policy:helpCenterPart1ReportPhotoViaApp', {
                      context: `${index + 1}`,
                    })}
                  </ParsedText>
                  {index === 0 && (
                    <>
                      <Icon type="more-filled" />
                      <ParsedText parse={patterns}>
                        {translate(
                          'policy:helpCenterPart1ReportPhotoViaApp_1_A',
                        )}
                      </ParsedText>
                    </>
                  )}
                  {index === 1 && (
                    <>
                      <Icon type="report" />
                      <StyledBold>
                        {translate(
                          'policy:helpCenterPart1ReportPhotoViaApp_2_A',
                        )}
                      </StyledBold>
                    </>
                  )}
                </StyledListItem>
              ))}
            </StyledList>
          </StyledListItem>
        </StyledList>
      </StyledTextContainer>
    ),
    [patterns, translate],
  )

  const handleRoboyu = useMemo(
    () => (
      <StyledTextContainer>
        <StyledTitle fontWeight="bold" fontSize="xxl">
          {translate('policy:helpCenterPart2')}
        </StyledTitle>
        <StyledSubTitle>
          {translate('policy:helpCenterPart2Roboyu')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:helpCenterPart2RoboyuDesc')}
        </StyledDescription>

        <StyledSubTitle fontSize="l">
          {translate('policy:helpCenterPart2FaceVerification')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:helpCenterPart2FaceVerificationDescA')}
        </StyledDescription>
        <StyledList>
          {Array.from({length: 12}).map((_, index) => (
            <StyledListItem key={index.toString()}>
              <ParsedText parse={patterns}>
                {translate('policy:helpCenterPart2FaceVerification', {
                  context: `${index + 1}`,
                })}
              </ParsedText>
              {index === 0 && (
                <>
                  <Icon type="robopet" />
                  <StyledBold>
                    {translate('policy:helpCenterPart2FaceVerification_1_A')}
                  </StyledBold>
                </>
              )}
              {index === 8 && (
                <>
                  <Icon type="dot" color="danger_5" />
                  <ParsedText parse={patterns}>
                    {translate('policy:helpCenterPart2FaceVerification_9_A')}
                  </ParsedText>
                </>
              )}
              {index === 10 && (
                <>
                  <Icon type="dot" />
                  <ParsedText parse={patterns}>
                    {translate('policy:helpCenterPart2FaceVerification_11_A')}
                  </ParsedText>
                </>
              )}
            </StyledListItem>
          ))}
        </StyledList>

        <StyledDescription>
          <ParsedText parse={patterns}>
            {translate('policy:helpCenterPart2FaceVerificationDescB')}
          </ParsedText>
        </StyledDescription>

        <StyledSubTitle fontSize="l">
          {translate('policy:helpCenterPart2RetakeSelfie')}
        </StyledSubTitle>
        <StyledDescription>
          <ParsedText parse={patterns}>
            {translate('policy:helpCenterPart2RetakeSelfieDesc')}
          </ParsedText>
        </StyledDescription>
        <StyledList>
          {Array.from({length: 7}).map((_, index) => (
            <StyledListItem key={index.toString()}>
              <ParsedText parse={patterns}>
                {translate('policy:helpCenterPart2RetakeSelfie', {
                  context: `${index + 1}`,
                })}
              </ParsedText>
              {index === 0 && (
                <>
                  <Icon type="face-scan" />
                  {'.'}
                </>
              )}
              {index === 3 && (
                <>
                  <Icon type="dot" color="danger_5" />
                  <ParsedText parse={patterns}>
                    {translate('policy:helpCenterPart2RetakeSelfie_4_A')}
                  </ParsedText>
                </>
              )}
              {index === 5 && (
                <>
                  <Icon type="dot" />
                  <ParsedText parse={patterns}>
                    {translate('policy:helpCenterPart2RetakeSelfie_6_A')}
                  </ParsedText>
                </>
              )}
            </StyledListItem>
          ))}
        </StyledList>

        <StyledSubTitle fontSize="l">
          {translate('policy:helpCenterPart2FindPhoto')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:helpCenterPart2FindPhotoDescA')}
          <br />
        </StyledDescription>
        <StyledList>
          <StyledListItem>
            {translate('policy:helpCenterPart2FindPhotoStep')}
            <StyledList>
              <StyledListItem>
                <ParsedText parse={patterns}>
                  {translate('policy:helpCenterPart2FindPhotoStep_1')}
                </ParsedText>
              </StyledListItem>
            </StyledList>
          </StyledListItem>
        </StyledList>
        <StyledDescription>
          {translate('policy:helpCenterPart2FindPhotoDescB')}
        </StyledDescription>
        <StyledDescription>
          <StyledBold>{translate('policy:helpCenterPart2Filter')}</StyledBold>
          <br />
          <ParsedText parse={patterns}>
            {translate('policy:helpCenterPart2FilterDesc')}
          </ParsedText>
        </StyledDescription>
        <StyledList>
          <StyledListItem>
            {translate('policy:helpCenterPart2FilterStep')}
            <StyledList>
              {Array.from({length: 5}).map((_, index) => (
                <StyledListItem key={index.toString()}>
                  <ParsedText parse={patterns}>
                    {translate('policy:helpCenterPart2FilterStep', {
                      context: `${index + 1}`,
                    })}
                  </ParsedText>
                </StyledListItem>
              ))}
            </StyledList>
          </StyledListItem>
        </StyledList>

        <StyledSubTitle fontSize="l">
          {translate('policy:helpCenterPart2ConfirmPhoto')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:helpCenterPart2ConfirmPhotoDescA')}
          <Icon type="fotoyu" />
          {translate('policy:helpCenterPart2ConfirmPhotoDescB')}
        </StyledDescription>
        <StyledList>
          <StyledListItem>
            {translate('policy:helpCenterPart2ConfirmPhotoStep')}
            <StyledList>
              <StyledListItem>
                {translate('policy:helpCenterPart2ConfirmPhotoStep_1')}
                <Icon type="check-custom" />
                <ParsedText parse={patterns}>
                  {translate('policy:helpCenterPart2ConfirmPhotoStep_1_A')}
                </ParsedText>
                <Icon type="not-interested" />
                <ParsedText parse={patterns}>
                  {translate('policy:helpCenterPart2ConfirmPhotoStep_1_B')}
                </ParsedText>
              </StyledListItem>
            </StyledList>
          </StyledListItem>
        </StyledList>

        <StyledSubTitle fontSize="l">
          {translate('policy:helpCenterPart2BuyPhoto')}
        </StyledSubTitle>
        <StyledDescription>
          <ParsedText parse={patterns}>
            {translate('policy:helpCenterPart2BuyPhotoDesc')}
          </ParsedText>
        </StyledDescription>
        <StyledList>
          <StyledListItem>
            {translate('policy:helpCenterPart2BuyPhotoViaApp')}
            <StyledList>
              <StyledListItem>
                <ParsedText parse={patterns}>
                  {translate('policy:helpCenterPart2BuyPhotoViaApp_1')}
                </ParsedText>
              </StyledListItem>
              <StyledListItem>
                {translate('policy:helpCenterPart2BuyPhotoViaApp_2')}
                <Icon type="cart" />
                <ParsedText parse={patterns}>
                  {translate('policy:helpCenterPart2BuyPhotoViaApp_2_A')}
                </ParsedText>
              </StyledListItem>
            </StyledList>
          </StyledListItem>
          <StyledListItem>
            {translate('policy:helpCenterPart2BuyPhotoPurchase')}
            <StyledList>
              {Array.from({length: 7}).map((_, index) => (
                <StyledListItem key={index.toString()}>
                  <ParsedText parse={patterns}>
                    {translate('policy:helpCenterPart2BuyPhotoPurchase', {
                      context: `${index + 1}`,
                    })}
                  </ParsedText>
                </StyledListItem>
              ))}
            </StyledList>
          </StyledListItem>
          <StyledListItem>
            {translate('policy:helpCenterPart2BuyPhotoViaWeb')}
            <StyledList>
              <StyledListItem>
                <ParsedText parse={patterns}>
                  {translate('policy:helpCenterPart2BuyPhotoViaWeb_1')}
                </ParsedText>
              </StyledListItem>
              <StyledListItem>
                {translate('policy:helpCenterPart2BuyPhotoViaWeb_2')}
                <Icon type="pencil" />
                <ParsedText parse={patterns}>
                  {translate('policy:helpCenterPart2BuyPhotoViaWeb_2_A')}
                </ParsedText>
              </StyledListItem>
            </StyledList>
          </StyledListItem>
          <StyledListItem>
            {translate('policy:helpCenterPart2BuyPhotoDirectly')}
            <StyledList>
              {Array.from({length: 5}).map((_, index) => (
                <StyledListItem key={index.toString()}>
                  <ParsedText parse={patterns}>
                    {translate('policy:helpCenterPart2BuyPhotoDirectly', {
                      context: `${index + 1}`,
                    })}
                  </ParsedText>
                </StyledListItem>
              ))}
            </StyledList>
          </StyledListItem>
          <StyledListItem>
            <ParsedText parse={patterns}>
              {translate('policy:helpCenterPart2BuyPhotoPutCart')}
            </ParsedText>
            <StyledList>
              {Array.from({length: 8}).map((_, index) => (
                <StyledListItem key={index.toString()}>
                  <ParsedText parse={patterns}>
                    {translate('policy:helpCenterPart2BuyPhotoPutCart', {
                      context: `${index + 1}`,
                    })}
                  </ParsedText>
                  {index === 0 && (
                    <>
                      <Icon type="cart" />
                      <ParsedText parse={patterns}>
                        {translate('policy:helpCenterPart2BuyPhotoPutCart_1_A')}
                      </ParsedText>
                    </>
                  )}
                  {index === 1 && (
                    <>
                      <Icon type="cart" />
                      {translate('policy:helpCenterPart2BuyPhotoPutCart_2_A')}
                    </>
                  )}
                </StyledListItem>
              ))}
            </StyledList>
          </StyledListItem>
        </StyledList>

        <StyledSubTitle fontSize="l">
          {translate('policy:helpCenterPart2Collection')}
        </StyledSubTitle>
        <StyledDescription>
          <ParsedText parse={patterns}>
            {translate('policy:helpCenterPart2CollectionDesc')}
          </ParsedText>
        </StyledDescription>
        <StyledList>
          {Array.from({length: 3}).map((_, index) => (
            <StyledListItem key={index.toString()}>
              <ParsedText parse={patterns}>
                {translate('policy:helpCenterPart2Collection', {
                  context: `${index + 1}`,
                })}
              </ParsedText>
              {index === 0 && (
                <>
                  <Icon type="account" />
                  <StyledBold>
                    {translate('policy:helpCenterPart2Collection_1_A')}
                  </StyledBold>
                </>
              )}
            </StyledListItem>
          ))}
        </StyledList>

        <StyledSubTitle fontSize="l">
          {translate('policy:helpCenterPart2Download')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:helpCenterPart2DownloadDescA')}
        </StyledDescription>
        <StyledList>
          {Array.from({length: 5}).map((_, index) => (
            <StyledListItem key={index.toString()}>
              <ParsedText parse={patterns}>
                {translate('policy:helpCenterPart2Download', {
                  context: `${index + 1}`,
                })}
              </ParsedText>
              {index === 0 && (
                <>
                  <Icon type="account" />
                  <StyledBold>
                    {translate('policy:helpCenterPart2Download_1_A')}
                  </StyledBold>
                </>
              )}
              {index === 3 && (
                <>
                  <Icon type="chevron-down" />
                  <ParsedText parse={patterns}>
                    {translate('policy:helpCenterPart2Download_4_A')}
                  </ParsedText>
                </>
              )}
              {index === 4 && (
                <>
                  <Icon type="download" />
                  <ParsedText parse={patterns}>
                    {translate('policy:helpCenterPart2Download_5_A')}
                  </ParsedText>
                </>
              )}
            </StyledListItem>
          ))}
        </StyledList>
        <StyledDescription>
          {translate('policy:helpCenterPart2DownloadDescB')}
          <StyledLink onClick={() => scrollTo(11)}>
            {translate('global:here')}
          </StyledLink>
        </StyledDescription>

        <StyledSubTitle fontSize="l">
          {translate('policy:helpCenterPart2PhotoDetail')}
        </StyledSubTitle>
        <StyledDescription>
          <ParsedText parse={patterns}>
            {translate('policy:helpCenterPart2PhotoDetailDesc')}
          </ParsedText>
        </StyledDescription>
        <StyledList>
          <StyledListItem>
            {translate('policy:helpCenterPart2PhotoDetail_1')}
            <Icon type="account" />
            <StyledBold>
              {translate('policy:helpCenterPart2PhotoDetail_1_A')}
            </StyledBold>
          </StyledListItem>
          <StyledListItem>
            <ParsedText parse={patterns}>
              {translate('policy:helpCenterPart2PhotoDetail_2')}
            </ParsedText>
          </StyledListItem>
          <StyledListItem>
            {translate('policy:helpCenterPart2PhotoDetail_3')}
            <Icon type="chevron-down" />
            <ParsedText parse={patterns}>
              {translate('policy:helpCenterPart2PhotoDetail_3_A')}
            </ParsedText>
          </StyledListItem>
        </StyledList>

        <StyledSubTitle fontSize="l">
          {translate('policy:helpCenterPart2SendMessage')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:helpCenterPart2SendMessageDesc')}
        </StyledDescription>
        <StyledList>
          {Array.from({length: 5}).map((_, index) => (
            <StyledListItem key={index.toString()}>
              <ParsedText parse={patterns}>
                {translate('policy:helpCenterPart2SendMessage', {
                  context: `${index + 1}`,
                })}
              </ParsedText>
              {index === 0 && (
                <>
                  <Icon type="account" />
                  <StyledBold>
                    {translate('policy:helpCenterPart2SendMessage_1_A')}
                  </StyledBold>
                </>
              )}
              {index === 3 && (
                <>
                  <Icon type="chevron-down" />
                  <ParsedText parse={patterns}>
                    {translate('policy:helpCenterPart2SendMessage_4_A')}
                  </ParsedText>
                </>
              )}
              {index === 4 && (
                <>
                  <Icon type="message" />
                  <ParsedText parse={patterns}>
                    {translate('policy:helpCenterPart2SendMessage_5_A')}
                  </ParsedText>
                </>
              )}
            </StyledListItem>
          ))}
        </StyledList>
      </StyledTextContainer>
    ),
    [patterns, scrollTo, translate],
  )

  const handleCreator = useMemo(
    () => (
      <StyledTextContainer>
        <StyledTitle fontWeight="bold" fontSize="xxl">
          {translate('policy:helpCenterPart3')}
        </StyledTitle>
        <StyledSubTitle>
          {translate('policy:helpCenterPart3BeCreator')}
        </StyledSubTitle>

        <StyledSubTitle fontSize="l">
          {translate('policy:helpCenterPart3Register')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:helpCenterPart3RegisterDescA')}
        </StyledDescription>
        <StyledDescription>
          <ParsedText parse={patterns}>
            {translate('policy:helpCenterPart3RegisterStep')}
          </ParsedText>
        </StyledDescription>
        <StyledList>
          {Array.from({length: 5}).map((_, index) => (
            <StyledListItem key={index.toString()}>
              <ParsedText parse={patterns}>
                {translate('policy:helpCenterPart3RegisterStep', {
                  context: `${index + 1}`,
                })}
              </ParsedText>
            </StyledListItem>
          ))}
        </StyledList>
        <StyledDescription>
          <ParsedText parse={patterns}>
            {translate('policy:helpCenterPart3RegisterByPassport')}
          </ParsedText>
        </StyledDescription>
        <StyledList>
          {Array.from({length: 5}).map((_, index) => (
            <StyledListItem key={index.toString()}>
              <ParsedText parse={patterns}>
                {translate('policy:helpCenterPart3RegisterByPassport', {
                  context: `${index + 1}`,
                })}
              </ParsedText>
            </StyledListItem>
          ))}
        </StyledList>
        <StyledDescription>
          {translate('policy:helpCenterPart3RegisterDescB')}
        </StyledDescription>

        <StyledSubTitle fontSize="l">
          {translate('policy:helpCenterPart3UploadPhoto')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:helpCenterPart3UploadPhotoDesc')}
        </StyledDescription>
        <StyledList>
          <StyledListItem>
            {translate('policy:helpCenterPart3UploadPhotoViaApp')}
            <br />
            <ParsedText parse={patterns}>
              {translate('policy:helpCenterPart3UploadPhotoViaAppDesc')}
            </ParsedText>
            <br />
            {translate('policy:helpCenterPart3UploadPhotoViaAppRoboyu')}
            <StyledList>
              {Array.from({length: 7}).map((_, index) => (
                <StyledListItem key={index.toString()}>
                  <ParsedText parse={patterns}>
                    {translate(
                      'policy:helpCenterPart3UploadPhotoViaAppRoboyu',
                      {context: `${index + 1}`},
                    )}
                  </ParsedText>
                  {index === 0 && (
                    <>
                      <Icon type="upload" />
                      <ParsedText parse={patterns}>
                        {translate(
                          'policy:helpCenterPart3UploadPhotoViaAppRoboyu_1_A',
                        )}
                      </ParsedText>
                    </>
                  )}
                </StyledListItem>
              ))}
            </StyledList>
            {translate('policy:helpCenterPart3UploadPhotoViaAppAccount')}
            <StyledList>
              {Array.from({length: 9}).map((_, index) => (
                <StyledListItem key={index.toString()}>
                  <ParsedText parse={patterns}>
                    {translate(
                      'policy:helpCenterPart3UploadPhotoViaAppAccount',
                      {context: `${index + 1}`},
                    )}
                  </ParsedText>
                </StyledListItem>
              ))}
            </StyledList>
          </StyledListItem>
          <StyledListItem>
            {translate('policy:helpCenterPart3UploadPhotoViaWeb')}
            <br />
            <ParsedText parse={patterns}>
              {translate('policy:helpCenterPart3UploadPhotoViaWebDesc')}
            </ParsedText>
            <br />
            {translate('policy:helpCenterPart3UploadPhotoViaWebRoboyu')}
            <StyledList>
              {Array.from({length: 7}).map((_, index) => (
                <StyledListItem key={index.toString()}>
                  <ParsedText parse={patterns}>
                    {translate(
                      'policy:helpCenterPart3UploadPhotoViaWebRoboyu',
                      {context: `${index + 1}`},
                    )}
                  </ParsedText>
                  {index === 0 && (
                    <>
                      <Icon type="upload" />
                      <ParsedText parse={patterns}>
                        {translate(
                          'policy:helpCenterPart3UploadPhotoViaWebRoboyu_1_A',
                        )}
                      </ParsedText>
                    </>
                  )}
                </StyledListItem>
              ))}
            </StyledList>
            {translate('policy:helpCenterPart3UploadPhotoViaWebAccount')}
            <StyledList>
              {Array.from({length: 10}).map((_, index) => (
                <StyledListItem key={index.toString()}>
                  <ParsedText parse={patterns}>
                    {translate(
                      'policy:helpCenterPart3UploadPhotoViaWebAccount',
                      {context: `${index + 1}`},
                    )}
                  </ParsedText>
                </StyledListItem>
              ))}
            </StyledList>
          </StyledListItem>
        </StyledList>

        <StyledSubTitle fontSize="l">
          {translate('policy:helpCenterPart3SamePeople')}
        </StyledSubTitle>
        <StyledDescription>
          <ParsedText parse={patterns}>
            {translate('policy:helpCenterPart3SamePeopleDesc')}
          </ParsedText>
        </StyledDescription>
        <StyledList>
          {Array.from({length: 4}).map((_, index) => (
            <StyledListItem key={index.toString()}>
              <ParsedText parse={patterns}>
                {translate('policy:helpCenterPart3SamePeopleDesc', {
                  context: `${index + 1}`,
                })}
              </ParsedText>
            </StyledListItem>
          ))}
        </StyledList>

        <StyledSubTitle fontSize="l">
          {translate('policy:helpCenterPart3ResendHires')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:helpCenterPart3ResendHiresDesc')}
        </StyledDescription>
        <StyledList>
          {Array.from({length: 6}).map((_, index) => (
            <StyledListItem key={index.toString()}>
              <ParsedText parse={patterns}>
                {translate('policy:helpCenterPart3ResendHiresDesc', {
                  context: `${index + 1}`,
                })}
              </ParsedText>
              {index === 0 && (
                <>
                  <Icon type="account" />
                  <StyledBold>
                    {translate('policy:helpCenterPart3ResendHiresDesc_1_A')}
                  </StyledBold>
                </>
              )}
              {index === 3 && (
                <>
                  <Icon type="chevron-down" />
                  <ParsedText parse={patterns}>
                    {translate('policy:helpCenterPart3ResendHiresDesc_4_A')}
                  </ParsedText>
                </>
              )}
            </StyledListItem>
          ))}
        </StyledList>

        <StyledSubTitle fontSize="l">
          {translate('policy:helpCenterPart3EditPhoto')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:helpCenterPart3EditPhotoDesc')}
        </StyledDescription>
        <StyledList>
          {Array.from({length: 7}).map((_, index) => (
            <StyledListItem key={index.toString()}>
              <ParsedText parse={patterns}>
                {translate('policy:helpCenterPart3EditPhotoDesc', {
                  context: `${index + 1}`,
                })}
              </ParsedText>
              {index === 0 && (
                <>
                  <Icon type="account" />
                  <StyledBold>
                    {translate('policy:helpCenterPart3EditPhotoDesc_1_A')}
                  </StyledBold>
                </>
              )}
              {index === 4 && (
                <>
                  <Icon type="pencil" />
                  <ParsedText parse={patterns}>
                    {translate('policy:helpCenterPart3EditPhotoDesc_5_A')}
                  </ParsedText>
                </>
              )}
            </StyledListItem>
          ))}
        </StyledList>
        <StyledSubTitle fontSize="l">
          {translate('policy:helpCenterPart3DeletePhoto')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:helpCenterPart3DeletePhotoDesc')}
        </StyledDescription>
        <StyledList>
          <StyledListItem>
            {translate('policy:helpCenterPart3DeletePhotoDesc_1')}
            <Icon type="account" />
            <StyledBold>
              {translate('policy:helpCenterPart3DeletePhotoDesc_1_A')}
            </StyledBold>
          </StyledListItem>
          <StyledListItem>
            <ParsedText parse={patterns}>
              {translate('policy:helpCenterPart3DeletePhotoDesc_2')}
            </ParsedText>
          </StyledListItem>
          <StyledListItem>
            {translate('policy:helpCenterPart3DeletePhotoDesc_3')}
            <Icon type="pencil" />
            <ParsedText parse={patterns}>
              {translate('policy:helpCenterPart3DeletePhotoDesc_3_A')}
            </ParsedText>
            <Icon type="trash" />
            <ParsedText parse={patterns}>
              {translate('policy:helpCenterPart3DeletePhotoDesc_3_B')}
            </ParsedText>
          </StyledListItem>
        </StyledList>

        <StyledSubTitle fontSize="l">
          {translate('policy:helpCenterPart3Balance')}
        </StyledSubTitle>
        <StyledDescription>
          <ParsedText parse={patterns}>
            {translate('policy:helpCenterPart3BalanceDesc')}
          </ParsedText>
        </StyledDescription>
        <StyledDescription>
          <StyledBold>
            {translate('policy:helpCenterPart3BalanceAvailable')}
          </StyledBold>
          <br />
          <ParsedText parse={patterns}>
            {translate('policy:helpCenterPart3BalanceAvailableDesc')}
          </ParsedText>
          <br />
        </StyledDescription>
        <StyledList>
          <StyledListItem>
            {translate('policy:helpCenterPart3Balance_1')}
            <Icon type="account" />
            <StyledBold>
              {translate('policy:helpCenterPart3Balance_1_A')}
            </StyledBold>
          </StyledListItem>
          <StyledListItem>
            {translate('policy:helpCenterPart3Balance_2')}
            <Icon type="wallet" />
            <ParsedText parse={patterns}>
              {translate('policy:helpCenterPart3Balance_2_A')}
            </ParsedText>
          </StyledListItem>
          <StyledListItem>
            <ParsedText parse={patterns}>
              {translate('policy:helpCenterPart3Balance_3')}
            </ParsedText>
          </StyledListItem>
        </StyledList>
        <StyledDescription>
          <StyledBold>
            {translate('policy:helpCenterPart3BankAccount')}
          </StyledBold>
          <br />
          {translate('policy:helpCenterPart3BankAccountAdd')}
        </StyledDescription>
        <StyledList>
          {Array.from({length: 7}).map((_, index) => (
            <StyledListItem key={index.toString()}>
              <ParsedText parse={patterns}>
                {translate('policy:helpCenterPart3BankAccountAdd', {
                  context: `${index + 1}`,
                })}
              </ParsedText>
              {index === 0 && (
                <>
                  <Icon type="account" />
                  <StyledBold>
                    {translate('policy:helpCenterPart3BankAccountAdd_1_A')}
                  </StyledBold>
                </>
              )}
              {index === 1 && (
                <>
                  <Icon type="wallet" />
                  <ParsedText parse={patterns}>
                    {translate('policy:helpCenterPart3BankAccountAdd_2_A')}
                  </ParsedText>
                </>
              )}
              {index === 2 && (
                <>
                  <Icon type="bank" />
                  <StyledBold>
                    {translate('policy:helpCenterPart3BankAccountAdd_3_A')}
                  </StyledBold>
                </>
              )}
            </StyledListItem>
          ))}
        </StyledList>
        <StyledDescription>
          {translate('policy:helpCenterPart3BankAccountDelete')}
        </StyledDescription>
        <StyledList>
          {Array.from({length: 5}).map((_, index) => (
            <StyledListItem key={index.toString()}>
              <ParsedText parse={patterns}>
                {translate('policy:helpCenterPart3BankAccountDelete', {
                  context: `${index + 1}`,
                })}
              </ParsedText>
              {index === 0 && (
                <>
                  <Icon type="account" />
                  <StyledBold>
                    {translate('policy:helpCenterPart3BankAccountDelete_1_A')}
                  </StyledBold>
                </>
              )}
              {index === 1 && (
                <>
                  <Icon type="wallet" />
                  <ParsedText parse={patterns}>
                    {translate('policy:helpCenterPart3BankAccountDelete_2_A')}
                  </ParsedText>
                </>
              )}
              {index === 2 && (
                <>
                  <Icon type="bank" />
                  <StyledBold>
                    {translate('policy:helpCenterPart3BankAccountDelete_3_A')}
                  </StyledBold>
                </>
              )}
            </StyledListItem>
          ))}
        </StyledList>
        <StyledDescription>
          <StyledBold>
            {translate('policy:helpCenterPart3Withdrawal')}
          </StyledBold>
          <br />
          {translate('policy:helpCenterPart3WithdrawalDesc')}
        </StyledDescription>
        <StyledList>
          {Array.from({length: 5}).map((_, index) => (
            <StyledListItem key={index.toString()}>
              <ParsedText parse={patterns}>
                {translate('policy:helpCenterPart3Withdrawal', {
                  context: `${index + 1}`,
                })}
              </ParsedText>
              {index === 0 && (
                <>
                  <Icon type="account" />
                  <StyledBold>
                    {translate('policy:helpCenterPart3Withdrawal_1_A')}
                  </StyledBold>
                </>
              )}
              {index === 1 && (
                <>
                  <Icon type="wallet" />
                  <ParsedText parse={patterns}>
                    {translate('policy:helpCenterPart3Withdrawal_2_A')}
                  </ParsedText>
                </>
              )}
            </StyledListItem>
          ))}
        </StyledList>
        <StyledDescription>
          <StyledBold>
            {translate('policy:helpCenterPart3TransactionHistory')}
          </StyledBold>
          <br />
          {translate('policy:helpCenterPart3TransactionHistoryDesc')}
        </StyledDescription>
        <StyledList>
          <StyledListItem>
            {translate('policy:helpCenterPart3TransactionHistory_1')}
            <Icon type="account" />
            <StyledBold>
              {translate('policy:helpCenterPart3TransactionHistory_1_A')}
            </StyledBold>
          </StyledListItem>
          <StyledListItem>
            {translate('policy:helpCenterPart3TransactionHistory_2')}
            <Icon type="wallet" />
            <ParsedText parse={patterns}>
              {translate('policy:helpCenterPart3TransactionHistory_2_A')}
            </ParsedText>
          </StyledListItem>
          <StyledListItem>
            <ParsedText parse={patterns}>
              {translate('policy:helpCenterPart3TransactionHistory_3')}
            </ParsedText>
          </StyledListItem>
        </StyledList>
      </StyledTextContainer>
    ),
    [patterns, translate],
  )

  return (
    <div>
      {handleGetStarted}
      {handleProfile}
      {handleUpload}
      {handleFeed}
      {handleDM}
      {handleReport}
      {handleRoboyu}
      {handleCreator}
    </div>
  )
}
