import React, {useCallback, useMemo, useRef, useState} from 'react'
import Autosizer, {Size} from 'react-virtualized-auto-sizer'
import styled from 'styled-components'
import {
  FORKYGRAM_FEED_QUERY_LIMIT,
  IMAGE_ASSET,
  REGEX_MULTIPLE_NEWLINES,
  TREE_HEADER_HEIGHT,
  WINDOW_MODE_MOBILE_WIDTH,
} from 'consts'
import {useTranslation} from 'i18n'
import {requestData} from 'services'
import {TreeFotoTreeLeafResponse} from 'types'
import {
  formatDateRange,
  fototreeLocationName,
  getBoxShadow,
  getFontFamily,
  getThemeColor,
  useHistory,
} from 'utils'
import {
  Button,
  Icon,
  Image,
  ListMasonryLazy,
  Paragraph,
  Tooltip,
} from 'common/components'
import convertUnit from 'lib/unit'
import {useDispatch, useSelector} from 'lib/redux'
import {TemplateProfilePhotoModal} from 'pages/template'
import {FototreeDetailContentProps} from './FototreeContentProps'
import {FototreeDetailContentItem} from '../item'

const StyledRowContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const StyledColumnContainer = styled(StyledRowContainer)`
  flex-direction: column;
`

const StyledTreeDetailContainer = styled(StyledColumnContainer)`
  width: 100%;
  max-width: ${convertUnit(600)};
  box-sizing: border-box;
  background-color: ${({theme}) => getThemeColor(theme, 'black', 0.48)};
  box-shadow: ${({theme}) =>
    getBoxShadow(theme, {verticalOffset: 2, blurRadius: 8, opacity: 0.24})};
  border-radius: ${convertUnit(16)};
  padding: ${convertUnit(12)};
  margin: 0 auto ${convertUnit(12)};

  @media (max-width: ${WINDOW_MODE_MOBILE_WIDTH}px) {
    width: calc(100% - ${convertUnit(12)});
  }
`

const StyledEmptyContainer = styled(StyledTreeDetailContainer)`
  text-align: center;
`

const StyledSpacer = styled.div`
  margin-top: ${convertUnit(TREE_HEADER_HEIGHT)};
`

const StyledProfileUpperContainer = styled.div`
  display: flex;
  margin-bottom: ${convertUnit(8)};
`

const StyledAvatarContainer = styled.div`
  position: relative;
`

const StyledAvatar = styled(Image)`
  border-radius: ${convertUnit(8)};
  width: ${convertUnit(80)};
  aspect-ratio: 0.75;
  object-fit: contain;
  background-color: ${({theme}) => theme.black_2};
`

const StyledTitleContainer = styled(StyledColumnContainer)`
  flex: 1;
  justify-content: center;
  width: calc(100% - ${convertUnit(92)});
  margin-left: ${convertUnit(12)};
  gap: ${convertUnit(4)};
`

const StyledBold = styled.span`
  font-family: ${getFontFamily('bold')};
`

const StyledTreeDetailDateContainer = styled(StyledRowContainer)`
  align-items: center;
  gap: ${convertUnit(4)};
`

const StyledDivider = styled.div`
  ${({theme}) => ({borderRight: `solid 1px ${theme.white_1}`})};
  width: 1px;
  margin: ${convertUnit(3)} ${convertUnit(12)};
`

const StyledLocationButton = styled(Button)`
  max-width: fill-available;
  min-width: ${convertUnit(195)};
  width: fit-content;
  height: ${convertUnit(28)};
  margin-top: ${convertUnit(8)};
  & > p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`

const StyledBio = styled(Paragraph)`
  word-break: break-word;
  white-space: pre-wrap;
  line-height: ${convertUnit(18)};
`

const StyledLeafCountContainer = styled.div`
  display: flex;
  align-items: center;
`

const StyledLeafCountTooltip = styled.div`
  display: flex;
  justify-content: center;
  align-items: baseline;
  border-radius: 50%;
  width: ${convertUnit(12)};
  height: ${convertUnit(12)};
  margin-left: ${convertUnit(4)};
  background-color: ${({theme}) => getThemeColor(theme, 'black_1', 0.4)};
`

const StyledIconExclamation = styled.div`
  rotate: 180deg;
  display: flex;
  margin: auto;
`

export default function TreeFototreeDetailContent({
  selectMode,
  stateData,
  stateSelectedItem,
  profileData,
  treeId,
  treeAlias,
  isPastMemory,
  onScroll,
}: FototreeDetailContentProps) {
  const defaultImage = IMAGE_ASSET('tree', 'default.png')
  const history = useHistory()
  const lastPage = useRef(1)
  const {update} = useDispatch()
  const {translate} = useTranslation()
  const {id: userId} = useSelector('user') || {}
  const data = stateData[0]
  const [selectedItem, setSelectedItem] = stateSelectedItem
  const [previewModal, setPreviewModal] = useState(false)
  const [isImageBroken, setImageBroken] = useState(false)

  const handleLoadData = useCallback(
    async (page: number, limit: number) => {
      const response = await requestData('tree_get_leaves', {
        actionType: 'execute',
        params: {
          page,
          limit,
          tree_id: treeId,
          member_id: isPastMemory ? userId : undefined,
        },
      })
      return typeof response !== 'string' && response.status === 200
        ? response.data.result
        : []
    },
    [isPastMemory, treeId, userId],
  )

  const handleRenderEmpty = useMemo(
    () => (
      <StyledEmptyContainer>
        <Paragraph fontSize="s" fontWeight="regular" color="white_1">
          {translate('tree:leafEmpty')}
        </Paragraph>
      </StyledEmptyContainer>
    ),
    [translate],
  )

  const handleKeyExtractor = useCallback(
    (item: TreeFotoTreeLeafResponse) => item.post_id,
    [],
  )

  const handleClickItem = useCallback(
    (item: TreeFotoTreeLeafResponse, isSelected: boolean) => {
      if (selectMode) {
        isSelected
          ? setSelectedItem((previous) =>
              previous.filter((value) => value.post_id !== item.post_id),
            )
          : setSelectedItem((previous) => [...previous, item])
      } else {
        const index = data.findIndex((post) => post.post_id === item.post_id)
        lastPage.current = Math.floor(index / FORKYGRAM_FEED_QUERY_LIMIT) + 1
        history.push(
          'tree_fototree_feed',
          {
            treeAlias,
            treeId: profileData?.id,
            selectedItemId: item.post_id,
            memberId: isPastMemory ? userId : undefined,
            initialPage: lastPage.current,
          },
          treeAlias,
        )
        update('lastTreeState', {videoIntro: false})
      }
    },
    [
      data,
      history,
      isPastMemory,
      profileData?.id,
      selectMode,
      setSelectedItem,
      treeAlias,
      update,
      userId,
    ],
  )

  const handleRenderItem = useCallback(
    (item: TreeFotoTreeLeafResponse) => (
      <FototreeDetailContentItem
        item={item}
        onClickItem={handleClickItem}
        selectMode={selectMode}
        isSelected={selectedItem.includes(item)}
      />
    ),
    [handleClickItem, selectMode, selectedItem],
  )

  const handleRenderLocationButton = useMemo(
    () =>
      profileData && (
        <StyledLocationButton
          label={
            fototreeLocationName(profileData.location.name) ||
            translate('tree:viewLocation')
          }
          backgroundColor="white_4"
          backgroundHoverColor="white_2"
          color="primary_5"
          fontSize="s"
          leftIcon={
            <Icon type="location" color="primary_5" size={convertUnit(12)} />
          }
          onClick={() =>
            history.push('tree_fototree', {
              initialData: {
                id: profileData.id,
                location: profileData.location,
                name: profileData.name,
                category_name: profileData.category_name,
              },
            })
          }
        />
      ),
    [history, profileData, translate],
  )

  const handleRenderProfilePhotoModal = useMemo(
    () =>
      previewModal && (
        <TemplateProfilePhotoModal
          src={profileData?.url}
          title={translate('tree:profilePicture')}
          toggleModal={() => setPreviewModal((prev) => !prev)}
        />
      ),
    [previewModal, profileData?.url, translate],
  )

  const handleRenderProfile = useMemo(
    () =>
      !selectMode && profileData ? (
        <StyledTreeDetailContainer>
          <StyledProfileUpperContainer>
            <StyledAvatarContainer
              style={{cursor: !isImageBroken ? 'pointer' : 'default'}}
              onClick={() => !isImageBroken && setPreviewModal(true)}>
              <StyledAvatar
                alt={profileData.name}
                src={profileData.url}
                fallbackSrc={defaultImage}
                onError={() => setImageBroken(true)}
              />
            </StyledAvatarContainer>
            <StyledTitleContainer>
              <Paragraph
                fontSize="l"
                fontWeight="bold"
                color="white_1"
                lineHeight={22}>
                {profileData.name}
              </Paragraph>
              <StyledRowContainer>
                <StyledLeafCountContainer>
                  <Paragraph color="white_1">
                    <StyledBold>{profileData.count_memory}</StyledBold>{' '}
                    {translate('forkygram:leafs')}
                  </Paragraph>
                  <StyledLeafCountTooltip data-tooltip-id="tooltip-leaf">
                    <StyledIconExclamation>
                      <Icon
                        type="exclamation-lg"
                        size={convertUnit(8)}
                        color="white_1"
                      />
                    </StyledIconExclamation>
                    <Tooltip
                      id="tooltip-leaf"
                      content={translate('tree:leafCountIncludePrivate')}
                      place="bottom"
                      fontSize="s"
                      padding={[4, 12]}
                      borderRadius={8}
                    />
                  </StyledLeafCountTooltip>
                </StyledLeafCountContainer>
                <StyledDivider />
                <Paragraph color="white_1">
                  <StyledBold>{profileData.height || 0}</StyledBold>{' '}
                  {translate('forkygram:treeHeight')}
                </Paragraph>
              </StyledRowContainer>
              {handleRenderLocationButton}
            </StyledTitleContainer>
          </StyledProfileUpperContainer>
          {profileData.event_start_date && profileData.event_end_date && (
            <StyledTreeDetailDateContainer>
              <Icon type="calendar-event" color="white_1" />
              <Paragraph color="white_1" fontWeight="bold">
                {formatDateRange(
                  profileData.event_start_date,
                  profileData.event_end_date,
                )}
              </Paragraph>
            </StyledTreeDetailDateContainer>
          )}
          <StyledBio color="white_1">
            {profileData.bio.replace(REGEX_MULTIPLE_NEWLINES, '\n')}
          </StyledBio>
        </StyledTreeDetailContainer>
      ) : (
        <></>
      ),
    [
      isImageBroken,
      defaultImage,
      handleRenderLocationButton,
      profileData,
      selectMode,
      translate,
    ],
  )

  const handleLoadLayout = useCallback(
    (item: TreeFotoTreeLeafResponse) => item.resolution,
    [],
  )

  const handleRenderMasonry = useCallback(
    ({height, width}: Size) => (
      <ListMasonryLazy
        scrollbar={false}
        loadData={handleLoadData}
        loadLayout={handleLoadLayout}
        keyExtractor={handleKeyExtractor}
        renderItem={handleRenderItem}
        stateData={stateData}
        height={height}
        width={width}
        listEmptyElement={handleRenderEmpty}
        listHeaderElement={handleRenderProfile}
        contentContainerStyle={
          !selectMode
            ? {margin: `${convertUnit(TREE_HEADER_HEIGHT)} 0`}
            : undefined
        }
        listLoadingElementStyle={{marginBottom: convertUnit(64)}}
        onScroll={onScroll}
      />
    ),
    [
      handleKeyExtractor,
      handleLoadData,
      handleLoadLayout,
      handleRenderEmpty,
      handleRenderItem,
      handleRenderProfile,
      onScroll,
      selectMode,
      stateData,
    ],
  )

  return (
    <>
      {handleRenderProfilePhotoModal}
      {selectMode && <StyledSpacer />}
      <Autosizer>{handleRenderMasonry}</Autosizer>
    </>
  )
}
