import React, {useMemo} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {getFontFamily, getFontSize} from 'utils'
import {Paragraph, ParsedText} from 'common/components'
import convertUnit from 'lib/unit'
import {parseShape} from 'types'

export interface LegalFAQContentProps {
  scrollTo: (childRef: number | undefined) => void
}

const StyledList = styled.ol`
  padding-left: ${convertUnit(25)};
`
const StyledListUl = styled.ul`
  padding-left: ${convertUnit(25)};
`
const StyledListItem = styled.li`
  font-family: ${getFontFamily('regular')};
  font-size: ${getFontSize('l')};
  line-height: ${convertUnit(31)};
  padding-left: ${convertUnit(25)};
`
const StyledTextContainer = styled.div`
  text-align: justify;
`
const StyledTitle = styled(Paragraph)`
  ${({theme}) => ({
    color: theme.primary_5,
  })}
  text-align: justify;
`
const StyledSubTitle = styled(Paragraph)`
  ${({fontSize = 'xl', fontWeight = 'bold'}) => ({
    fontSize: typeof fontSize === 'number' ? fontSize : getFontSize(fontSize),
    fontFamily: getFontFamily(fontWeight),
  })}
  margin-top: ${convertUnit(20)};
  text-align: justify;
`
const StyledDescription = styled(Paragraph)`
  ${({fontSize = 'l'}) => ({
    fontSize: typeof fontSize === 'number' ? fontSize : getFontSize(fontSize),
  })}
  margin-top: ${convertUnit(10)};
  line-height: ${convertUnit(31)};
  white-space: pre-wrap;
  text-align: justify;
`
const StyledLink = styled.a`
  color: ${({theme}) => theme.primary_5};
  text-decoration: underline;
  cursor: pointer;
`

export default function LegalFAQContent({scrollTo}: LegalFAQContentProps) {
  const {translate} = useTranslation()

  const patterns = useMemo((): parseShape[] => {
    const regex = /\*\*(.+?)\*\*/
    return [
      {
        pattern: regex,
        style: {fontFamily: getFontFamily('bold')},
        renderText: (text) => text.replace(regex, `$1`),
      },
    ]
  }, [])

  const handleTitle = useMemo(
    () => (
      <StyledTextContainer>
        <StyledTitle fontWeight="bold" fontSize="xxl">
          {translate('policy:frequentlyAskedQuestions')}
        </StyledTitle>
      </StyledTextContainer>
    ),
    [translate],
  )

  const handleFotoyuWorks = useMemo(
    () => (
      <>
        <StyledDescription>
          {translate('policy:howDoesItWorks')}
        </StyledDescription>
        <StyledList>
          {Array.from({length: 9}).map((_, index) => (
            <StyledListItem key={index.toString()}>
              {translate('policy:howDoesItWorks', {context: `${index + 1}`})}
            </StyledListItem>
          ))}
        </StyledList>
      </>
    ),
    [translate],
  )

  const handleGeneralInformation = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>{translate('policy:faq')}</StyledSubTitle>
        <StyledList>
          <StyledListItem>
            <StyledDescription>{translate('policy:fotoyu')}</StyledDescription>
            <StyledDescription>
              <ParsedText parse={patterns}>
                {translate('policy:fotoyuDesc')}
              </ParsedText>
            </StyledDescription>
          </StyledListItem>
          <StyledListItem>
            <StyledDescription>
              {translate('policy:faq', {context: 'photographer'})}
            </StyledDescription>
            <StyledDescription>
              {translate('policy:faq', {context: 'photographerDesc'})}
            </StyledDescription>
          </StyledListItem>
          <StyledListItem>
            <StyledDescription>
              {translate('policy:faq', {context: 'yuser'})}
            </StyledDescription>
            <StyledDescription>
              {translate('policy:faq', {context: 'yuserDesc'})}
            </StyledDescription>
          </StyledListItem>
          <StyledListItem>{handleFotoyuWorks}</StyledListItem>
          <StyledListItem>
            <StyledDescription>
              {translate('policy:faq', {context: 'automatic'})}
            </StyledDescription>
            <StyledDescription>
              {translate('policy:faq', {context: 'automaticDesc'})}
            </StyledDescription>
          </StyledListItem>
          <StyledListItem>
            <StyledDescription>
              {translate('policy:faq', {context: 'data'})}
            </StyledDescription>
            <StyledDescription>
              <ParsedText parse={patterns}>
                {translate('policy:faq', {context: 'dataDesc'})}
              </ParsedText>
            </StyledDescription>
          </StyledListItem>
          <StyledListItem>
            <StyledDescription>
              {translate('policy:faq', {context: 'otherPeople'})}
            </StyledDescription>
            <StyledDescription>
              {translate('policy:faq', {context: 'otherPeopleDesc'})}
            </StyledDescription>
          </StyledListItem>
          <StyledListItem>
            <StyledDescription>
              {translate('policy:faq', {context: 'findMyPhoto'})}
            </StyledDescription>
            <StyledDescription>
              {translate('policy:faq', {context: 'findMyPhotoDesc'})}
            </StyledDescription>
          </StyledListItem>
          <StyledListItem>
            <StyledDescription>
              {translate('policy:faq', {context: 'otherPlatform'})}
            </StyledDescription>
            <StyledDescription>
              {translate('policy:faq', {context: 'otherPlatformDesc'})}
            </StyledDescription>
          </StyledListItem>
          <StyledListItem>
            <StyledDescription>
              {translate('policy:faq', {context: 'accurateRoboyu'})}
            </StyledDescription>
            <StyledDescription>
              {translate('policy:faq', {context: 'accurateRoboyuDesc'})}
            </StyledDescription>
            <StyledList>
              {Array.from({length: 4}).map((_, index) => (
                <StyledListItem key={index.toString()}>
                  {translate('policy:faq', {
                    context: `accurateRoboyuDesc${index + 1}`,
                  })}
                </StyledListItem>
              ))}
            </StyledList>
            <StyledDescription>
              {translate('policy:faq', {context: 'accurateRoboyuDescBottom'})}
            </StyledDescription>
          </StyledListItem>
        </StyledList>
      </StyledTextContainer>
    ),
    [handleFotoyuWorks, patterns, translate],
  )

  const handleYuserSearch = useMemo(
    () => (
      <>
        <StyledDescription>{translate('policy:howToSearch')}</StyledDescription>
        <StyledListUl>
          {Array.from({length: 6}).map((_, index) => (
            <StyledListItem key={index.toString()}>
              {translate('policy:howToSearch', {context: `${index + 1}`})}
            </StyledListItem>
          ))}
        </StyledListUl>
      </>
    ),
    [translate],
  )

  const handleYuserInformation = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>{translate('policy:faqYuser')}</StyledSubTitle>
        <StyledList>
          <StyledListItem>{handleYuserSearch}</StyledListItem>
          <StyledListItem>
            <StyledDescription>
              {translate('policy:faqYuser', {context: 'verification'})}
            </StyledDescription>
            <StyledDescription>
              {translate('policy:faqYuser', {context: 'verificationDesc'})}
            </StyledDescription>
          </StyledListItem>
          <StyledListItem>
            <StyledDescription>
              {translate('policy:faqYuser', {context: 'location'})}
            </StyledDescription>
            <StyledDescription>
              {translate('policy:faqYuser', {context: 'locationDesc'})}
            </StyledDescription>
          </StyledListItem>
          <StyledListItem>
            <StyledDescription>
              {translate('policy:faqYuser', {context: 'myPhoto'})}
            </StyledDescription>
            <StyledDescription>
              {translate('policy:faqYuser', {context: 'myPhotoDesc'})}
            </StyledDescription>
          </StyledListItem>
          <StyledListItem>
            <StyledDescription>
              {translate('policy:faqYuser', {context: 'otherPhoto'})}
            </StyledDescription>
            <StyledDescription>
              {translate('policy:faqYuser', {context: 'otherPhotoDesc'})}
            </StyledDescription>
          </StyledListItem>
          <StyledListItem>
            <StyledDescription>
              {translate('policy:faqYuser', {context: 'spread'})}
            </StyledDescription>
            <StyledDescription>
              {translate('policy:faqYuser', {context: 'spreadDesc'})}
            </StyledDescription>
          </StyledListItem>
          <StyledListItem>
            <StyledDescription>
              {translate('policy:faqYuser', {context: 'otherYuser'})}
            </StyledDescription>
            <StyledDescription>
              {translate('policy:faqYuser', {context: 'otherYuserDesc'})}
            </StyledDescription>
          </StyledListItem>
          <StyledListItem>
            <StyledDescription>
              {translate('policy:faqYuser', {context: 'acquainted'})}
            </StyledDescription>
            <StyledDescription>
              {translate('policy:faqYuser', {context: 'acquaintedDesc'})}
            </StyledDescription>
          </StyledListItem>
          <StyledListItem>
            <StyledDescription>
              {translate('policy:faqYuser', {context: 'delete'})}
            </StyledDescription>
            <StyledDescription>
              {translate('policy:faqYuser', {context: 'deleteDesc'})}
            </StyledDescription>
          </StyledListItem>
          <StyledListItem>
            <StyledDescription>
              {translate('policy:faqYuser', {context: 'lotsPhoto'})}
            </StyledDescription>
            <StyledDescription>
              {translate('policy:faqYuser', {context: 'lotsPhotoDesc'})}
            </StyledDescription>
          </StyledListItem>
          <StyledListItem>
            <StyledDescription>
              {translate('policy:faqYuser', {context: 'buyPurchased'})}
            </StyledDescription>
            <StyledDescription>
              {translate('policy:faqYuser', {context: 'buyPurchasedDesc'})}
            </StyledDescription>
          </StyledListItem>
          <StyledListItem>
            <StyledDescription>
              {translate('policy:faqYuser', {context: 'realId'})}
            </StyledDescription>
            <StyledDescription>
              {translate('policy:faqYuser', {context: 'realIdDesc'})}
            </StyledDescription>
          </StyledListItem>
          <StyledListItem>
            <StyledDescription>
              {translate('policy:faqYuser', {context: 'twoAccount'})}
            </StyledDescription>
            <StyledDescription>
              {translate('policy:faqYuser', {context: 'twoAccountDesc'})}
            </StyledDescription>
          </StyledListItem>
          <StyledListItem>
            <StyledDescription>
              {translate('policy:faqYuser', {context: 'similarity'})}
            </StyledDescription>
            <StyledDescription>
              {translate('policy:faqYuser', {context: 'similarityDesc'})}
            </StyledDescription>
            <StyledList>
              {Array.from({length: 5}).map((_, index) => (
                <StyledListItem key={index.toString()}>
                  {translate('policy:faqYuser', {
                    context: `similarityDesc${index + 1}`,
                  })}
                </StyledListItem>
              ))}
            </StyledList>
          </StyledListItem>
          <StyledListItem>
            <StyledDescription>
              {translate('policy:faqYuser', {context: 'usernameNickname'})}
            </StyledDescription>
            <StyledDescription>
              {translate('policy:faqYuser', {context: 'usernameNicknameDesc'})}
            </StyledDescription>
          </StyledListItem>
          <StyledListItem>
            <StyledDescription>
              {translate('policy:faqYuser', {context: 'aliasNickname'})}
            </StyledDescription>
            <StyledDescription>
              {translate('policy:faqYuser', {context: 'aliasNicknameDesc'})}
            </StyledDescription>
          </StyledListItem>
          <StyledListItem>
            <StyledDescription>
              {translate('policy:faqYuser', {context: 'cantFind'})}
            </StyledDescription>
            <StyledDescription>
              {translate('policy:faqYuser', {context: 'cantFindDesc'})}
            </StyledDescription>
          </StyledListItem>
          <StyledListItem>
            <StyledDescription>
              {translate('policy:faqYuser', {context: 'parentChild'})}
            </StyledDescription>
            <StyledDescription>
              {translate('policy:faqYuser', {context: 'parentChildDesc'})}
              <StyledLink href="" onClick={() => scrollTo(13)}>
                {translate('policy:privacyPolicyChild')}
              </StyledLink>
            </StyledDescription>
          </StyledListItem>
          <StyledListItem>
            <StyledDescription>
              {translate('policy:faqYuser', {context: 'ageCheat'})}
            </StyledDescription>
            <StyledDescription>
              {translate('policy:faqYuser', {context: 'ageCheatDesc'})}
            </StyledDescription>
          </StyledListItem>
          <StyledListItem>
            <StyledDescription>
              {translate('policy:faqYuser', {context: 'screenshot'})}
            </StyledDescription>
            <StyledDescription>
              <ParsedText parse={patterns}>
                {translate('policy:faqYuser', {context: 'screenshotDesc'})}
              </ParsedText>
            </StyledDescription>
          </StyledListItem>
          <StyledListItem>
            <StyledDescription>
              {translate('policy:faqYuser', {context: 'rights'})}
            </StyledDescription>
            <StyledDescription>
              <ParsedText parse={patterns}>
                {translate('policy:faqYuser', {context: 'rightsDesc'})}
              </ParsedText>
            </StyledDescription>
          </StyledListItem>
          <StyledListItem>
            <StyledDescription>
              {translate('policy:faqYuser', {context: 'ownCopyright'})}
            </StyledDescription>
            <StyledDescription>
              {translate('policy:faqYuser', {context: 'ownCopyrightDesc'})}
            </StyledDescription>
          </StyledListItem>
        </StyledList>
      </StyledTextContainer>
    ),
    [handleYuserSearch, patterns, scrollTo, translate],
  )

  const handleCreatorInformation = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>{translate('policy:faqCreator')}</StyledSubTitle>
        <StyledList>
          <StyledListItem>
            <StyledDescription>
              {translate('policy:faqCreator', {context: 'money'})}
            </StyledDescription>
            <StyledDescription>
              {translate('policy:faqCreator', {context: 'moneyDesc'})}
            </StyledDescription>
          </StyledListItem>
          <StyledListItem>
            <StyledDescription>
              {translate('policy:faqCreator', {context: 'upload'})}
            </StyledDescription>
            <StyledDescription>
              <ParsedText parse={patterns}>
                {translate('policy:faqCreator', {context: 'uploadDesc'})}
              </ParsedText>
            </StyledDescription>
          </StyledListItem>
          <StyledListItem>
            <StyledDescription>
              {translate('policy:faqCreator', {context: 'buy'})}
            </StyledDescription>
            <StyledDescription>
              {translate('policy:faqCreator', {context: 'buyDesc'})}
            </StyledDescription>
          </StyledListItem>
          <StyledListItem>
            <StyledDescription>
              {translate('policy:faqCreator', {context: 'takePhoto'})}
            </StyledDescription>
            <StyledDescription>
              {translate('policy:faqCreator', {context: 'takePhotoDesc'})}
            </StyledDescription>
          </StyledListItem>
          <StyledListItem>
            <StyledDescription>
              {translate('policy:faqCreator', {context: 'vest'})}
            </StyledDescription>
            <StyledDescription>
              {translate('policy:faqCreator', {context: 'vestDesc'})}
            </StyledDescription>
          </StyledListItem>
          <StyledListItem>
            <StyledDescription>
              {translate('policy:faqCreator', {context: 'watermark'})}
            </StyledDescription>
            <StyledDescription>
              {translate('policy:faqCreator', {context: 'watermarkDesc'})}
            </StyledDescription>
          </StyledListItem>
          <StyledListItem>
            <StyledDescription>
              {translate('policy:faqCreator', {context: 'verification'})}
            </StyledDescription>
            <StyledDescription>
              {translate('policy:faqCreator', {context: 'verificationDesc'})}
            </StyledDescription>
          </StyledListItem>
          <StyledListItem>
            <StyledDescription>
              {translate('policy:faqCreator', {context: 'askId'})}
            </StyledDescription>
            <StyledDescription>
              {translate('policy:faqCreator', {context: 'askIdDesc'})}
            </StyledDescription>
          </StyledListItem>
          <StyledListItem>
            <StyledDescription>
              {translate('policy:faqCreator', {context: 'competition'})}
            </StyledDescription>
            <StyledDescription>
              {translate('policy:faqCreator', {context: 'competitionDesc'})}
            </StyledDescription>
          </StyledListItem>
          <StyledListItem>
            <StyledDescription>
              {translate('policy:faqCreator', {context: 'autoCompress'})}
            </StyledDescription>
            <StyledDescription>
              {translate('policy:faqCreator', {context: 'autoCompressDesc'})}
            </StyledDescription>
          </StyledListItem>
        </StyledList>
      </StyledTextContainer>
    ),
    [patterns, translate],
  )

  return (
    <div>
      {handleTitle}
      {handleGeneralInformation}
      {handleYuserInformation}
      {handleCreatorInformation}
    </div>
  )
}
