import React, {useMemo} from 'react'
import styled from 'styled-components'
import {Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {getFontFamily, getFontSize} from 'utils'
import {useTranslation} from 'i18n'
import {COMPANY_NAME, GIFT_SHOP_SEND_TO_FACE_MAX_CONTENT} from 'consts'

const StyledList = styled.ol`
  padding-left: ${convertUnit(25)};
`
const StyledListItem = styled.li`
  font-family: ${getFontFamily('regular')};
  font-size: ${getFontSize('l')};
  line-height: ${convertUnit(31)};
  padding-left: ${convertUnit(25)};
`
const StyledTextContainer = styled.div`
  text-align: justify;
`
const StyledSubTitle = styled(Paragraph)`
  ${({fontSize = 'xl', fontWeight = 'bold'}) => ({
    fontSize: typeof fontSize === 'number' ? fontSize : getFontSize(fontSize),
    fontFamily: getFontFamily(fontWeight),
  })}
  margin-top: ${convertUnit(20)};
  text-align: justify;
`
const StyledDescription = styled(Paragraph)`
  ${({fontSize = 'l'}) => ({
    fontSize: typeof fontSize === 'number' ? fontSize : getFontSize(fontSize),
  })}
  margin-top: ${convertUnit(10)};
  line-height: ${convertUnit(31)};
  white-space: pre-wrap;
  text-align: justify;
`

export default function LegalSendToFaceContent() {
  const {translate} = useTranslation()

  const handleTitle = useMemo(
    () => (
      <StyledTextContainer>
        <StyledSubTitle>
          {translate('policy:howItWorksSendToFace')}
        </StyledSubTitle>
        <StyledDescription>
          {translate('policy:howItWorksSendToFaceDesc', {
            name: COMPANY_NAME,
          })}
        </StyledDescription>
        <StyledDescription>
          {translate('policy:howItWorksSendToFaceStep')}
        </StyledDescription>
        <StyledList>
          {Array.from({length: 5}).map((_, index) => (
            <StyledListItem key={index.toString()}>
              {translate(`policy:howItWorksSendToFaceStep`, {
                context: `${index + 1}`,
                max: GIFT_SHOP_SEND_TO_FACE_MAX_CONTENT,
              })}
            </StyledListItem>
          ))}
        </StyledList>
      </StyledTextContainer>
    ),
    [translate],
  )

  return <>{handleTitle}</>
}
